import {
  Box,
  BoxProps,
  Center,
  Table as ChakraTable,
  TableProps as ChakraTableProps,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import classNames from 'classnames';
import DragToScroll from 'react-indiana-drag-scroll';

import { useIsSmallDesktop, useTranslate } from 'hooks/common';

import { ColumnsType, TableProps } from '.';
import { TableRow } from './TableRow';

interface Props<RecordType extends object = any>
  extends ChakraTableProps,
    Pick<
      TableProps<RecordType>,
      | 'activeRowKey'
      | 'rowStyleProps'
      | 'noDataPlaceholder'
      | 'data'
      | 'columns'
      | 'error'
      | 'expandable'
      | 'allowDrag'
      | 'allowDragInSmallDesk'
    >,
    Required<Pick<TableProps<RecordType>, 'rowKey'>> {
  maxHeight: BoxProps['maxH'];
  activeCellStyles: BoxProps;
  renderHeader: (column: ColumnsType<RecordType>) => JSX.Element;
}

export const TableView = <RecordType extends object = any>({
  data,
  columns,
  maxHeight,
  activeRowKey,
  rowStyleProps,
  activeCellStyles,
  noDataPlaceholder,
  error,
  expandable,
  className,
  allowDrag,
  allowDragInSmallDesk,
  rowKey,
  renderHeader,
  ...props
}: Props<RecordType>) => {
  const t = useTranslate();
  const hasData = !!data?.length;
  const isSmallDesktop = useIsSmallDesktop();

  const getPositionSticky = (colIndex: number) => {
    if (colIndex === 0)
      return {
        left: 0,
      };
    if (colIndex === columns.length - 1)
      return {
        right: 0,
      };
    let left = columns.slice(0, colIndex).reduce((acc, curr) => {
      const colWidth = curr.fixed?.columnWidth;
      const colDirection = curr.fixed?.direction === 'left';
      if (colDirection && colWidth) return acc + colWidth;
      return acc;
    }, 0);

    let right = columns.slice(-(columns.length - 1 - colIndex)).reduce((acc, curr) => {
      const colWidth = curr.fixed?.columnWidth;
      const colDirection = curr.fixed?.direction === 'right';
      if (colDirection && colWidth) return acc + colWidth;
      return acc;
    }, 0);

    return {
      left,
      right,
    };
  };

  const table = (
    <ChakraTable {...props} className={classNames(className, { expandable: !!expandable })}>
      <Thead>
        <Tr>
          {columns.map((column, index) => {
            const canSticky = column.fixed?.direction;
            const width = column.fixed?.columnWidth ?? column.width;
            const { left, right } = getPositionSticky(index);
            return (
              <Th
                key={column.key}
                position="sticky"
                top={{ md: '-5px', lg: '0' }}
                zIndex="docked"
                width={width}
                maxWidth={column.maxWidth}
                minWidth={column.minWidth}
                left={canSticky ? left + 'px' : 'initial'}
                right={canSticky ? right + 'px' : 'initial'}
                {...column.headerCellStyleProps}
              >
                {renderHeader(column)}
              </Th>
            );
          })}
        </Tr>
      </Thead>
      <Tbody position="relative">
        <Tr />
        {expandable && <Tr />}
        {!!data?.length &&
          data.map((record, recordIndex) => {
            const isActiveRow =
              activeRowKey !== undefined && rowKey(record, recordIndex) === activeRowKey;
            return (
              <TableRow
                key={rowKey(record, recordIndex) ?? recordIndex}
                columns={columns}
                record={record}
                recordIndex={recordIndex}
                isActiveRow={isActiveRow}
                rowStyleProps={rowStyleProps}
                activeCellStyles={activeCellStyles}
                expandable={expandable}
              />
            );
          })}
      </Tbody>
    </ChakraTable>
  );

  const noContent = !data?.length && <Center>{noDataPlaceholder ?? t('message.noData')} </Center>;

  if (allowDrag || (allowDragInSmallDesk && isSmallDesktop)) {
    return (
      <Box
        sx={{
          '& > .scroll-wrapper': {
            maxHeight: hasData ? maxHeight : undefined,
            pr: '1px',
          },
        }}
      >
        <DragToScroll className="scroll-wrapper" hideScrollbars={false}>
          {table}
        </DragToScroll>
        {noContent}
      </Box>
    );
  }

  return (
    <Box
      maxHeight={hasData ? maxHeight : undefined}
      pr="1px"
      className="scroll-wrapper"
      overflow="auto"
    >
      {table}
      {noContent}
    </Box>
  );
};