import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import { DATE_TIME_BE } from 'constant';
import i18next from 'i18next';
import {
  ArrayAxiosResponse,
  ArrayResponse,
  DataResponse,
  ListAxiosResponse,
  Option,
  Payload,
  Query,
} from 'types';
import { booleanToBit, parseToFormData, removeEmpty } from 'utils';

import { ILocation, ILocationFeedback } from 'models/location';

import { AreaLocationsFilterData } from 'components/molecules/AreaLocationsFilter';
import { CompanyLocationsFilterData } from 'components/molecules/CompanyLocationsFilter';
import { JodCreditInAreaFilterData } from 'components/molecules/JodCreditInAreaFilter';
import { JodCreditInCompanyFilterData } from 'components/molecules/LocationJodCreditFilter';
import { LocationFormData } from 'components/organisms/LocationForm';
import { Sorter } from 'components/organisms/Table';

import { orderOptions } from 'utils/array';

import { concatParams, mapErrorDescriptions, mapPaginationState, mapQueryToParams } from './utils';

const entity = 'location';
const basePath = `portal/${entity}` as const;

const locationPayloadScheme: Record<string, keyof LocationFormData> = {
  name: 'name',
  address: 'address',
  location_name: 'name',
  location_address: 'address',
  area_manager_id: 'areaManagerOption',
  minimum_jod_credit_limit: 'minimumCredit',
  location_logo: 'logo',
  job_approval: 'jobApprovalRequired',
  create_template_only: 'createFromTemplateOnly',
  auto_job_posting: 'autoJobPosting',
  location_manager_can_create_job: 'locationManagerCanCreateJobs',
  job_credit_deduction: 'jodCreditDeductionAtLocationLevel',
  city_code: 'cityOptions',
  expired_datetime: 'expired_datetime',
};

export const locationApi = {
  async getLocationList(
    payload: Payload<null, null, Query & { companyId?: string | number }>,
  ): Promise<DataResponse<ILocation[]>> {
    try {
      const { pagination, query } = payload;
      const { companyId, sort, search } = query || {};

      const res: ListAxiosResponse<ILocation> = await httpClient.get(`${basePath}/index`, {
        params: mapQueryToParams(
          {
            sort,
            search,
          },
          pagination,
          {
            company_id: companyId,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async summary(): Promise<DataResponse<{ total: number }>> {
    try {
      const res: AxiosResponse<{ data: { total: number }; message: string }> = await httpClient.get(
        `${basePath}/summary`,
      );
      return {
        status: true,
        data: res.data.data,
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getOptions(): Promise<DataResponse<Option[]>> {
    try {
      const { data }: AxiosResponse<{ data: Option[] }> = await httpClient.get(
        `${basePath}/getLocationOption`,
      );
      return {
        status: true,
        message: '',
        data: orderOptions(data.data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getLocationByHqCompany(
    payload: Payload<
      null,
      null,
      {
        search?: string | null;
        sort?: Sorter | null;
      } & Partial<CompanyLocationsFilterData>
    >,
  ): Promise<DataResponse<ILocation[]>> {
    try {
      const { pagination } = payload;
      const query = removeEmpty(payload.query ?? {});

      const {
        sort,
        search,
        recent,
        locationStatus,
        minAvailableCredit,
        maxAvailableCredit,
        minAvailableConsumed,
        maxAvailableConsumed,
      } = query || {};

      const filterTypes = concatParams(recent ? 'recent' : '', locationStatus ?? '');

      const res: ListAxiosResponse<ILocation> = await httpClient.get(`${basePath}/listLocationHq`, {
        params: mapQueryToParams(
          { sort, search },
          pagination,
          {
            filter_types: filterTypes,
            min_available_credit: minAvailableCredit,
            max_available_credit: maxAvailableCredit,
            min_consumed_credit: minAvailableConsumed,
            max_consumed_credit: maxAvailableConsumed,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getLocationJodCreditByHQCompany(
    payload: Payload<null, null, Query & Partial<JodCreditInCompanyFilterData>>,
  ): Promise<DataResponse<ILocation[]>> {
    try {
      const { pagination } = payload;

      const query = removeEmpty(payload.query ?? {});

      const {
        sort,
        search,
        recent,
        locationStatus,
        minAvailableCredit,
        maxAvailableCredit,
        minAvailableConsumed,
        maxAvailableConsumed,
      } = query || {};
      const filterTypes = concatParams(recent ? 'recent' : '', locationStatus ?? '');

      const res: ListAxiosResponse<ILocation> = await httpClient.get(
        `${basePath}/listLocationForJodCreditByHqManager`,
        {
          params: mapQueryToParams(
            { sort, search },
            pagination,
            {
              filter_types: filterTypes,
              min_available_credit: minAvailableCredit,
              max_available_credit: maxAvailableCredit,
              min_consumed_credit: minAvailableConsumed,
              max_consumed_credit: maxAvailableConsumed,
            },
            {
              sortDefaultByCreatedAt: true,
            },
          ),
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getFirstLocationByHqCompany(): Promise<DataResponse<ILocation>> {
    try {
      const res: ListAxiosResponse<ILocation> = await httpClient.get(`${basePath}/listLocationHq`, {
        params: {
          sort_field: 'created_at',
          sort_order: 'desc',
          page: 1,
          limit: 1,
        },
      });
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data[0],
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async disable(payload: Payload<null, { id: string }>): Promise<DataResponse<{ status: 0 }>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{ message: string; data: ILocation }> = await httpClient.delete(
        `${basePath}/disable/${params.id}`,
      );
      return {
        status: true,
        message: res.data.message,
        data: { status: 0 },
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.disableLocationUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async enable(payload: Payload<null, { id: string }>): Promise<DataResponse<{ status: 1 }>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{ message: string; data: ILocation }> = await httpClient.put(
        `${basePath}/enable/${params.id}`,
      );
      return {
        status: true,
        message: res.data.message,
        data: { status: 1 },
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.enableLocationUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async updateStatus(
    id: string,
    action: 'enable' | 'disable',
  ): Promise<DataResponse<{ status: ILocation['status'] }>> {
    if (action === 'enable') {
      return locationApi.enable({ params: { id } });
    }
    return locationApi.disable({ params: { id } });
  },
  async getDetail(payload: Payload<null, { id: string }>): Promise<DataResponse<ILocation>> {
    try {
      const {
        params: { id },
      } = payload;

      const res: AxiosResponse<{ data: ILocation; message: string }> = await httpClient.get(
        `${basePath}/show/${id}`,
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        message,
        data,
      };
    } catch (error: any) {
      return Promise.reject({ message: error.message, status: false });
    }
  },
  async create(payload: Payload<LocationFormData>): Promise<DataResponse<null>> {
    try {
      const {
        body: {
          name,
          address,
          minimumCredit,
          jobApprovalRequired,
          createFromTemplateOnly,
          autoJobPosting,
          locationManagerCanCreateJobs,
          jodCreditDeductionAtLocationLevel,
          areaManagerOption,
          logo,
          cityOptions,
          expired_datetime,
          maxDistance,
        },
      } = payload;

      const formData = parseToFormData({
        name: name,
        address: address,
        location_name: name,
        location_address: address,
        area_manager_id: areaManagerOption?.value,
        minimum_jod_credit_limit: minimumCredit,
        location_logo: logo,
        job_approval: booleanToBit(jobApprovalRequired),
        create_template_only: booleanToBit(createFromTemplateOnly),
        auto_job_posting: booleanToBit(autoJobPosting),
        location_manager_can_create_job: booleanToBit(locationManagerCanCreateJobs),
        job_credit_deduction: booleanToBit(jodCreditDeductionAtLocationLevel),
        city_code: cityOptions?.value,
        expired_datetime: expired_datetime?.set('second', 0).format(DATE_TIME_BE),
        max_distance: maxDistance,
      });

      const res: AxiosResponse<{ message: string; data: ILocation }> = await httpClient.post(
        `${basePath}/create-location`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.createdLocationUnsuccess'), description } =
        error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(locationPayloadScheme, description),
        message,
      });
    }
  },
  async update(
    payload: Payload<LocationFormData, { id: string | number }>,
  ): Promise<DataResponse<null>> {
    try {
      const {
        body: {
          name,
          address,
          minimumCredit,
          jobApprovalRequired,
          createFromTemplateOnly,
          autoJobPosting,
          locationManagerCanCreateJobs,
          jodCreditDeductionAtLocationLevel,
          areaManagerOption,
          logo,
          cityOptions,
          expired_datetime,
          maxDistance,
        },
        params: { id },
      } = payload;

      const formData = parseToFormData({
        name: name,
        address: address,
        location_name: name,
        location_address: address,
        area_manager_id: areaManagerOption?.value,
        minimum_jod_credit_limit: minimumCredit,
        location_logo: logo,
        job_approval: booleanToBit(jobApprovalRequired),
        create_template_only: booleanToBit(createFromTemplateOnly),
        auto_job_posting: booleanToBit(autoJobPosting),
        location_manager_can_create_job: booleanToBit(locationManagerCanCreateJobs),
        job_credit_deduction: booleanToBit(jodCreditDeductionAtLocationLevel),
        city_code: cityOptions?.value,
        expired_datetime: expired_datetime?.set('second', 0).format(DATE_TIME_BE),
        max_distance: maxDistance,
      });

      const res: AxiosResponse<{ message: string; data: ILocation }> = await httpClient.post(
        `${basePath}/update/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.updatedLocationUnsuccess'), description } =
        error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(locationPayloadScheme, description),
        message,
      });
    }
  },
  async getLocationJodCreditByArea(
    payload: Payload<null, null, Query & Partial<JodCreditInAreaFilterData>>,
  ): Promise<DataResponse<ILocation[]>> {
    try {
      const { pagination, query } = payload;
      const {
        sort,
        search,
        recent,
        dateRange,
        minAvailableCredit,
        maxAvailableCredit,
        minAvailableConsumed,
        maxAvailableConsumed,
      } = query || {};
      const [startDate, endDate] = dateRange || [];

      const res: ListAxiosResponse<ILocation> = await httpClient.get(
        `${basePath}/locations-jod-credits-by-area-user`,
        {
          params: mapQueryToParams({ sort, search }, pagination, {
            recent: recent ? 1 : '',
            start_date: startDate?.second(0).format(DATE_TIME_BE),
            end_date: endDate?.second(59).format(DATE_TIME_BE),
            min_available_credit: minAvailableCredit,
            max_available_credit: maxAvailableCredit,
            min_consumed_credit: minAvailableConsumed,
            max_consumed_credit: maxAvailableConsumed,
          }),
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },

  async getLocationFeedbacks(
    payload: Payload<null, { id: number | string }, Query>,
  ): Promise<DataResponse<ILocationFeedback[]>> {
    try {
      const {
        pagination,
        params: { id },
        query,
      } = payload;
      const { sort } = query || {};

      const res: ListAxiosResponse<ILocationFeedback> = await httpClient.get(
        `portal/jodJob/list-feedback-job-with-location-id`,
        {
          params: mapQueryToParams({ sort }, pagination, {
            location_id: id,
          }),
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },

  async getAreaLocation(
    payload: Payload<
      null,
      null,
      {
        sort?: Sorter | null;
        search?: string | null;
      } & Partial<AreaLocationsFilterData>
    >,
  ): Promise<DataResponse<ILocation[]>> {
    try {
      const { pagination } = payload;

      const query = removeEmpty(payload.query ?? {});

      const {
        sort,
        search,
        minConsumedCredit,
        maxConsumedCredit,
        minAvailableCredit,
        maxAvailableCredit,
      } = query;

      const res: ListAxiosResponse<ILocation> = await httpClient.get(
        `${basePath}/list-location-by-area-user`,
        {
          params: mapQueryToParams({ sort, search }, pagination, {
            min_available_credit: minAvailableCredit,
            max_available_credit: maxAvailableCredit,
            min_consumed_credit: minConsumedCredit,
            max_consumed_credit: maxConsumedCredit,
          }),
        },
      );
      const {
        data: { data, message },
      } = res;

      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getFirstAreaLocation(): Promise<DataResponse<ILocation>> {
    try {
      const res: ListAxiosResponse<ILocation> = await httpClient.get(
        `${basePath}/list-location-by-area-user`,
        {
          params: {
            page: 1,
            limit: 1,
          },
        },
      );
      const {
        data: { data, message },
      } = res;

      return {
        status: true,
        data: data.data[0],
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async summaryArea(): Promise<DataResponse<{ total: number }>> {
    try {
      const res: AxiosResponse<{ data: { total: number }; message: string }> = await httpClient.get(
        `${basePath}/location-number-total-of-area-user`,
      );

      const { data } = res;
      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async summaryHq(): Promise<DataResponse<{ total: number }>> {
    try {
      const res: AxiosResponse<{ data: { total: number }; message: string }> = await httpClient.get(
        `${basePath}/summaryHq`,
      );
      return {
        status: true,
        data: res.data.data,
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getDetailByAreaUser(
    payload: Payload<null, { id: string | number }>,
  ): Promise<DataResponse<ILocation>> {
    try {
      const {
        params: { id },
      } = payload;

      const res: AxiosResponse<{ data: ILocation[]; message: string }> = await httpClient.get(
        `${basePath}/area-user/location-detail-with-location-id`,
        {
          params: {
            location_id: id,
          },
        },
      );
      const {
        data: { data, message },
      } = res;

      return {
        status: true,
        message: message,
        data: data[0],
      };
    } catch (error: any) {
      return Promise.reject({ message: error.message, status: false });
    }
  },
  async getCityOptions(): Promise<ArrayResponse<Option>> {
    try {
      const res: ArrayAxiosResponse<{
        code: string;
        name: string;
      }> = await httpClient.get(`${basePath}/cities`);
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.map((item) => ({ value: item.code, label: item.name })),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getQrLocation(
    payload: Payload<null, { id: number }>,
  ): Promise<DataResponse<{ qrCode: string }>> {
    try {
      const { id } = payload.params;
      const res: AxiosResponse = await httpClient.get(`${basePath}/generate-qr-code`, {
        params: {
          id: id,
        },
      });

      return {
        data: { qrCode: res.data },
        message: '',
        status: true,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
};

export type LocationApi = typeof locationApi;
