import axios, { Canceler } from 'axios';
import { DEFAULT_PAGE_SIZE } from 'constant';
import noop from 'lodash/noop';
import { useEffect, useRef } from 'react';
import { AsyncReturnType, DataResponse } from 'types';
import { removeNullish } from 'utils';

import { PaginationState } from 'components/molecules';

import { useDeepMemo, useMergedState } from 'hooks/common';

type State<D, F> = {
  loading: boolean;
  error: any;
  data: D[] | null;
  pagination: PaginationState;
  filter: F | null;
};

export const usePaginationInfinity = <
  Data extends object = any,
  Func extends (...any: any[]) => any = any,
  Payload extends object = any,
  Filter extends object = any,
>(
  queryPayload: Payload | null,
  queryFnc: Func,
  config?: {
    initialState?: Partial<State<Data, Filter>> | null;
    preventInitCall?: boolean;
    needCancel?: boolean;
    intervalTime?: number; // ms
    onChange?: (data: Data[]) => void;
    onSuccess?: (res: AsyncReturnType<Func>) => void;
  },
) => {
  const {
    onChange = noop,
    onSuccess = noop,
    initialState: initialData = {},
    preventInitCall,
    needCancel,
    intervalTime,
  } = config || {};

  const canceler = useRef<Canceler | null>(null);

  const interval = useRef<number | null>(null);
  const [state, stateController, stateRef] = useMergedState<State<Data, Filter>>({
    loading: false,
    error: null,
    data: null,
    pagination: {
      pageSize: DEFAULT_PAGE_SIZE,
      current: 1,
      total: 0,
    },
    filter: null,
    ...removeNullish(initialData ?? {}),
  });

  const memoizedPayload = useDeepMemo(() => queryPayload, queryPayload);

  const refetch = async (args: typeof queryPayload): Promise<DataResponse<Data[]>> => {
    stateController.update({ loading: true, error: null });
    try {
      const payload: any = args || { pagination: state.pagination };
      if (needCancel) {
        payload.cancelToken = new axios.CancelToken(function executor(c) {
          canceler.current = c;
        });
      }
      const res: DataResponse<Data[]> = await queryFnc(payload);
      const clonedData = state.data ? [...state.data, ...res.data] : res.data;

      onSuccess(res);
      stateController.update((prev) => ({
        error: null,
        data: clonedData as Data[],
        loading: false,
        pagination: { ...prev.pagination, ...payload.pagination, total: res.pagination?.total },
      }));
      return res;
    } catch (error: any) {
      stateController.update({ error, loading: false });
      return error;
    }
  };

  const clearData = () => {
    const payload: any = { pagination: state.pagination };
    stateController.update((prev) => {
      return {
        error: null,
        data: [],
        loading: false,
        pagination: { ...prev.pagination, ...payload.pagination, current: 1 },
      };
    });
  };

  const cancel = (message?: string) => {
    canceler.current && canceler.current(message);
  };

  const stopInterval = () => {
    interval.current && clearInterval(interval.current);
  };

  const hotUpdateItems = (
    condition: (item: Data) => boolean,
    values: ((item: Data) => Partial<Data>) | Partial<Data>,
  ) => {
    stateController.set((prevState) => {
      // return prevState
      if (!prevState.data?.length) return prevState;
      let hasUpdate = false;

      let clonedData = prevState.data.map((item) => {
        if (condition(item)) {
          if (!hasUpdate) {
            hasUpdate = true;
          }
          const updates = typeof values === 'function' ? values(item) : values;
          return { ...item, ...updates };
        }
        return item;
      });

      if (!hasUpdate) return prevState;

      return {
        ...prevState,
        data: clonedData,
      };
    });
  };

  const calcItemNo = (index: number) => {
    return (state.pagination.current - 1) * state.pagination.pageSize + index + 1;
  };

  useEffect(() => {
    onChange(state.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  useEffect(() => {
    if (!preventInitCall) {
      refetch(queryPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (intervalTime) {
      interval.current = setInterval(refetch, intervalTime, memoizedPayload);
    }
    return () => {
      interval.current !== null && clearInterval(interval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalTime, memoizedPayload]);

  const tool = {
    error: state.error,
    filter: state.filter,
    ref: stateRef,
    ...stateController,
    cancel,
    stopInterval,
    refetch: refetch as Func,
    updateItems: hotUpdateItems,
    calcItemNo,
    clearData,
  };

  const result: [typeof state.data, boolean, typeof state.pagination, typeof tool] = [
    state.data,
    state.loading,
    state.pagination,
    tool,
  ];

  return result;
};
