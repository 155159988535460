import { Box, Flex, HStack, Spacer, Stack, VStack, useBreakpointValue } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { PermissionGuard } from 'guards';
import { withPermission } from 'hocs/withPermission';
import { Trans } from 'react-i18next';
import { DeviceMode } from 'types';
import useResizeObserver from 'use-resize-observer';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';
import { colors } from 'themes/foundations/colors';

import { PartnerPermission } from 'types/permission';

import { BarChar, Card, Legend, PrimaryButton, Typography } from 'components/atoms';

import { useDeviceMode, useRouter, useScreenMode, useTranslate } from 'hooks/common';
import {
  useAvailableCreditOfCompany,
  useTotalLocationAvailableCreditOfCompany,
} from 'hooks/components/credit';
import { useCreditOverview } from 'hooks/components/dashboard/useCreditOverview';
import { useLocationConsumedCredits } from 'hooks/components/location-profile';

import { LAYOUT_CONFIG } from 'configs/layout';

export const CompanyCreditOverviewWidget = () => {
  const device = useDeviceMode();
  const router = useRouter();
  const screen = useScreenMode();
  const t = useTranslate();

  const chartFixedHeight = useBreakpointValue(
    {
      base: 235,
      md: 283,
      lg: 283,
      xl: 283,
      lxl: 283,
      '2xl': 283,
    },
    screen,
  );
  const [{ consumedCredit, receivedCredit, timeData }] = useCreditOverview();
  const translatedTimeData = timeData?.map((item) => [
    t(('value.' + item[0].toLowerCase()) as any),
    item[1],
  ]);
  const { ref, width: chartWidth, height: chartWrapperHeight } = useResizeObserver();
  const totalLocationJodCredits = useTotalLocationAvailableCreditOfCompany();
  const hqJodCredits = useAvailableCreditOfCompany();
  const [, data] = useLocationConsumedCredits();
  const chartHeight = Math.max(chartFixedHeight ?? 0, chartWrapperHeight ?? 0) || undefined;
  const handleGoCredit = () => {
    router.push(PATH_NAME.JOD_CREDIT);
  };
  return (
    <Card
      round={LAYOUT_CONFIG.dashboard.round}
      boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
      p={{ base: '20px', lg: '20px 15px 0px 15px', '2xl': '20px 15px 0px 35px' }}
      fitParent
    >
      <VStack align="stretch" h="100%">
        <Flex
          alignItems={{ base: 'center', md: 'flex-start', lg: 'center' }}
          justifyContent={{ md: 'space-between', lg: 'flex-start' }}
        >
          <Stack
            // direction={{ base: 'column', '2xl': 'row' }}
            direction="column"
            spacing={{ base: '20px', lg: '10px', xl: '20px' }}
            justify={{ lg: 'space-between' }}
            w={{ base: '100%', md: 'auto', lg: '100%' }}
            flexGrow={{ md: 1, lg: 'initial' }}
          >
            <Typography.Title
              // alignSelf={{ base: 'flex-start', '2xl': 'center' }}
              fontSize={{ base: 'lg', lg: 'xl' }}
              uppercase
            >
              {t('title.creditOverview')}
            </Typography.Title>
            <HStack
              spacing={{ base: '10px', lg: '20px', '2xl': '30px' }}
              // justify={{ base: 'center', lg: 'flex-end' }}
              justify="center"
              align="flex-start"
              // alignSelf={{ base: 'flex-start', md: 'center', '2xl': 'flex-end' }}
              w={{ base: '100%', lg: 'auto' }}
              // flexWrap={{ base: 'wrap', '2xl': 'nowrap' }}
              flexWrap="wrap"
            >
              <VStack>
                <Typography.Title
                  fontSize={{ base: 'lg', lg: 'sm-3xl', '2xl': '3xl' }}
                  lineHeight="1"
                >
                  {hqJodCredits?.toLocaleString('en-US') ?? 0}
                </Typography.Title>
                <Typography.Text whiteSpace="nowrap" textAlign="center">
                  <Trans
                    t={t}
                    i18nKey="label.hqAvailableJodCredits"
                    components={[
                      <Box key={0} as="br" display={{ base: 'inline-block', lg: 'none' }} />,
                    ]}
                  />
                </Typography.Text>
              </VStack>
              <VStack>
                <Typography.Title
                  fontSize={{ base: 'lg', lg: 'sm-3xl', '2xl': '3xl' }}
                  lineHeight="1"
                >
                  {totalLocationJodCredits?.toLocaleString('en-US') ?? 0}
                </Typography.Title>
                <Typography.Text whiteSpace="nowrap" textAlign="center">
                  <Trans
                    t={t}
                    i18nKey="label.locationJodCredits"
                    components={[
                      <Box key={0} as="br" display={{ base: 'inline-block', lg: 'none' }} />,
                    ]}
                  />
                </Typography.Text>
              </VStack>
              <VStack paddingRight={createResponsiveStyle({ base: 0, '2xl': '15px' })}>
                <Typography.Title
                  fontSize={{ base: 'lg', lg: 'sm-3xl', '2xl': '3xl' }}
                  lineHeight="1"
                >
                  {data.consumed_credits_current_month?.toLocaleString('en-US') ?? 0}
                </Typography.Title>
                <Typography.Text textAlign="center" position="relative">
                  {t('label.consumedCredits')}
                  <Typography.Text position="absolute" left="0" right="0" bottom="-100%">
                    {t('label.currentMonth')}
                  </Typography.Text>
                </Typography.Text>
              </VStack>
            </HStack>
          </Stack>
          {device === DeviceMode.Tablet && (
            <VStack spacing="10px">
              <PermissionGuard permissions={PartnerPermission.ASSIGN_CREDIT}>
                <Spacer />
                <PrimaryButton w="148px" bg="secondary" onClick={handleGoCredit}>
                  {t('button.assignCredit')}
                </PrimaryButton>
              </PermissionGuard>
              <Spacer />
              <VStack w="100%" spacing="10px" alignItems="flex-start">
                <Box>
                  <Legend
                    color="secondary"
                    label={t('label.consumedCredits')}
                    textProps={{ whiteSpace: 'nowrap' }}
                  />
                </Box>
                <Box>
                  <Legend color="primary" label={t('label.receivedCredits')} />
                </Box>
              </VStack>
              <Spacer />
            </VStack>
          )}
        </Flex>
        <Stack flex="1" direction={{ base: 'column', lg: 'row' }} align="stretch">
          <Box flex="1" ref={ref}>
            <BarChar
              datasets={[
                {
                  name: t('label.receivedCredits'),
                  data: receivedCredit ?? [],
                },
                {
                  name: t('label.consumedCredits'),
                  data: consumedCredit ?? [],
                },
              ]}
              labels={translatedTimeData ?? []}
              colors={[colors.primary, colors.secondary]}
              width={chartWidth}
              numOfColumns={2}
              height={chartHeight}
              hightLightEndLabel
              tooltip={{
                y: {
                  formatter: (val) => {
                    return val.toLocaleString('en-US');
                  },
                },
              }}
            />
          </Box>
          {device !== DeviceMode.Tablet && (
            <VStack
              flexDirection={{ base: 'column-reverse', lg: 'column' }}
              spacing={{ base: '10px', lg: '20px' }}
              justify="center"
              padding={{ base: '20px 10px' }}
            >
              <PermissionGuard permissions={PartnerPermission.ASSIGN_CREDIT}>
                <Spacer />
                <PrimaryButton w="100%" bg="secondary" onClick={handleGoCredit}>
                  {t('button.assignCredit')}
                </PrimaryButton>
              </PermissionGuard>
              <Spacer />
              <VStack
                flexDirection={{ base: 'row', lg: 'column' }}
                mt={{ base: '0', lg: '20px' }}
                align={{ base: 'center', lg: 'flex-start' }}
                w="100%"
                spacing={{ lg: '10px' }}
              >
                <Box>
                  <Legend color="primary" label={t('label.receivedCredits')} />
                </Box>
                <Box pl={{ base: '20px', lg: '0' }}>
                  <Legend
                    color="secondary"
                    label={t('label.consumedCredits')}
                    textProps={{ whiteSpace: 'nowrap' }}
                  />
                </Box>
              </VStack>
              <Spacer />
            </VStack>
          )}
        </Stack>
      </VStack>
    </Card>
  );
};

export const CompanyCreditOverviewWidgetWithPermission = withPermission(
  PartnerPermission.VIEW_CREDIT_OVERVIEW,
  CompanyCreditOverviewWidget,
);
