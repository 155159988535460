import { RootState } from 'store/root-reducer';

export const locationSummarySelector = ({ location }: RootState) => location.summary;
export const locationOptionSelector = ({ location }: RootState) => location.options;
export const firstLocationSelector = ({ location }: RootState) => location.firstLocation;
export const isFetchingSummarySelector = ({ location }: RootState) =>
  location.fetchSummaryStatus === 'pending';
export const isFetchingLocationOption = ({ location }: RootState) =>
  location.fetchOption === 'pending';
export const citySelector = ({ location }: RootState) => location.cityOptions;
export const isFetchingCityOptions = ({ location }: RootState) =>
  location.fetchCity === 'pending';