import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import {
  AnyAxiosResponse,
  ArrayAxiosResponse,
  DataResponse,
  ListAxiosResponse,
  Option,
  Payload,
  Query,
} from 'types';

import { IRole, IRolePermission, PermissionType } from 'models/user';

import { UserRoleFormData } from 'components/organisms/UserRoleForm';

import { PermissionGroup } from 'constant/permission';

import { mapErrorDescriptions, mapPaginationState, mapQueryToParams } from './utils';

export type EducationFormData = {
  name: string;
  description?: string;
  address: string;
};

const entity = 'role';
const basePath = `portal/${entity}` as const;

const userPayloadScheme: Record<string, keyof UserRoleFormData> = {
  role_name: 'name',
  description: 'description',
};

export const userRoleApi = {
  async addRole(payload: UserRoleFormData): Promise<DataResponse<null>> {
    try {
      const { name, description, permissions: group } = payload;
      const permissions = Object.entries(group ?? {})
        .filter(([key, value]) => value)
        .map(([key]) => key);
      const res: AxiosResponse<{ message: string; data: IRole }> = await httpClient.post(
        `${basePath}/create`,
        {
          role_name: name,
          description: description,
          permissions: permissions,
        },
      );
      const { data } = res;
      return {
        message: data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.createdRoleUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(userPayloadScheme, error.description),
      });
    }
  },
  async updateRole(
    payload: UserRoleFormData,
    roleId: IRole['id'],
    isPartnerRole?: boolean,
  ): Promise<DataResponse<null>> {
    try {
      const { name, description, permissions: group } = payload;
      const permissions = Object.entries(group ?? {})
        .filter(([key, value]) => value)
        .map(([key]) => key);

      const formData = isPartnerRole
        ? {
            permissions,
          }
        : {
            role_name: name,
            description,
            permissions,
          };
      const res: AxiosResponse<{ message: string; data: IRole }> = await httpClient.put(
        `${basePath}/edit/${roleId}`,
        formData,
      );
      const { data } = res;
      return {
        message: data.message,
        status: true,
      };
    } catch (error: any) {
      let { message = i18next.t<string>('message.updatedRoleUnsuccess') } = error;
      if (error.description) {
        Object.entries<string>(error.description).forEach(([key, value]) => {
          if (key.startsWith('permissions')) {
            message = value;
          }
        });
      }
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(userPayloadScheme, error.description),
      });
    }
  },
  async disableRole(id: number | string): Promise<DataResponse<{ data: IRole; message: string }>> {
    try {
      const res: AxiosResponse<{
        data: IRole;
        message: string;
      }> = await httpClient.put(`${basePath}/disable/${id}`);
      return {
        data: res.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.disableRoleUnsuccess') } = error;

      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async enableRole(id: number | string): Promise<DataResponse<{ data: IRole; message: string }>> {
    try {
      const res: AxiosResponse<{
        data: IRole;
        message: string;
      }> = await httpClient.put(`${basePath}/enable/${id}`);
      return {
        data: res.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.enableRoleUnsuccess') } = error;

      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async getUserRoles(
    payload: Payload<null, { type: PermissionType }, Query>,
  ): Promise<DataResponse<IRole[]>> {
    try {
      const { pagination, query, params } = payload;
      const { sort, search } = query || {};

      const res: ListAxiosResponse<IRole> = await httpClient.get(`${basePath}/list-roles`, {
        params: mapQueryToParams({ sort, search }, pagination, { type: params.type }),
      });
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getUserRoleDetail(id: string | number): Promise<DataResponse<IRole>> {
    try {
      const res: AxiosResponse<{ data: IRole; message: string }> = await httpClient.get(
        `${basePath}/detail/${id}`,
      );

      return {
        status: true,
        data: res.data.data,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async updateStatus(
    id: string | number,
    action: 'enable' | 'disable',
  ): Promise<DataResponse<null>> {
    if (action === 'enable') {
      return userRoleApi.enableRole(id);
    }
    return userRoleApi.disableRole(id);
  },
  async getInternalRoleAsOptions(): Promise<DataResponse<Option[]>> {
    try {
      const res: AnyAxiosResponse<Option[]> = await httpClient.get(
        `${basePath}/list-option-internal-roles`,
      );
      const { data, message } = res.data;

      return {
        status: true,
        data: data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getPermissions(
    payload: Payload<null, { type: PermissionType }, null>,
  ): Promise<DataResponse<PermissionGroup[]>> {
    try {
      const { params } = payload;
      const res: ArrayAxiosResponse<PermissionGroup> = await httpClient.get(
        `portal/feature/list-features`,
        {
          params: {
            type: params.type,
          },
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getInternalRolePermissions(): Promise<DataResponse<IRolePermission[]>> {
    try {
      const res: ArrayAxiosResponse<IRolePermission> = await httpClient.get(
        `${basePath}/list-internal-roles`,
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getPartnerRolePermissions(): Promise<DataResponse<IRolePermission[]>> {
    try {
      const res: ArrayAxiosResponse<IRolePermission> = await httpClient.get(
        `${basePath}/list-partner-roles`,
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getPermissionByRole(roleId: string | number): Promise<DataResponse<IRolePermission>> {
    try {
      const res: AxiosResponse<{ data: IRolePermission; message: string }> = await httpClient.get(
        `${basePath}/detail/${roleId}`,
      );

      return {
        status: true,
        data: res.data.data,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
};

export type UserRoleApi = typeof userRoleApi;
