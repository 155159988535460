import { Box, Center, GridItem } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import { locationManagerApi } from 'apis/location-manager';

import { useExportQuery, useTranslate } from 'hooks/common';

import { PrimaryButton } from './PrimaryButton';
import { Typography } from './Typography';

export const TermsAndConditionsDownload = () => {
  const t = useTranslate();
  const [exportTermsAndConditions, exportLoadingTermsAndConditions] = useExportQuery(
    locationManagerApi.exportTermsAndConditions,
    () => `APTUS-Technologies-Terms-Of-Use-for-Customers.pdf`,
  );

  return (
    <>
      <GridItem mt={{ base: '15px' }}>
        <Typography.Text
          textAlign={{ base: 'left', lg: 'center' }}
          fontSize="md"
          w="100%"
          maxWidth="fit-content"
          _hover={{ color: 'primary', textDecoration: 'underline' }}
          onClick={() => {
            // const a = document.createElement('a');
            // a.target = '_blank';
            // a.href =
            //   ENV.API_ENDPOINT +
            //   '/storage/uploads/aptus/APTUS-Technologies-Terms-Of-Use-for-Customers.pdf';
            // a.click();
          }}
        >
          <Trans
            t={t}
            i18nKey="label.aptusTermsOfUseAndConditionsForCustomers"
            components={[<Box key={0} as="br" display={{ base: 'none', lg: 'inline-block' }} />]}
          />
        </Typography.Text>
      </GridItem>
      <GridItem w={{ base: '100%' }} justifySelf="flex-end">
        <Center h="100%">
          <PrimaryButton
            bg="secondary"
            w={{ base: '100%', md: '148px', '2xl': '140px', lg: '148px', xl: '100px' }}
            onClick={exportTermsAndConditions}
            isLoading={exportLoadingTermsAndConditions}
          >
            {t('button.download')}
          </PrimaryButton>
        </Center>
      </GridItem>
    </>
  );
};
