import { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  isFetchingSummarySelector,
  locationActions,
  locationSummarySelector,
} from 'store/modules/location';

import { InternalPermission, PartnerPermission } from 'types/permission';

import { Typography } from 'components/atoms';

import { useTranslate } from 'hooks/common';

import { useHasPermissions } from '../auth/useHasPermissions';
import { useAvailableCreditOfCompany } from '../credit';

export const useLocationSummary = (refreshOnUnmounted?: boolean, filterByHqCompany?: boolean) => {
  const dispatch = useDispatch();
  const storedSummary = useSelector(locationSummarySelector);
  const loading = useSelector(isFetchingSummarySelector);
  const availableAssignCredits = useAvailableCreditOfCompany();
  const t = useTranslate();
  const hasPermission = useHasPermissions([
    InternalPermission.VIEW_LOCATION,
    PartnerPermission.VIEW_LOCATION,
    PartnerPermission.CREATE_LOCATION,
    PartnerPermission.UPDATE_LOCATION,
  ]);

  const summarySections = useMemo(() => {
    const { total } = storedSummary;
    return [
      {
        label: (
          <Typography.Text>
            <Trans t={t} i18nKey="label.totalNumberOfLocations" components={[<br key={0} />]} />
          </Typography.Text>
        ),
        count: total,
      },
      {
        label: <Typography.Text>{t('title.unassignedCredit')}</Typography.Text>,
        count: availableAssignCredits ? availableAssignCredits.toLocaleString('en-US') : 0,
      },
    ];
  }, [availableAssignCredits, storedSummary, t]);

  const reFetch = () => {
    if (!hasPermission) return;
    dispatch(locationActions.getLocationSummary());
  };

  useEffect(() => {
    reFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo<[typeof summarySections, boolean, typeof reFetch, typeof storedSummary]>(
    () => [summarySections, loading, reFetch, storedSummary],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [summarySections, loading, storedSummary],
  );
};