import { EnglishTranslation } from './en';

export const id: EnglishTranslation = {
  'button.allCandidates': 'Semua kandidat',
  'button.assignCredit': ' Tambah Kredit ',
  'button.rating': ' Rating ',
  'button.download': 'Unduh',
  'button.filter': 'Filter',
  'button.sign_in': 'Masuk',
  'button.submit': ' Kirim saya',
  'button.save': 'Simpan',
  'button.cancel': ' Batalkan',
  'button.close': 'Tutup',
  'button.newLocation': 'Tambah cabang',
  'button.reset': 'Atur ulang',
  'button.resetFilters': 'Setel ulang filter',
  'button.confirm': 'Saya setuju ',
  'button.yesIDo': 'Saya setuju ',
  'button.noIDoNot': 'Saya tidak setuju ',
  'button.addCompany': 'Tambah mitra ',
  'button.addJobType': 'Tambah tipe pekerjaan ',
  'button.addManager': 'Tambah Manager ',
  'button.addApplicant': 'Tambah Kandidat',
  'button.addNewSchool': 'Tambah Sekolah',
  'button.addNewRole': 'Tambah peran',
  'button.addNewUser': 'Tambah Pengguna',
  'button.addNewInternalRole': 'Tambah peran internal',
  'button.addNewInternalUser': 'Tambah pengguna internal',
  'button.addNewHQManager': ' Tambah Manajer HQ ',
  'button.credit': 'Kredit Jod',
  'button.view': 'Lihat',
  'button.edit': ' Edit ',
  'button.yes': 'Ya ',
  'button.newManager': 'Tambah Manajer ',
  'button.manager': ' Manajer ',
  'button.enable': 'Aktifkan',
  'button.disable': 'Batalkan',
  'button.newJobTemplate': 'Tambah template pekerjaan ',
  'button.qrCode': 'QR',
  'button.bulkFastExport': 'Eksport massal cepat ',
  'button.downloadPayment': 'Lakukan pembayaran ',
  'button.downloadApplicantJobSummary': ' Ringkasan Pekerjaan ',
  'button.copy': 'Salin',
  'button.delete': 'Hapus',
  'button.approveJob': 'Setuju',
  'button.rejectJob': 'Tolak ',
  'button.copyJob': 'Salin',
  'button.deleteJob': 'Hapus',
  'button.editJob': ' Riwayat ',
  'button.done': 'Selesai',
  'button.back': 'Kembali',
  'button.profile': ' Profil ',
  'button.continue': ' Lanjutkan ',
  'button.updateHourlyRate': 'Pembaruan Gaji',
  'button.changePassword': 'Ganti Kata Sandi ',
  'button.assign': 'Tetapkan',
  'button.logOut': ' Keluar ',
  'button.ok': 'Oke',
  'button.detail': ' Rincian ',
  'button.companyHistory': ' Riwayat Perusahaan',
  'button.locationHistory': ' Riwayat Lokasi',
  'button.areaHistory': ' Riwayat Area',
  'button.share': 'Share',
  'button.editRating': 'Edit Rating',
  'button.bulkClockIn': 'Bulk Clock In',
  'button.bulkClockOut': 'Bulk Clock Out',
  'button.approve': 'Approve',
  'button.decline': 'Decline',

  'label.superAdmin': 'Super Admin',
  'label.hqUser': ' Manajer HQ ',
  'label.areaUser': ' Manajer Area ',
  'label.locationUser': ' Manajer Cabang ',
  'label.managerDetail': ' Rincian ',
  'label.rememberMe': 'Ingat Saya',
  'label.forgotPassword': 'Lupa kata sandi ?',
  'label.phoneNumber': ' Nomor telepon ',
  'label.contactNumber': ' Nomor telepon ',
  'label.firstName': 'Nama Depan',
  'label.lastName': 'Nama Belakang',
  'label.name': 'Nama Lengkap',
  'label.displayName': ' Nama tampilan ',
  'label.email': 'Email',
  'label.workEmail': 'Email kantor',
  'label.notification': 'Pemberitahuan',
  'label.address': ' Alamat ',
  'label.gender': 'Jenis kelamin',
  'label.dateOfBirth': 'Tanggal Lahir',
  'label.referralCode': 'Kode Referensi',
  'label.foodHygiene': ' kehigienisan dan keamanan pangan ',
  'label.foodHygieneCert': 'Sertifikat Food Hygiene',
  'label.sunOfJODBadge': 'Lencana JOD',
  'label.newJobNotifications': 'Pengumuman pekerjaan baru',
  'label.unsubscribeNews': ' Berhenti berlangganan Notifikasi',
  'label.hqCompany': ' Perusahaan HQ ',
  'label.deletedAccount': ' Akun Dihapus ',
  'label.language': 'Bahasa',
  'label.download': ' Unduh ',
  'label.workHistory': ' Riwayat Pekerjaan ',
  'label.currentOccupation': 'Pekerjaan saat ini',
  'label.job': 'Pekerjaan',
  'label.jobs': 'Pekerjaan',
  'label.activeJobs': 'Pekerjaan aktif',
  'label.openJobs': 'Lowongan pekerjaan',
  'label.completedJobs': 'Pekerjaan selesai',
  'label.currentMonth': 'Bulan ini',
  'label.todayHired': 'Pekerjaan hari ini ',
  'label.totalHired': ' Jumlah kandidat yang direkrut ',
  'label.totalCredits': 'Jumlah kredit ',
  'label.numberOfHiredStaff': ' Jumlah staff yang direkrut ',
  'label.hqAvailableJodCredits': 'Jumlah kredit Jod yang tersedia (HQ)',
  'label.locationJodCredits': 'Jumlah kredit Jod yang tersedia (cabang)',
  'label.consumedCredits': 'Jumlah kredit Jod yang Digunakan',
  'label.generalRating': ' Rating keseluruhan',
  'label.serviceContract': ' Kontrak Layanan ',
  'label.locationName': 'Nama Cabang',
  'label.codeCity': 'Pilih Kota',
  'label.availableCredits': 'Jumlah kredit yang dimiliki',
  'label.areaManager': ' Manajer Area ',
  'label.locationManagers': ' Manajer Cabang ',
  'label.areaManagers': ' Manajer Area ',
  'label.function': 'Fungsi',
  'label.min': 'Min',
  'label.max': 'Maks ',
  'label.recent': 'Dibuat baru-baru ini ',
  'label.recentAddCompanies': 'Mitra yang baru dibuat ',
  'label.enableCompanies': 'Aktifkan',
  'label.disableCompanies': 'Nonaktifkan perusahaan',
  'label.partnerCompanies': 'Mitra',
  'label.activeCompanies': 'Mitra aktif',
  'label.companyName': 'Nama Mitra ',
  'label.companyAddress': 'Alamat Mitra',
  'label.companyStatus': 'Status',
  'label.businessRegistration': 'Nomor registrasi bisnis',
  'label.minJODCreditLimit': 'Kredit JOD limit ( batas minimum ) ',
  'label.jobTypeStatus': 'Status',
  'label.numberOfAvailablePositions': ' Jumlah Pekerjaan',
  'label.numberOfAvailableCompanies': ' Jumlah Perusahaan',
  'label.minimumAvailablePositions': ' Jumlah pekerjaan yang bisa saya lakukan ',
  'label.maximumAvailablePositions': ' Jumlah maksimum pekerjaan ',
  'label.minimumAvailableCompanies': ' Minimal jumlah mitra ',
  'label.maximumAvailableCompanies': ' Jumlah maksimum mitra ',
  'label.minAvailablePositions': ' Jumlah pekerjaan yang bisa saya lakukan ',
  'label.maxAvailablePositions': ' Jumlah maksimum pekerjaan ',
  'label.minAvailableCompanies': ' Minimal jumlah mitra ',
  'label.maxAvailableCompanies': ' Jumlah maksimum mitra ',
  'label.generalDescription': ' Deskripsi Umum ',
  'label.jobDescription': ' Deskripsi Pekerjaan ',
  'label.comment': 'Komentar',
  'label.jobTitle': 'Jabatan',
  'label.hqManager': ' Manajer HQ ',
  'label.hqMangerName': ' Nama manajer HQ ',
  'label.contactInformation': 'Informasi Kontak',
  'label.company': ' Perusahaan ',
  'label.recentAddHQUsers': ' Manajer HQ yang baru dibuat ',
  'label.companyHQMainContactUserDetails': 'Info kontak utama',
  'label.disableHQUsers': 'Batalkan',
  'label.enableHQUsers': 'Aktifkan',
  'label.hqUserStatus': 'Status',
  'label.totalNumberOfHQUsers': ' Jumlah Manajer HQ ',
  'label.numberOfActiveHQUsers': 'Jumlah manajer HQ aktif',
  'label.numberOfDisableHQUsers': 'Jumlah manajer HQ tidak aktif',
  'label.recentAddJobTypes': ' Daftar tipe pekerjaan terbaru ',
  'label.disableJobTypes': 'Batalkan',
  'label.enableJobTypes': 'Aktifkan',
  'label.enabledCompanies': 'Mitra diaktifkan ',
  'label.disabledCompanies': 'Mitra dinonaktifkan ',
  'label.recentAdded': 'Dibuat baru-baru ini ',
  'label.totalNumberOfLocations': ' Jumlah cabang ',
  'label.locationStatus': 'Status',
  'label.minimumAvailableCredits': ' Minimum Kredit ',
  'label.maximumAvailableCredits': ' Maksimum Kredit ',
  'label.minimumConsumedCredits': 'Minimum Kredit yang digunakan',
  'label.maximumConsumedCredits': 'Maksimum Kredit yang digunakan',
  'label.minAvailableCredits': ' Minimal Kredit ',
  'label.maxAvailableCredits': ' Jumlah maksimum Kredit ',
  'label.minConsumedCredits': 'Jumlah Kredit yang digunakan',
  'label.maxConsumedCredits': 'Jumlah Kredit yang digunakan',
  'label.minAssignedCredits': 'Min Assigned Credits',
  'label.maxAssignedCredits': 'Max Assigned Credits',
  'label.minAvailableConsumed': ' Minimal Kredit yang digunakan',
  'label.maxAvailableConsumed': ' Maksimal Kredit yang digunakan',
  'label.minimumAvailableConsumed': 'Jumlah Kredit yang dihabiskan',
  'label.maximumAvailableConsumed': 'Jumlah Kredit yang digunakan',
  'label.locationAddress': ' Alamat Cabang ',
  'label.jobApprovalRequired': 'Permintaan Persetujuan Pekerjaan',
  'label.locationManagerCanCreateJob': ' Manajer Cabang Dapat Membuat Pekerjaan',
  'label.createFromTemplate': 'Buat Pekerjaan Hanya Dari Template ',
  'label.creditDeductionAtLocationLevel': 'Kurangi Kredit Jod Dari Cabang',
  'label.expired_datetime': 'Waktu Kadaluarsa',
  'label.autoJobPosting': 'Secara Otomatis Memposting Pekerjaan',
  'label.date': 'Tanggal',
  'label.inProgress': 'sedang berlangsung',
  'label.assignedCredits': 'Tetapkan kredit ',
  'label.applicantName': 'Nama kandidat',
  'label.applicantStatus': 'Status',
  'label.applicantIsFeedback': 'Feedback dari kandidat',
  'label.applicantIsRating': 'Rating dari kandidat',
  'label.identityStatus': 'Status',
  'label.identityDocument': 'Dokumen Identitas',
  'label.managerFeedback': 'Feedback dari manajer ',
  'label.managerRating': ' Rating dari manajer ',
  'label.minimumRating': ' Rating minimum ',
  'label.maximumRating': ' Rating maksimal ',
  'label.applicantsWithAmountJobs': 'Applicant with Job Amount',
  'label.availableBreakLineCredits': 'Koin Jod tersedia',
  'label.consumedBreakLineCredits': 'Koin Jod Digunakan',
  'label.disableLocations': 'Batalkan',
  'label.enableLocations': 'Aktifkan',
  'label.maskAsValid': 'Valid',
  'label.maskAsInvalid': 'Tidak Valid',
  'label.userAccountID': 'ID Akun Pengguna',
  'label.verifyCode': 'Kode Verifikasi',
  'label.school': 'Sekolah',
  'label.averageRating': 'Rata-rata rating',
  'label.totalWorkingHours_breakLine': ' Jumlah jam kerja',
  'label.totalCompletedJobs_breakLine': ' Jumlah pekerjaan yang diselesaikan',
  'label.totalCompletedJobs': ' Jumlah pekerjaan selesai',
  'label.totalNumberOfApplicants': 'Jumlah kandidat',
  'label.numberOfActiveApplicants': 'Jumlah kandidat aktif',
  'label.numberOfDisableApplicants': 'Jumlah kandidat tidak aktif',
  'label.recentAddedApplicants': ' Kandidat yang baru dibuat ',
  'label.disableApplicants': 'Disable',
  'label.enableApplicants': 'Aktifkan',
  'label.minRating': ' Rating minimum ',
  'label.maxRating': 'Rating maksimal ',
  'label.applicantWithNumberOfJobs': 'Jumlah pelamar kerja',
  'label.manager': 'Manajer',
  'label.managerType': ' Jenis Manajer ',
  'label.locations': 'Cabang',
  'label.totalNoAreaManagers': 'Jumlah Manajer Area',
  'label.totalNoLocationManagers': 'Jumlah Manajer Cabang',
  'label.managerStatus': 'Status',
  'label.location': 'Cabang',
  'label.institutionName': 'Nama bidang',
  'label.instituteName': 'Nama bidang',
  'label.description': ' Deskripsi',
  'label.educationInstituteStatus': 'Status',
  'label.totalNumberOfEducationalInstitutes': ' Nomor sekolah',
  'label.recentAddedEducationInstitutes': ' Bidang yang baru dibuat',
  'label.disableEducationalInstitutes': 'Batalkan',
  'label.enableEducationalInstitutes': 'Aktifkan',
  'label.templateName': ' Nama template ',
  'label.jobStart': 'Pekerjaan dimulai ',
  'label.jobEnd': 'Pekerjaan selesai',
  // TODO: new translation (2)
  'label.slotStart': 'Pekerjaan dimulai ',
  'label.slotEnd': 'Pekerjaan selesai',
  'label.wage': 'Gaji',
  'label.applicationNo': 'Jumlah kandidat ',
  'label.hiredNo': ' Jumlah pelamar ',
  'label.totalNumberOfJobs': ' Jumlah pekerjaan',
  'label.jobWithApplicants': 'Pekerjaan dengan pelamar',
  'label.jobStart&JobEnd': ' Mulai dan selesai pekerjaan ',
  'label.minimumWage': ' Gaji Minimum ',
  'label.maximumWage': ' Gaji maksimum ',
  'label.from': 'Dari',
  'label.to': ' Sampai ',
  'label.include': 'Termasuk ',
  'label.exclude': 'Kecualikan',
  'label.totalNumberOfJobTemplates': 'Jumlah template pekerjaan ',
  'label.jobTemplateStatus': 'Status',
  'label.jobType': 'Daftar Jenis Pekerjaan',
  'label.hourlyRate': 'Upah per jam',
  'label.specialInstructions': 'Instruksi Khusus',
  'label.specialInstructions_breakLine': ' Instruksi Khusus <0/> ',
  'label.createdDate': 'Tanggal dibuat',
  'label.timeIn': 'Jam masuk',
  'label.timeOut': 'Jam pulang',
  'label.totalHours': ' Jumlah jam',
  'label.totalWages': ' Total Gaji ',
  'label.status': 'Status',
  'label.jobStatus': 'Status',
  'label.jobIsStatus': 'Status',
  'label.createMonth': 'Bulan Generasi',
  'label.assigningAmount': ' Jumlah coin ',
  'label.amount': ' Kuantitas ',
  'label.feedback': 'Ulasan',
  'label.assigningDate': 'Tanggal penerbitan ',
  'label.startDate': ' Tanggal mulai ',
  'label.endDate': ' Tanggal akhir ',
  'label.minimumAssignAmount': ' Minimal jumlah poin ',
  'label.maximumAssignAmount': ' Jumlah poin maksimum ',
  'label.selectAll': ' Pilih semuanya ',

  'label.jodID': 'ID Pekerjaan',
  'label.slotID': 'ID Slot',
  'label.clockIn': 'Waktu masuk',
  'label.clockOut': 'Waktu pulang',
  'label.netPay': 'Gaji ',
  'label.detail': ' Rincian ',
  'label.rating': ' Rating ',
  'label.breakTime': ' Waktu istirahat ',
  'label.deductedCredits': 'Kredit yang dikurangi',
  'label.jodCredit': 'Kredit Jod',
  'label.jobHistory': ' Riwayat ',
  'label.locationCredit': 'Jumlah kredit Jod di cabang',
  'label.processedDate': 'Tanggal proses',
  'label.jodAllowance': ' Tunjangan Jod ',
  'label.bankPaid': ' Dibayar ',
  'label.bankName': 'Nama bank',
  'label.bankHolderName': ' Nama pemegang rekening ',
  'label.bankAccountNumber': ' Nomor rekening ',
  'label.paymentStatus': 'Status',
  'label.recentAddedPayments': ' Pembayaran yang baru dibuat ',
  'label.jobDetail': ' Detail pekerjaan ',
  'label.jobDetails': ' Detail pekerjaan ',
  'label.wagePerHour': 'Gaji per jam',
  'label.jodAmendedDetails': 'Jod Amended Details',
  'label.noAdjustmentMade': 'Tidak ada yang bisa dilakukan ',
  'label.completedByAdmin': 'Dilengkapi oleh Admin',
  'label.unavailable': 'Tidak tersedia',
  'label.fullName': 'Nama Lengkap',
  'label.totalJodCredits': 'Jumlah kredit Jod',
  'label.insuranceAmount': ' Biaya asuransi ',
  'label.netPayableAmount': 'Gaji bersih',
  'label.accountHolder': ' Pemegang Rekening ',
  'label.adjustedJODCredit': ' Penyesuaian Kredit Jod ',
  'label.adjustedJodCredits': ' Penyesuaian Kredit Jod ',
  'label.adjustedAt': 'Disesuaikan pada ',

  'label.totalNumberOfPayments': 'Jumlah pembayaran',
  'label.totalNumberOfProcessedPayments': ' Jumlah pembayaran yang diproses',
  'label.totalNumberOfUnpaidPayments': ' Jumlah pembayaran belum diproses',

  'label.applicantID': 'ID Kandidat',
  'label.applicantFirstName': 'Nama Depan',
  'label.applicantLastName': 'Nama Belakang',
  'label.applicantNRIC/FICNumber': 'ID KTP ',
  'label.applicantEmail': 'Email',
  'label.applicantContactNumber': ' Nomor telepon ',
  'label.applicantGender': 'Jenis kelamin',
  'label.applicantDoB': 'Ulang tahun',
  'label.yearOld': '{{age}} tahun',
  'label.applicantAge': 'Tahun',
  'label.applicantAddress': ' Alamat ',
  'label.applicantRating': 'Rating dari kandidat',
  'label.applicantFeedback': 'Feedback dari kandidat',
  'label.applicantEducationalInstitution': 'Sekolah',
  'label.applicantBankName': 'Nama bank',
  'label.applicantV1BankName': '',
  'label.applicantBankAccountName': ' Nama pemegang rekening ',
  'label.applicantBankAccountNumber': ' Nomor rekening',
  'label.jobStartDateTime': 'Waktu mulai pekerjaan ',
  'label.jobEndDateTime': 'Waktu menyelesaikan pekerjaan',
  'label.totalExpectedHours': 'Perkiraan jam kerja',
  'label.totalExpectedWages': 'Perkiraan gaji',
  'label.jobCreatedDateTime': 'Waktu dibuat',
  'label.daysOfJobCreatedAndJobStart': ' Tanggal pembuatan pekerjaan dan tanggal mulai ',
  'label.hoursOfJobCreatedAndJobStart': ' Jam pembuatan pekerjaan dan jam mulai ',
  'label.applicantAppliedDateTime': 'Waktu melamar',
  'label.daysOfJobCreatedAndApplicantApplied':
    'Tanggal pembuatan pekerjaan dan tanggal perekrutan ',
  'label.hoursOfJobCreatedAndApplicantApplied': 'Jam pembuatan pekerjaan dan jam perekrutan ',
  'label.applicantHiredDateTime': 'Waktu perekrutan',
  'label.daysOfApplicantAppliedAndApplicantHired': 'Waktu penerimaan',
  'label.hoursOfApplicantAppliedAndApplicantHired': 'Waktu penerimaan ',
  'label.jobApplicantStatus': 'Status',
  'label.slotApplicantStatus': 'Status',
  'label.slotStartDateTime': 'Waktu mulai shift',
  'label.slotEndDateTime': 'Waktu selesai shift',
  'label.applicantClockIn': 'Kandidat masuk',
  'label.applicantClockOut': 'Kandidat pulang',
  'label.applicantCancelledJob': 'Kandidat membatalkan pekerjaan ',
  'label.applicantCancellationFeedback': 'Kandidat membatalkan feedback',
  'label.ack1Done': 'Ack 1',
  'label.ack2Done': 'Ack 2',
  'label.jobCompleted': 'Pekerjaan selesai',
  'label.hiringManagerID': ' ID Manajer ',
  'label.hiringManagerFirstName': 'Nama depan',
  'label.hiringManagerLastName': 'Nama belakang',
  'label.hiringMangerContactNumber': ' Nomor telepon ',
  'label.hiringManagerEmail': 'Email',
  'label.hiringManagerRating': 'Rating manajer',
  'label.hiringManagerFeedback': 'Feedback manajer',
  'label.jobApplicantIsStatus': 'Status',
  'label.slotApplicantIsStatus': 'Status',
  'label.totalWorkingHours': ' Jumlah jam kerja',
  'label.jobId': 'ID pekerjaan',
  'label.totalDays': ' Jumlah hari',
  'label.bankTransfer': 'Bank Transfer',
  'label.originalJobStart': 'Waktu mulai asli ',
  'label.originalJobEnd': 'Waktu selesai asli ',
  'label.actualClockIn': 'Waktu masuk aktual',
  'label.actualClockOut': ' Waktu pulang aktual',
  'label.dob': 'Tanggal lahir',
  'label.jodCompletedJobs': 'Pekerjaan selesai',
  'label.view': 'Lihat',
  'label.select': 'Pilih',

  'label.locationManager': ' Manajer Cabang ',
  'label.earningPerHours': 'Upah per jam',
  'label.workingDay': 'Pilih hari kerja',
  'label.dateAndTime': 'Tanggal Waktu',

  'label.content': 'Konten',
  'label.value': 'Nilai',
  'label.push': 'Pemberitahuan Aplikasi',
  'label.sms': 'Pemberitahuan SMS',
  'label.id': 'ID',
  'label.enabled': 'Aktifkan',

  'label.internalPermissions': ' Hak Akses Internal ',
  'label.partnerPermissions': ' Hak Akses Mitra ',
  'label.permission': ' Hak akses',
  'label.roleName': 'Jenis Peran',
  'label.userName': 'Jenis Pengguna',
  'label.role': 'Pengguna',
  'label.userDetails': ' Detail pengguna ',
  'label.internalRoles': 'Peran internal',
  'label.partnerRoles': ' Peran mitra ',

  'label.changeWagePerHour': 'Ubah upah per jam',

  'label.password': 'Kata Sandi',
  'label.oldPassword': 'Password Lama',
  'label.newPassword': 'Kata Sandi Baru',
  'label.confirmPassword': 'Masukkan kembali kata sandi ',
  'label.credit': 'Kredit',
  'label.jodCredits': 'Kredit Jod',
  'label.employeeName': 'Nama kandidat',
  'label.consumedCreditsPreviousMonth': 'Kredit Jod yang digunakan (bulan lalu)',
  'label.consumedCreditsCurrentMonth': 'Kredit Jod Digunakan (Bulan Ini)',
  'label.jodCreditAvailableWithHeadquarter': 'Jumlah Kredit Jod yang Tersedia (HQ)',
  'label.test': ' Tes ',
  'label.clockIn&ClockOut': 'Tekan masuk dan keluar',
  'label.minimumTotalJodCredit': 'Jumlah Kredit Jod ( angka minimum ) ',
  'label.maximumTotalJodCredit': 'Jumlah Kredit Jod ( maksimum )',
  'label.jodCreditDeductionLevel': 'Level Pengurangan Kredit Jod',
  'label.mealBreakTime': ' Waktu istirahat ',
  'label.availableCreditBeforeDeduction': 'Jumlah Kredit Jod sebelum dikurangi',
  'label.availableCreditAfterDeduction': 'Jumlah Kredit Jod setelah dikurangi',
  'label.assignBadge': 'Dapatkan lencana ',
  'label.badge': 'Lencana',
  'label.allCompanies': ' Semua mitra',
  'label.futurePayment': 'Pembayaran Baru',
  'label.allFuturePayments': ' Semua pembayaran Baru ',
  'label.paymentDetails': ' Rincian pembayaran ',
  'label.paymentSummary': ' Ringkasan Pembayaran ',
  'label.unprocessedCredits': ' Kredit Jod yang Belum Diproses ',
  'label.processedCredits': ' Proses Kredit Jod ',
  'label.recentAddLocations': ' Cabang-cabang baru dibuat ',
  'label.locationStatus.': 'Status',
  'label.noHistory': 'Tidak ada riwayat ',
  'label.noExperience': 'Tidak ada pengalaman',
  'label.candidateName': 'Nama kandidat',
  'label.applyDate': ' Tanggal melamar',
  'label.jobStartAndJobEnd': ' Mulai dan selesai pekerjaan ',
  'label.paymentProcessed': 'Pembayaran diproses ',
  'label.notProcessed': 'Tidak diproses ',
  'label.clickCancelSorting': ' Tekan untuk membatalkan pengurutan ',
  'label.clickToSortAsc': ' Tekan untuk urutan menaik ',
  'label.clickToSortDesc': ' Tekan untuk urutan menurun ',

  'label.unknown': 'Tidak diketahui',
  'label.receivedCredits': 'Kredit Jod Diterima',
  'label.feedbackFromApplicant': 'Feedback dari kandidat',
  'label.feedbackFromManager': 'Feedback dari manajer ',
  'label.ratingFromApplicant': ' Rating dari kandidat',
  'label.ratingFromManager': ' Rating dari manajer ',
  'label.applicantInformation': 'Informasi Kandidat',

  'label.aptusTermsOfUseAndConditionsForCustomers':
    'Ketentuan Penggunaan Jod & Ketentuan Penggunaan untuk Pelanggan ',
  'label.mon': 'Senin',
  'label.tue': 'Selasa',
  'label.wed': 'Rabu',
  'label.thur': 'Kamis',
  'label.fri': 'Jumat',
  'label.sat': 'Sabtu',
  'label.sun': ' Minggu',
  'label.all': ' Semuanya ',
  'label.asc': 'Meningkat secara bertahap ',
  'label.desc': ' Menurun secara bertahap ',
  'label.nric': 'KTP',
  'label.lessThan1Years': 'Kurang dari 1 tahun',
  'label.between1To2Years': 'Dari 1 sampai 2 tahun',
  'label.moreThan2Years': 'Lebih dari 2 tahun',
  'label.hour': 'jam',
  'label.hours': 'jam',
  'label.minute': 'menit',
  'label.minutes': 'menit',
  'label.checkboxSendMailToApplicant': 'Kirim email ke kandidat',
  'label.certificates': 'Sertifikat',
  'label.noCertificates': 'Tidak ada sertifikat',
  'label.age': 'Umur',
  'label.locationAverageRating': `Rating rata-rata lokasi`,
  'label.dailySalary': 'Daily credits consumed per worker: ',
  'label.totalSalary': 'Total credits consumed per worker: ',
  'label.maxDistance': 'Max Distance (meter)',
  'label.reason': 'Reason',


  'placeholder.reason': 'Reason',
  'placeholder.maxDistance': 'Max Distance (meter)',
  'placeholder.email': 'Email',
  'placeholder.password': ' Kata Sandi ',
  'placeholder.uploadServiceContract': ' Unggah kontrak layanan * ',
  'placeholder.uploadedServiceContract': ' Unggah kontrak layanan ',
  'placeholder.uploadBrandBanner': 'Unggah spanduk (opsional)',
  'placeholder.uploadedBanner': 'Spanduk diunggah ',
  'placeholder.minExpectedCredit': ' Kredit Jod yang Diharapkan ( minimum ) ',
  'placeholder.enterKeyword': 'Masukkan kata kunci',
  'placeholder.enterJobTitle': 'Masukkan judul pekerjaan ',
  'placeholder.credit': '0',
  'placeholder.upload': 'Unggah ',
  'placeholder.uploaded': 'Saya telah menunggah ',
  'placeholder.uploadLogo': 'Unggah logo saya ',
  'placeholder.uploadBanner': 'Unggah spanduk ',
  'placeholder.uploadProfilePicture': ' Gambar profile ',
  'placeholder.firstName': 'Masukkan nama depan',
  'placeholder.lastName': 'Masukkan nama belakang',
  'placeholder.jobTitle': 'Masukkan judul pekerjaan',
  'placeholder.contactNumber': 'Masukkan nomor telepon ',
  'placeholder.workEmail': 'Masukkan email',
  'placeholder.creditAmount': ' Jumlah Kredit',
  'placeholder.comment': 'Komentar',
  'placeholder.locationName': 'Masukkan nama cabang',
  'placeholder.locationAddress': ' Masukkan alamat cabang ',
  'placeholder.areaManager': 'Pilih manajer area ',
  'placeholder.managerType': 'Pilih posisi manajer ',
  'placeholder.location': 'Pilih cabang',
  'placeholder.locations': 'Pilih cabang',
  'placeholder.applicantFirstName': 'Nama depan',
  'placeholder.applicantLastName': 'Nama belakang',
  'placeholder.applicantDisplayName': ' Nama tampilan ',
  'placeholder.applicantDateOfBirth': 'Ulang tahun',
  'placeholder.applicantNRIC/FIN': 'No KTP',
  'placeholder.applicantContactNumber': ' Nomor telepon ',
  'placeholder.applicantEmail': 'Email',
  'placeholder.applIcantSelectSchool': 'Sekolah',
  'placeholder.applicantReferralCode': 'Kode Referral',
  'placeholder.applicantSelectHQCompany': ' Mitra khusus dipilih ',
  'placeholder.enterRating': 'Masukkan Rating ',
  'placeholder.selectStatus': 'Pilih Status',
  'placeholder.selectDate': 'Pilih tanggal',
  'placeholder.selectLocations': 'Pilih cabang',
  'placeholder.selectMonth': 'Pilih bulan',
  'placeholder.selectCompany': 'Pilih perusahaan ',
  'placeholder.status': 'Pilih Status',
  'placeholder.startDate': ' Tanggal mulai ',
  'placeholder.endDate': ' Tanggal akhir ',
  'placeholder.enterTemplateName': 'Masukkan nama template',
  'placeholder.jobType': 'Pilih kategori pekerjaan',
  'placeholder.noDateSelected': 'Tanggal tidak dipilih',
  'placeholder.pleaseSelectDate': 'Pilih tanggal',
  'placeholder.jobDescription': 'Masukkan deskripsi pekerjaan ',
  'placeholder.specialInstructions': 'Masukkan permintaan khusus ',
  'placeholder.hourlyRate': 'Masukkan gaji per jam',
  'placeholder.pleaseEnterBankHolderName': 'Masukkan nama pemegang rekening pembayaran ',
  'placeholder.pleaseEnterBankAccountNumber': 'Masukkan nomor rekening pembayaran ',
  'placeholder.selectLocation': 'Pilih cabang',
  'placeholder.feedback': 'Masukkan komentar',
  'placeholder.noAppliedApplicant': 'Tidak ada kandidat',
  'placeholder.selectTemplateName': 'Pilih template pekerjaan ',
  'placeholder.selectLocationManager': 'Pilih manajer cabang ',
  'placeholder.pleaseSelectLanguage': 'Pilih bahasa',
  'placeholder.currentWagePerHour': 'Gaji per jam saat ini',
  'placeholder.newWagePerHour': 'Gaji per jam baru',
  'placeholder.allLocations': ' Semua cabang ',
  'placeholder.allJobs': ' Semua pekerjaan ',
  'placeholder.allJobStatus': ' Semua status pekerjaan ',
  'placeholder.pleaseSelectAnOption': 'Pilih opsi',
  'placeholder.pleaseSelectAColumn': 'Pilih kolom',
  'placeholder.pleaseEnterAssigningAmount': ' Masukkan jumlah ',
  'placeholder.pleaseSelectBreakTime': 'Please select break time.',
  'placeholder.expired_datetime': 'Waktu kadaluarsa',
  
  'title.declineClockInOutRating': 'Decline Clock In/Out',
  'title.dashboard': 'Dashboard',
  'title.editProfile': 'Edit Profil ',
  'title.companies': 'Perusahaan ',
  'title.newCompany': 'Buat Perusahaan ',
  'title.editCompany': ' Edit Perusahaan ',
  'title.listOfCompanies': ' Daftar Perusahaan ',
  'title.listOfJobTypes': 'Daftar Tipe Pekerjaan',
  'title.jobTypes': 'Daftar Tipe Pekerjaan',
  'title.jobType': 'Tipe Pekerjaan',
  'title.newJobType': 'Buat tipe pekerjaan',
  'title.editJobType': 'Edit tipe pekerjaan',
  'title.hqUsers': ' Manajer HQ ',
  'title.listOfHQUsers': ' Daftar Manajer HQ',
  'title.newHQUsers': ' Pendaftaran Manajer HQ ',
  'title.editHQUsers': ' Edit Manajer HQ',
  'title.applicants': 'Kandidat',
  'title.listOfApplicants': 'Daftar kandidat',
  'title.newApplicant': 'Buat Kandidat',
  'title.editApplicant': 'Edit Kandidat ',
  'title.educationalInstitutes': 'Sekolah',
  'title.educationalInstitutesHasTrans': 'Sekolah',
  'title.listOfEducationInstitutes': 'Daftar Sekolah',
  'title.newEducationalInstitute': 'Buat Sekolah',
  'title.editEducationalInstitute': 'Edit Sekolah ',
  'title.locations': 'Cabang',
  'title.listOfLocations': 'Daftar Cabang',
  'title.locationFeedback': 'Feedback Cabang',
  'title.billingRecords': 'Riwayat pembayaran',
  'title.jodCredits': 'Kredit Jod',
  'title.assignCredit': ' Kredit',
  'title.creditHistory': ' Riwayat Kredit ',
  'title.payments': 'Pembayaran',
  'title.jobSummary': 'Ringkasan pembayaran ',
  'title.jobPayments': 'Pembayaran pekerjaan',
  'title.futurePayments': 'Pembayaran Baru',
  'title.report': 'Laporan',
  'title.usersAndPermissions': 'Pengguna dan Hak Akses ',
  'title.users': 'Pengguna',
  'title.listOfInternalUsers': 'Daftar pengguna internal',
  'title.newInternalUser': 'Buat pengguna internal',
  'title.editInternalUser': 'Edit pengguna internal',
  'title.roles': 'Peran',
  'title.listOfRoles': 'Daftar Peran',
  'title.newRole': 'Buat peran',
  'title.newInternalRole': 'Buat peran internal',
  'title.editRole': ' Edit peran',
  'title.permissions': 'Hak Akses',
  'title.configuration': ' Konfigurasi ',
  'title.notifications': 'Pemberitahuan',
  'title.notificationConfigurations': 'Pengaturan notifikasi',
  'title.other': 'Lainnya',
  'title.otherConfigurations': 'Konfigurasi lainnya',
  'title.badgeConfigurations': 'Pembentuk Lencana',
  'title.jodBadges': 'Lencana Jod',
  'title.jodBadgesConfiguration': 'Pengaturan Lencana Jod ',
  'title.locationList': 'Daftar Cabang',
  'title.addNewLocation': 'Buat cabang',
  'title.managers': ' Manajer ',
  'title.managerList': ' Daftar Manajer ',
  'title.addNewManager': 'Buat Manajer ',
  'title.locationProfile': 'Profil Cabang ',
  'title.jobTemplates': 'Template pekerjaan ',
  'title.jobTemplateList': 'Contoh template pekerjaan ',
  'title.addNewJobTemplate': 'Buat template pekerjaan ',
  'title.jobPosting': 'Postingan pekerjaan',
  'title.allJobPosting': 'Postingan pekerjaan',
  'title.createNewJob': 'Buat pekerjaan baru',
  'title.manageJobs': ' Atur pekerjaan ',
  'title.updateJobs': 'Perbarui pekerjaan',
  'title.cancelJobs': ' Batalkan pekerjaan',
  'title.jobOverview': 'Ringkasan pekerjaan',
  'title.hiringOverview': 'Ringkasan perekrutan',
  'title.creditOverview': 'Ringkasan kredit',
  'title.ratingOverview': 'Ringkasan rating',
  'title.addNewAreaManager': 'Buat manajer area ',
  'title.newLocation': 'Buat cabang',
  'title.editLocation': 'Edit cabang ',
  'title.assignJodCredit': 'Kredit Jod ',
  'title.listOfManagers': ' Daftar manajer ',
  'title.managerRegistration': 'Daftar Manajer ',
  'title.editLocationManager': 'Edit manajer cabang ',
  'title.editAreaManager': 'Edit manajer area',
  'title.editManager': 'Edit manajer ',
  'title.addJobTemplate': 'Buat template pekerjaan ',
  'title.editJobTemplate': ' Edit template pekerjaan ',
  'title.jobHistory': ' Riwayat ',
  'title.candidateProfile': ' Profil Kandidat ',
  'title.listOfAllCandidates': 'Daftar kandidat',
  'title.candidateOfJob': 'Calon kandidat',
  'title.candidatesOfNamedJob': 'kandidat posisi {{job}}',
  'title.candidatesOfJob': 'Calon kandidat',
  'title.qrCode': 'Kode QR',
  'title.jobInformation': 'Informasi pekerjaan',
  'title.jobNote': 'Catatan pekerjaan',
  'title.workerInformation': 'Informasi Kandidat',
  'title.jobEdit': 'Edit',
  'title.copyJob': 'Salin',
  'title.clockInOut': 'Clock In/Out',
  'title.howToUse': 'Tutorial  ',
  'title.howToPostJobs': 'Tutorial Cara memposting pekerjaan ',
  'title.howToMakeSelections': 'Tutorial Bagaimana memilih kandidat',
  'title.howToClockInAndOut': 'Tutorial memulai dan menyelesaikan pekerjaan',
  'title.currentAvailableCredit': 'Jumlah Kredit Jod Tersedia',
  'title.redirecting': ' Redirect ',
  'title.loadPage': 'Mohon tunggu...',
  'title.unassignedCredit': 'Kredit Jod Tersedia',
  'title.hqLevel': ' Manajer HQ ',
  'title.uploadCertification': 'Unggah Sertifikat',
  'title.hiringManager': 'Hiring Manager',
  'title.appConfig': 'Konfigurasi App',
  'title.bulkClockOut': 'Bulk Clock Out',

  'message.maxDistanceRequired': 'Jarak maksimal wajib diisi.',
  'message.expired_datetimeRequired': 'Please select expired datetime',
  'message.distance_must_be_greater_than_zero': 'Jarak harus lebih dari 0',
  'message.changePassword': 'Ubah kata sandi',
  'message.confirmPasswordMustMatchPassword': 'Kata sandi harus cocok',
  'message.totalNumberOf': 'Total nomor ',
  'message.numberOf': 'Urutan ke- ',
  'message.actionFailed': 'Eksekusi gagal, silakan coba lagi !',
  'message.somethingWentWrong': 'Eksekusi gagal, silakan coba lagi !',
  'message.emailOrPasswordIncorrect': ' Email atau kata sandi salah ',
  'message.enableCompany': 'Anda telah memilih untuk mengaktifkan perusahaan ini.',
  'message.disableCompany': 'Anda telah memilih untuk menonaktifkan perusahaan ini.',
  'message.disableAccount': 'Anda telah memilih untuk menonaktifkan akun ini . ',
  'message.enableAccount': 'Anda telah memilih untuk mengaktifkan akun ini . ',
  'message.validateMinLessThanMaxCredits':
    ' Jumlah kredit Jod yang mungkin harus kurang dari jumlah maksimum kredit Jod ',
  'message.validateMaxGreaterThanMinCredits':
    ' Jumlah maksimum kredit Jod harus lebih besar dari jumlah minimum kredit Jod ',
  'message.validateOnlyIntegerNumber': ' Hanya bilangan bulat yang diperbolehkan ',
  'message.pleaseEnterContactNumber': 'Masukkan nomor telepon ',
  'message.contactNumberMustBeNumber': ' Nomor telepon wajib angka ',
  'message.contactNumberMustBe8Digits': ' Nomor telepon terdiri dari 8 digit ',
  'message.contactNumberMustBe9Digits': ' Nomor telepon terdiri dari 9 digit ',
  'message.contactNumberMustBe10Digits': ' Nomor telepon terdiri dari 10 digit ',
  'message.contactNumber9Or10': ' Nomor telepon terdiri dari 9 sampai 12 digit . ',
  'message.minLessThanMaxConsumeCredit':
    ' Jumlah kredit Jod harus kurang dari jumlah maksimal kredit Jod ',
  'message.maxGreaterThanMaxConsumeCredit':
    ' Jumlah maksimum kredit Jod  harus lebih besar dari jumlah minimum kredit Jod ',
  'message.validateNotBeGreaterThan255Characters': 'Jumlah karakter maksimal 255 karakter ',
  'message.validateTypeFileCompanyAvatar': ' Avatar harus dalam format {{files}}',
  'message.validateTypeFileCompanyLogo': 'Logo harus dalam format {{files}}',
  'message.validateTypeFileJobTypeLogo': 'Logo harus dalam format {{files}}',
  'message.validateTypeFileContractService': 'Kontrak harus bertipe file {{file}}',
  'message.pleaseUploadServiceContract': ' Unggah kontrak layanan ',
  'message.validateTypeFileCompanyBanner': 'Banner harus berupa {{file}}',
  'message.registrationCompleted': 'Pendaftaran selesai.',
  'message.changeCompleted': 'Perubahan selesai.',
  'message.wantToSubmit': 'Ingin kirim sekarang?',
  'message.wantToSave': 'Simpan sekarang?',
  'message.wantToContinue': ' Lanjutkan sekarang?',
  'message.pleaseSelectCompany': 'Pilih perusahaan ',
  'message.selectCompany': 'Pilih perusahaan ',
  'message.pleaseEnterCompanyName': 'Masukkan nama perusahaan ',
  'message.pleaseEnterCompanyAddress': ' Masukkan alamat perusahaan ',
  'message.pleaseEnterBusinessCompany': 'Masukkan nomor perusahaan Anda ',
  'message.pleaseEnterMinJODCreditLimit': 'Masukkan Kreditt Jod Limit',
  'message.enableJobType': 'Anda telah memilih untuk mengaktifkan tipe pekerjaan ini.',
  'message.disableJobType': 'Anda telah memilih untuk menonaktifkan tipe pekerjaan ini.',
  'message.pleaseEnterJobTitle': 'Masukkan posisi pekerjaan',
  'message.pleaseEnterJobDescription': 'Masukkan deskripsi pekerjaan ',
  'message.pleaseEnterComment': 'Masukkan komentar',
  'message.commentLengthCannotExceed255Characters': 'Jumlah karakter maksimal 255 karakter',
  'message.minAvailablePositionsLargerThanOrEqualTo0':
    ' Jumlah harus lebih besar dari atau sama dengan 0',
  'message.maxAvailablePositionsLargerThanOrEqualTo0':
    ' Jumlah maksimum pekerjaan harus lebih besar dari atau sama dengan 0 ',
  'message.minAvailableCompaniesLargerThanOrEqualTo0':
    ' Jumlah minimum mitra harus lebih besar dari atau sama dengan 0 ',
  'message.maxAvailableCompaniesLargerThanOrEqualTo0':
    ' Jumlah maksimum perusahaan harus lebih besar dari atau sama dengan 0 ',
  'message.minLessThanMaxAvailablePositions':
    ' Jumlah pekerjaan yang mungkin harus kurang dari jumlah maksimum pekerjaan ',
  'message.maxGreaterThanMinAvailablePositions':
    ' Jumlah pekerjaan maksimum harus lebih besar dari jumlah pekerjaan minimum ',
  'message.minLessThanMaxAvailableCompanies':
    ' Jumlah minimal perusahaan harus lebih sedikit dari jumlah maksimal perusahaan ',
  'message.maxGreaterThanMinAvailableCompanies':
    ' Jumlah maksimal perusahaan harus lebih banyak dari jumlah minimal perusahaan ',
  'message.pleaseEnterFirstName': 'Masukkan nama depan',
  'message.pleaseEnterLastName': 'Masukkan nama belakang',
  'message.pleaseEnterWorkEmail': 'Masukkan email kantor',
  'message.workEmailMustBeValidEmailAddress': 'Email kantor harus dikonfirmasi ',
  'message.firstNameHasMaximum100Characters': 'Nama depan maksimal sampai 100 karakter',
  'message.firstNameHasMinimum2Characters': 'Nama depan minimal boleh 2 karakter ',
  'message.firstNameAlphabetsOnlyNoNumericNoSpecialCharacters': `Nama harus sesuai abjad , tidak termasuk digit , karakter khusus (kecuali ,-/'@). `,
  'message.lastNameHasMaximum100Characters': 'Nama belakang memiliki maksimal 100 karakter',
  'message.lastNameHasMinimum2Characters': 'Nama belakang memiliki setidaknya 2 karakter ',
  'message.lastNameAlphabetsOnlyNoNumericNoSpecialCharacters': `Nama belakang harus dalam alfabet , tidak termasuk digit , karakter khusus (kecuali ,-/'@).`,
  'message.enableLocation': 'Anda telah memilih untuk mengaktifkan cabang ini.',
  'message.disableLocation': 'Anda telah memilih untuk menonaktifkan cabang ini.',
  'message.noData': 'Tidak ada data',
  'message.locationNotFound': ' Tidak dapat menemukan hasil apa pun ',
  'message.onlyAllowTwoDecimalPlace': ' Maksimum 2 angka desimal diperbolehkan ',
  'message.commentRequired': 'Masukkan komentar',
  'message.creditAmountRequired': 'Masukkan jumlah kredit Jod',
  'message.locationLogoMustBe': 'Logo harus dalam format  {{files}}',
  'message.locationNameRequired': 'Wajib masukkan nama cabang',
  'message.locationAddressRequired': 'Wajib masukkan alamat cabang ',
  'message.minJODCreditRequired': ' Masukkan batas koin Jod minimum ',
  'message.firstNameRequired': 'Masukkan nama depan',
  'message.lastNameRequired': 'Masukkan nama belakang',
  'message.jobTitleRequired': 'Masukkan posisi pekerjaan',
  'message.workEmailRequired': 'Masukkan email kantor',
  'message.enableManager': 'Anda telah memilih untuk mengaktifkan manajer ini .',
  'message.disableManager': 'Anda telah memilih untuk menonaktifkan manajer ini .',
  'message.managerTypeRequired': 'Pilih jenis manajer ',
  'message.pleaseEnterApplicantFirstName': 'Masukkan nama depan kandidat',
  'message.pleaseEnterApplicantLastName': 'Masukkan nama belakang kandidat',
  'message.pleaseEnterReasonHere': 'Masukkan alasan',
  'message.enableAccountBelowPreviousReasonOfDisabling':
    'Tinjau alasan mengapa akun Anda dinonaktifkan sebelum mengaktifkannya kembali.',
  'message.disableAccountPleaseClarifyYourReason':
    'Harap berikan alasan sebelum menonaktifkan akun.',
  'message.choseMarkApplicantIdentityAsValid': 'Informasi kandidat yang valid',
  'message.choseMarkApplicantIdentityAsInvalid': 'Informasi kandidat tidak valid',
  'message.displayNameRequired': 'Masukkan nama tampilan ',
  'message.displayNameMax100Char': ' Nama tampilan bisa sampai 100 karakter',
  'message.displayNameMin2Char': ' Nama tampilan harus minimal 2 karakter ',
  'message.displayNameMustBeUserName': ` Nama tampilan harus sesuai abjad , tidak termasuk digit , karakter khusus (kecuali ,-/'@) .`,
  'message.pleaseEnterApplicantDateOfBirth': 'Masukkan tanggal lahir kandidat',
  'message.ageBetween': ' Kandidat harus dari {{from}} sampai { {to}}.',
  'message.pleaseEnterGenderInOptionsAbove': 'Masukkan jenis kelamin',
  'message.formatInvalidInNRIC/FIN': 'KTP memerlukan 16 karakter.',
  'message.NRIC/FINrequired': 'Masukkan nomor KTP ',
  'message.finDocumentRequired': '',
  'message.pleaseEnterApplicantContactNumber': 'Masukkan nomor telepon kandidat ',
  'message.emailMustBeValidEmailAddress': 'Email tidak valid',
  'message.pleaseEnterApplicantEmail': 'Masukkan email kandidat',
  'message.validateTypeFileApplicantFoodHygieneCert':
    'Demi keamanan dan kebersihan makanan harus berupa jenis file',
  'message.pleaseUploadFoodHygieneCert': 'Unggah sertifikat higienis makanan',
  'message.minimumRatingLargerThanOrEqualTo0':
    'Minimum rating harus lebih besar dari atau sama dengan 0 ',
  'message.maximumRatingLargerThanOrEqualTo0':
    'Maksimum rating harus lebih besar dari atau sama dengan 0 ',
  'message.amountJobGreaterThanOrEqualTo0':
    ' Jumlah tugas harus lebih besar dari atau sama dengan 0',
  'message.minimumRatingLessThan5': ' Tumpukan penting harus kurang dari 5 ',
  'message.maximumRatingLessThan5': ' tumpukan maksimal harus lebih besar dari atau sama dengan 5 ',
  'message.minimumRatingLessThanMaximumRating':
    ' Rating minimum harus lebih kecil dari rating maksimum ',
  'message.maximumRatingGreaterThanMinimumRating':
    ' Rating maksimum harus lebih besar dari rating minimum ',
  'message.choseDisableInstitute': 'Anda telah memilih untuk menonaktifkan kolom ini.',
  'message.choseEnableInstitute': 'Anda telah memilih untuk mengaktifkan kolom ini.',
  'message.pleaseEnterInstituteName': 'Masukkan nama institusi',
  'message.pleaseEnterInstituteDescription': 'Masukkan deskripsi institusi ',
  'message.pleaseEnterInstituteAddress': 'Masukkan alamat institusi ',
  'message.enableJobTemplate': 'Anda telah memilih untuk mengaktifkan template ini .',
  'message.disableJobTemplate': 'Anda telah memilih untuk menonaktifkan template ini .',
  'message.templateNameRequired': 'Masukkan template pekerjaan ',
  'message.templateNameMax100Char': ' Nama template hingga 100 karakter ',
  'message.templateNameMin2Char': ' Nama template minimal 2 karakter ',
  'message.jobTitleMax100Char': 'Judul hingga 100 karakter',
  'message.jobTitleMin2Char': 'Judul boleh minimal 2 karakter ',
  'message.jobTypeRequired': 'Masukkan daftar tipe pekerjaan',
  'message.jobDescriptionRequired': 'Masukkan deskripsi pekerjaan ',
  'message.jobStartDateRequired': ' Pilih tanggal mulai ',
  'message.jobStartDateBeforeJobEndDate': ' Tanggal mulai harus sebelum tanggal selesai ',
  'message.jobEndDateRequired': 'Pilih tanggal selesai ',
  'message.jobEndDateAfterJobStartDate': ' Tanggal selesai harus setelah tanggal mulai ',
  'message.hourlyRateGreaterThanZero': 'Gaji per jam harus lebih besar dari 0',
  'message.dateMustBeBeforeOrEqualCurrentDateTime':
    'Tanggal harus sebelum atau sama dengan tanggal saat ini',
  'message.pleaseEnterAssigningAmount': 'Masukkan jumlah kredit yang valid ',
  'message.confirmAssignCredits': ' Konfirmasi Kredit Jod ? ',
  'message.minimumAssignAmountLessThanMaximumAssignAmount':
    ' Jumlah minimum kredit Jod ( dikeluarkan ) harus lebih kecil dari jumlah maksimum kredit Jod ( dikeluarkan ) ',
  'message.maximumAssignAmountGreaterThanMinimumAssignAmount':
    ' Jumlah maksimum kredit Jod ( diberikan ) harus lebih besar dari jumlah maksimum kredit Jod ( diberikan )',
  'message.selectedPayment': 'Anda telah memilih <0 ><1> {{countSelected}} </1></0> pembayaran',
  'message.selectedMultiplePayment':
    'Anda telah memilih <0 ><1> {{countSelected}} </1></0> pembayaran',
  'message.wantToProcessThesePayments': ' Lanjutkan memproses pembayaran ?',
  'message.applicantMustCompleteFeedbackBeforePaymentIsProcessed':
    ' Pelamar harus menyelesaikan feedback untuk memproses pembayaran ',
  'message.applicantJobDetailsOnlyShownWhenClockOutDone':
    ' Detail pekerjaan hanya ditampilkan setelah kandidat menyelesaikan pekerjaan ',
  'message.jobNotFound': ' Tidak dapat menemukan pekerjaan',
  'message.pleaseSelectClockInTime': 'Pilih waktu untuk bekerja ',
  'message.pleaseSelectClockOutTime': 'Pilih jam pulang kerja ',
  'message.pleaseSelectBreakTime': 'Pilih istirahat ',
  'message.pleaseEnterWagePerHour': 'Masukkan gaji per jam',
  'message.pleaseSelectBank': 'Pilih bank',
  'message.wagePerHourMustBeLargerThan0': 'Upah per jam harus lebih besar dari 0',
  'message.clockInTimeMustBeBeforeClockOutTime': ' Jam kerja harus sebelum jam pulang ',
  'message.clockOutTimeMustBeAfterClockInTime': ' Jam pulang harus setelah jam masuk ',
  'message.breakTimeLessThanTotalWorkingHours':
    ' Jumlah jam istirahat harus kurang dari jumlah jam kerja. ',
  'message.pleaseEnterAllowance': 'Masukkan tunjangan ',
  'message.jodAllowanceLargerThan0': 'Tunjangan Jod harus lebih besar dari 0',
  'message.bankHolderNameLessThanOrEqualTo35Char':
    ' Nama pemegang rekening bank maksimal 35 karakter',
  'message.bankAccountNumberMustBeBetween8to15Digits':
    ' Nomor rekening bank dari 8 hingga 16 digit ',
  'message.bankAccountNumberMustBeBetween9to12Digits': 'Nomor rekening bank dari 8 hingga 16 digit',
  'message.min12To16DigitsNoDashOtherSpecialChar':
    'Memiliki antara 12 dan 16 digit , tanpa tanda hubung atau karakter khusus',
  'message.min9To12DigitsNoDashOtherSpecialChar':
    'Memiliki antara 9 dan 12 digit , tanpa tanda hubung atau karakter khusus',
  'message.min8To15DigitsNoDashOtherSpecialChar':
    'Memiliki antara 8 dan 15 digit , tanpa tanda hubung atau karakter khusus',

  'message.bankHolderNameFormatIsInvalid': ' Format nama pemegang rekening bank tidak valid ',
  'message.bankAccountNumberFormatIsInvalid': ' Format nomor rekening bank tidak valid ',

  'message.limitTo35CharAlphaOnlyNoDashOtherSpecialChar':
    'Batas 35 karakter, termasuk alfabet, tanpa tanda hubung atau karakter khusus',

  'message.wantToProcessThisPayment': ' Lanjutkan memproses pembayaran?',
  'message.wantToDeleteJob': 'Hapus pekerjaan ini?',
  'message.wantToRejectJob': 'Menolak pekerjaan ini ?',
  'message.wantToApproveJob': 'Menyetujui pekerjaan ini?',
  'message.pleaseScanQrCode': 'Pindai kode QR',
  'message.cancelApplicant': ' Batalkan kandidat ini?',
  'message.clockInBeforeClockOut': ' Jam kerja harus sebelum jam masuk ',
  'message.clockOutAfterClockIn': ' Jam masuk harus setelah jam keluar ',
  'message.ratingRequired': 'Pilih rating ',
  'message.feedbackRequired': 'Feedback wajib diisi.',
  'message.workingHoursMoreThan18Hours': 'Waktu kerja tidak melebihi 18 jam',
  'message.deletedAccount': 'Akun telah dihapus ',
  'message.haveSelected': 'Kamu telah memilih',
  'message.wantToProcess': ' Lanjutkan pemrosesan ? ',
  'message.templateNameOptionRequired': 'Pilih template pekerjaan ',
  'message.locationRequired': 'Pilih cabang',
  'message.dateRangeRequired': 'Pilih waktu kerja',
  'message.jobStartDateAfterCurrent': ' Waktu mulai harus setelah waktu saat ini ',
  'message.jobEndDateAfterCurrent': ' Waktu akhir harus setelah waktu saat ini',
  'message.jobStartBeforeJobEnd': ' Waktu mulai harus sebelum waktu saat ini ',
  'message.earningPerHourRequired': 'Masukkan gaji per jam',
  'message.earningPerHourLargerThan0': 'Upah per jam harus lebih besar dari 0',
  'message.atLeastOneWorkingDay': 'Pilih setidaknya satu hari kerja',
  'message.postingNewJob': 'Anda baru saja memposting pekerjaan .',
  'message.wantToChangeValueOfSelectedCountOtherConfiguration':
    'Ubah nilai konfigurasi <0 /> {{selectedCount}}? ',
  'message.wantToChangeValueOfSelectedCountMultipleOtherConfiguration':
    'Ubah nilai konfigurasi <0 /> {{selectedCount}}? ',
  'message.wantToChangeValueOfBadgeConfiguration': 'Ubah nilai konfigurasi <0 /> {{label}}? ',
  'message.wantToChangeStatusOfEmailNotification':
    'Apakah Anda ingin mengubah <0 /> Status pemberitahuan email {{selectedCount}}?',
  'message.wantToChangeStatusPushNotification':
    'Apakah Anda ingin mengubah <0 /> status notifikasi aplikasi {{selectedCount}}?',
  'message.wantToChangeStatusSMSNotification':
    'Apakah Anda ingin mengubah <0 /> status pemberitahuan SMS {{selectedCount}}?',
  'message.validateTypeFileLocationLogo': 'Nama logo harus format',
  'message.pleaseEnterPassword': 'Masukkan kata sandi ',
  'message.pleaseEnterNewPassword': 'Masukkan kata sandi baru ',
  'message.pleaseEnterConfirmPassword': 'Masukkan kembali kata sandi baru ',
  'message.newPasswordMinimum6Char': 'Password baru harus minimal 6 karakter ',
  'message.confirmPasswordMinimum6Char': 'Konfirmasi Password harus minimal 6 karakter ',
  'message.passwordMinimum6Char': ' Kata sandi harus minimal 6 karakter . ',
  'message.choseDisableRole': 'Anda telah memilih untuk menonaktifkan peran ini.',
  'message.choseEnableRole': 'Anda telah memilih untuk mengaktifkan peran ini.',
  'message.choseDisableUser': 'Anda telah memilih untuk menonaktifkan pengguna ini.',
  'message.choseEnableUser': 'Anda telah memilih untuk mengaktifkan pengguna ini.',
  'message.pleaseSelectRole': 'Pilih peran',
  'message.pleaseEnterRoleName': 'Masukkan nama peran',
  'message.pleaseEnterDescription': 'Masukkan deskripsi peran ',
  'message.descriptionMustBeGreaterThan255Char': 'Deskripsi bisa sampai 255 karakter ',
  'message.minimumWageLargerOrEqual0': ' Gaji minimum harus lebih besar dari atau sama dengan 0',
  'message.minimumWageLessThanMaximumWage': ' Gaji minimum harus lebih kecil dari gaji maksimum ',
  'message.maximumWageGreaterThanMinimumWage':
    ' Gaji maksimum harus lebih besar dari gaji minimum ',
  'message.maximumWageLargerOrEqual0': ' Gaji maksimum harus lebih besar dari atau sama dengan 0',
  'message.wantToUpdateWagePerHourForAJob':
    'Perubahan yang dikonfirmasi : <0/> upah per jam dari {{currentWage}} ke { {newWage}} untuk {{jobCount}}.',
  'message.wantToUpdateWagePerHourForMultiJob':
    'Perubahan yang dikonfirmasi : <0/> upah per jam dari {{currentWage}} ke { {newWage}} untuk {{jobCount}}.',
  'message.currentWageRequired': 'Masukkan gaji per jam saat ini',
  'message.currentWageLargerThan0': 'Upah per jam harus lebih besar dari atau sama dengan 0',
  'message.newWageRequired': 'Masukkan gaji per jam baru',
  'message.newWageLargerThan0': 'Upah per jam harus lebih besar dari atau sama dengan 0',
  'message.differentValidValueForCurrentAndNewWage':
    'Masukkan nilai yang berbeda untuk upah per jam saat ini dan upah per jam yang baru',
  'message.selectedAJob': 'Anda telah memilih <0><1> {{selectedCount}} </1></0> pekerjaan',
  'message.selectedMultipleJob': 'Anda telah memilih <0><1> {{selectedCount}} </1></0> pekerjaan',
  'message.wantToCancelAJob': ' Lanjut membatalkan pekerjaan ?',
  'message.wantToCancelMultipleJob': ' Lanjut membatalkan pekerjaan ?',
  'message.totalNumberOfAvailableCredits': 'Jumlah Kredit Jod <0/>tersedia',
  'message.consumedCreditCurrentMonth': 'Jumlah Kredit Jod yang digunakan <0/> (bulan ini)',
  'message.jodCreditsAvailableUnderAreaManager':
    'Jumlah Kredit Jod yang tersedia <0/> di manajer area',
  'message.jodCreditsAvailableUnderLocationManager':
    'Jumlah Kredit Jod yang tersedia <0/> di manajer cabang',
  'message.minimumTotalJodCreditLessThanMaximumTotalJodCredit':
    'Jumlah maksimum Kredit Jod harus kurang dari jumlah maksimum Kredit Jod ',
  'message.maximumTotalJodCreditGreaterThanMinimumTotalJodCredit':
    'Jumlah maksimum Kredit Jod harus lebih besar dari jumlah minimum Kredit Jod ',
  'message.wantToChangeTheLogo': 'Apakah Anda ingin mengubah logo ? ',
  'message.totalNumberOfPartnerCompanies': ' Jumlah mitra <0 /> ',
  'message.totalNumberOfHQManagers': 'Jumlah pencari lokasi < 0 / > ',
  'message.totalNumberOfFuturePayments': ' Nomor pembayaran baru <0 /> ',
  'message.minimumLessThanMaximumAvailableConsumed':
    'Jumlah yang digunakan harus lebih kecil dari jumlah maksimal yang digunakan ',
  'message.maximumGreaterThanMinimumAvailableConsumed':
    'Jumlah maksimum yang digunakan harus lebih besar dari jumlah minimum yang digunakan ',
  'message.totalNumberOfJobs': ' Jumlah pekerjaan <0 />',
  'message.totalNumberOfRejectCandidates': 'Jumlah Kandidat ditolak <0 />',
  'message.createdApplicantUnsuccess': 'Buat Gagal',
  'message.updatedApplicantUnsuccess': 'Pembaruan gagal',
  'message.enableApplicantUnsuccess': 'Aktivasi gagal.',
  'message.disableApplicantUnsuccess': 'Gagal menonaktifkan',
  'message.updateApplicantIdentityStatusUnsuccess': 'Pembaruan identitas kandidat gagal',
  'message.downloadSuccess': ' Pengunduhan berhasil ',
  'message.createdAreaManagerUnsuccess': 'Buat Gagal',
  'message.updatedAreaManagerUnsuccess': 'Pembaruan gagal',
  'message.disableAreaManager': 'Gagal menonaktifkan',
  'message.enableAreaManager': 'Aktivasi gagal.',
  'message.createdCompanyUnsuccess': 'Buat Gagal',
  'message.updatedCompanyUnsuccess': 'Pembaruan gagal',
  'message.disableCompanyUnsuccess': 'Gagal menonaktifkan',
  'message.enableCompanyUnsuccess': 'Aktivasi gagal.',
  'message.bannerCompanyHasNotBeenUploaded': 'Banner mitra belum diunggah ! ',
  'message.uploadBannerCompanyUnsuccess': 'Unduhan tidak berhasil',
  'message.exportSuccess': ' Ekspor file berhasil',
  'message.changeStatusNotificationUnsuccess': 'Mengganti status notifikasi gagal',
  'message.changeValueOtherConfigurationUnsuccess': 'Ubah nilai konfigurasi gagal ',
  'message.assignCreditUnsuccess': 'Setoran koin Jod gagal ',
  'message.haveNoPermissionAccessFeature': ' Belum diizinkan untuk menggunakan fitur ',
  'message.userAdminLogoFileHasNotBeenUploadYet': 'Logonya belum dipasang ',
  'message.updateFailed': 'Pembaruan gagal',
  'message.getAdminLogoFailed': 'Pengunduhan logo gagal ',
  'message.disableEducationalInstitutesUnsuccess': 'Gagal menonaktifkan',
  'message.enableEducationalInstitutesUnsuccess': 'Aktivasi gagal.',
  'message.createdHQManagerUnsuccess': 'Buat Gagal',
  'message.updatedHQManagerUnsuccess': 'Pembaruan gagal',
  'message.disableHQUserUnsuccess': 'Gagal menonaktifkan',
  'message.enableHQUserUnsuccess': 'Gagal menonaktifkan',
  'message.createdUserUnsuccess': 'Buat Gagal',
  'message.updatedUserUnsuccess': 'Pembaruan gagal',
  'message.disableUserUnsuccess': 'Gagal menonaktifkan',
  'message.enableUserUnsuccess': 'Aktivasi gagal.',
  'message.disableJobTemplateUnsuccess': 'Gagal menonaktifkan',
  'message.enableJobTemplateUnsuccess': 'Aktivasi gagal.',
  'message.createdJobTemplateUnsuccess': 'Buat Gagal',
  'message.updatedJobTemplateUnsuccess': 'Pembaruan gagal',
  'message.createdJobTypeUnsuccess': 'Buat Gagal',
  'message.updatedJobTypeUnsuccess': 'Pembaruan gagal',
  'message.disableJobTypeUnsuccess': 'Gagal menonaktifkan',
  'message.enableJobTypeUnsuccess': 'Aktivasi gagal.',
  'message.createdNewJobUnsuccess': 'Buat Gagal',
  'message.updatedNewJobUnsuccess': 'Pembaruan gagal',
  'message.deletedJobFailed': 'Gagal dihapus',
  'message.approveJobFailed': 'Penjelajahan gagal',
  'message.cancelApplicantFailed': 'Pembatalan tidak berhasil',
  'message.cancelUnsuccess': 'Pembatalan tidak berhasil',
  'message.createdLocationManagerUnsuccess': 'Buat Gagal',
  'message.updatedLocationManagerUnsuccess': 'Pembaruan gagal',
  'message.createdLocationUnsuccess': 'Buat Gagal',
  'message.updatedLocationUnsuccess': 'Pembaruan gagal',
  'message.disableLocationUnsuccess': 'Gagal menonaktifkan',
  'message.enableLocationUnsuccess': 'Aktivasi gagal.',
  'message.pleaseSelectProvince': 'Please select province',
  'message.pleaseSelectDistrict': 'Please select district',
  'message.pleaseSelectCity': 'Pilih Kota/Kabupaten',

  'message.confirmUnsuccess': 'Tidak berhasil',
  'message.downloadApplicantJobSummaryUnsuccess': 'Unduhan tidak berhasil',
  'message.createdRoleUnsuccess': 'Gagal membuat peran baru',
  'message.updatedRoleUnsuccess': 'Pembaruan gagal',
  'message.disableRoleUnsuccess': 'Gagal menonaktifkan',
  'message.enableRoleUnsuccess': 'Aktivasi gagal.',
  'message.passwordChangeFailed': ' Kata sandi gagal ',
  'message.youNoPermissionAccessPage': ' Anda harus diberikan akses ',
  'message.hourlyRate': 'Masukkan gaji per jam',
  'message.tableSummary': ' Menampilkan dari {{from}} hingga  {{to}} dari {{total}}',
  'message.invalidPasswordResetLink':
    'URL pengaturan ulang kata sandi Anda tidak lagi valid. Silakan coba lagi .',
  'message.duplicatedEmailError': 'Email sudah digunakan',
  'message.valueMustBeAlphabeticOnly': 'Nilainya harus berupa huruf.',
  'message.candidate': 'Kandidat',
  'message.candidates': 'Para Kandidat',
  'message.valueMustBeNumericOnly': 'Nilainya harus berupa alfanumerik . ',
  'message.total_salary_is_not_greater_than_total_credit': 'Kredit JOD tidak mencukupi.',
  'message.credits_must_be_greater_than_zero': 'Kredit harus lebih besar dari nol.',
  'message.credits_must_be_less_than_unassigned_credits':
    'Kredit harus kurang dari kredit yang belum ditetapkan.',
  'message.valueMustBeAlphaNumeric': 'Value must be alphanumeric.',
  'message.pleaseSelectAverageRating': 'Please select average rating',
  'message.commonRequired': 'Wajib diisi.',
  'message.breaktimeStartRequired': 'Please select breaktime start',
  'message.breaktimeStartAfterCurrent': 'Breaktime start must be after current datetime',

  'value.all': ' Semuanya ',
  'value.enable': 'Aktifkan',
  'value.disable': 'Batalkan',

  'value.include': 'Termasuk',
  'value.exclude': ' Tidak termasuk ',
  'value.male': 'Laki-laki',
  'value.female': 'Perempuan',
  'value.other': 'Lainnya',
  'value.recentAddedLocations': ' Cabang baru dibuat ',
  'value.enableLocations': 'Aktifkan',
  'value.disableLocations': 'Batalkan',
  'value.location': 'Cabang',
  'value.area': 'Area',
  'value.locationManager': 'Manajer Cabang ',
  'value.areaManager': 'Manajer Area ',
  'value.recentAddedManagers': ' Baru-baru ini dibuat oleh manajemen ',
  'value.disableManagers': 'Batalkan',
  'value.enableManagers': 'Aktifkan',
  'value.jobWithApplicants': 'Memiliki calon',
  'value.recentAddedJobTemplates': ' Kreasi Terbaru ',
  'value.enableJobTemplates': 'Aktifkan',
  'value.disableJobTemplates': 'Batalkan',
  'value.yearOld': '{{age}} tahun',
  'value.inThisSlot': 'Hanya dalam shift ini',
  'value.inAllSlot': 'Dalam semua shift yang belum selesai ',
  'value.singleDayJobs': 'Pekerjaan satu hari',
  'value.multiDayJobs': 'Bekerja banyak sepanjang hari',
  'value.pending': 'Menunggu persetujuan',
  'value.open': 'Terbuka',
  'value.active': 'Aktif',
  'value.completed': 'Lengkap',
  'value.cancelled': ' Batalkan ',
  'value.activeButNotClockIn': 'Menunggu',
  'value.jan': 'Jan',
  'value.feb': 'Feb',
  'value.mar': 'Mar',
  'value.apr': 'Apr',
  'value.may': 'Mei',
  'value.jun': 'Jun',
  'value.jul': 'Jul',
  'value.aug': 'Agu',
  'value.sep': 'Sep',
  'value.oct': 'Okt',
  'value.nov': 'Nov',
  'value.dec': 'Des',
  'value.unprocessed': 'Belum diproses',
  'value.processed': 'Diproses',
  'value.inProgress': 'Dalam proses',
  'value.perPage': '/ page',
  'value.hired': 'Direkrut',
  'value.rejected': ' Ditolak oleh manajer ',
  'value.selfCancelled': 'Membatalkan sendiri',
  'value.ack1': 'ACK 1',
  'value.ack2': 'ACK 2',
  'value.clockedIn': 'Masuk',
  'value.clockedOut': 'Pulang',
  'value.adminDisable': 'Ditolak karena dinonaktifkan oleh Admin',
  'value.noShow': 'Tidak muncul',
  'value.noOptions': 'Tidak ada pilihan',
  'value.applied': 'Dilamar',
  'value.selected': 'Dipilih',
  'value.unselected': 'Tidak dipilih',
  'value.preSelectSelfCancelled': 'Calon membatalkan sebelum memilih',
  'value.timeOverlapReject': ' Dibatalkan karena waktu yang sama',
  'value.postSelectSelfCanceled': 'Batalkan calon setelah terpilih',
  'value.unknown': 'Tidak diketahui',
  'value.su': 'Mi',
  'value.mo': 'Sen',
  'value.tu': 'Sel',
  'value.we': 'Rab',
  'value.th': 'Kam',
  'value.fr': 'Jum',
  'value.sa': 'Sab',
  'value.unverifiedIdentity': 'Belum diverifikasi',
  'value.validIdentity': 'Terverifikasi',
  'value.invalidIdentity': 'Tidak sah',
  'value.unavailableIdentity': 'Tidak tersedia',
  'value.noHiring': 'Selesai tanpa perekrutan',
  'value.noApplicants': 'Selesai tanpa pelamar',
};
