import { ChakraProvider } from '@chakra-ui/react';
import { FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes';
import store from 'store';
import { overrides } from 'themes';

import { authActions } from 'store/modules/auth';

import { httpClient } from 'apis/index';

import { authStorage, rememberMeStorage, userStorage } from 'configs/browser-storage';

import { AuthContainer } from 'containers/AuthContainer';

import './App.scss';

httpClient.initRequest();
httpClient.injectCallbacks({
  onCatchUnauthorizedError: () => {
    authStorage.clear();
    userStorage.clear();
    rememberMeStorage.clear();
    store.dispatch(authActions.clear());
    store.dispatch(authActions.expireSession());
  },
  onCatchForbidden: () => {
    store.dispatch(authActions.getCurrentUserPermission());
  },
});

export const App: FC = () => {
  return (
    <ChakraProvider resetCSS={true} theme={overrides}>
      <Provider store={store}>
        <Router>
          <AuthContainer>
            <Routes />
          </AuthContainer>
        </Router>
      </Provider>
    </ChakraProvider>
  );
};
