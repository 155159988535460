import { HStack, StackProps, Tooltip, TooltipProps } from '@chakra-ui/react';
import last from 'lodash/last';
import noop from 'lodash/noop';
import React, { Fragment, useState } from 'react';

import { Star, StarProps, Typography } from 'components/atoms';

import { getFloatPartPercent } from 'utils/numeric';

export type RateProps = {
  count?: number;
  value?: number;
  starBgColor?: StarProps['bgColor'];
  starBorderColor?: StarProps['borderColor'];
  viewOnly?: boolean;
  showValue?: boolean;
  size?: StarProps['size'];
  allowClear?: boolean;
  showTooltip?: boolean;
  tooltipProps?: Omit<TooltipProps, 'children'>;
  onChange?: (value: number) => void;
} & Pick<StackProps, 'spacing' | 'justify' | 'display' | 'width'>;

export const Rate: React.FC<RateProps> = ({
  count,
  value: parentValue,
  starBgColor,
  starBorderColor,
  viewOnly,
  showValue = false,
  size,
  allowClear = false,
  showTooltip,
  tooltipProps,
  onChange = noop,
  ...props
}) => {
  const items = Array.from({ length: count || 5 }, (v, index) => index + 1);
  const [value, setValue] = useState(parentValue ?? 0);
  const displayValue = parentValue ?? value;
  const filledItems = items.filter((item) => item - displayValue < 1);

  const handleChange = (nextValue: number) => () => {
    if (viewOnly) return;
    if (parentValue !== undefined) {
      onChange(nextValue === parentValue && nextValue === 1 && allowClear ? 0 : nextValue);
    } else {
      const next = nextValue === value && nextValue === 1 && allowClear ? 0 : nextValue;
      setValue(next);
      onChange(next);
    }
  };

  const TooltipComp = showTooltip ?? viewOnly ? Tooltip : Fragment;

  const innerTooltipProps: TooltipProps | {} =
    showTooltip ?? viewOnly
      ? { label: displayValue || 'No rating', placement: 'top', hasArrow: true, ...tooltipProps }
      : {};

  return (
    <TooltipComp {...innerTooltipProps}>
      <HStack {...props}>
        {items.map((item) => {
          const isFill = item - displayValue < 1;
          const isEndStar = last(filledItems) === item;
          return (
            <Star
              size={size}
              cursor={viewOnly ? undefined : 'pointer'}
              onClick={handleChange(item)}
              key={item}
              filled={isFill}
              bgColor={starBgColor}
              borderColor={starBorderColor}
              fillPercent={
                isEndStar && !Number.isInteger(displayValue)
                  ? getFloatPartPercent(displayValue)
                  : undefined
              }
            />
          );
        })}
        {showValue && <Typography.Text>{displayValue}</Typography.Text>}
      </HStack>
    </TooltipComp>
  );
};