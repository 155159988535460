import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';

import 'configs/dayjs';
import 'configs/i18n';

import { App } from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

// Sentry.init({
//   dsn: 'https://f81b174f1aef50f7135f109acbe4a60f@o4505837693370368.ingest.sentry.io/4505838186004480',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
