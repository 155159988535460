import { FormControlProps, FormErrorMessageProps } from '@chakra-ui/react';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';
import { colors } from 'themes/foundations/colors';

import { addHexOpacity } from 'utils/opacity';

const control: FormControlProps = {
  pb: { base: '15px', lg: 'initial' },
  pos: 'relative',
};
const errorMessage: FormErrorMessageProps = {
  position: 'absolute',
  mt: { base: '4px', md: '5px', '2xl': '6px' },
  ml: '2px',
  lineHeight: 1,
};

const button = {
  width: { base: '100%', md: '148px', lg: '130px', '2xl': '148px' },
  height: { base: '40px', md: '48px' },
};

// the value of these sizes has default as px
export const LAYOUT_CONFIG = {
  columns: 24,
  gap: '1rem',
  dashboard: {
    widgetPadding: {
      base: '22px',
      lg: '15px',
      xl: '25px',
      '2xl': '30px',
    },
    boxShadow: `0 3px 6px 0 ${addHexOpacity(colors.tango[500], 20)}`,
    round: { base: 'xl', md: '2xl' },
  },
  chart: {
    yStyles: {
      fontSize: 'inherit',
      fontFamily: 'Roboto Italic',
    },
    xStyles: {
      fontSize: 'inherit',
      fontFamily: 'Roboto',
    },
  },
  sidebar: {
    colSpan: createResponsiveStyle({ base: 1, lg: 5 }),
    paddingLeft: createResponsiveStyle({
      base: '8px',
      md: '70px',
      lg: '8px',
      xl: '20px',
      '2xl': '60px',
    }),
  },
  main: {
    colSpan: createResponsiveStyle({ base: 1, lg: 19 }),
  },
  form: {
    rowSpacing: '30px',
    topFooterSpacing: createResponsiveStyle({ base: '20px', lg: '50px' }),
    cardPadding: createResponsiveStyle({ base: '20px', lg: '30px 40px', '2xl': '60px 80px' }),
    footerSpacing: createResponsiveStyle({ base: '0', md: '50px' }),
    buttonWidth: button.width,
    rowGap: { base: '5px', lg: '22px', '2xl': '24px' },
    control,
    errorMessage,
  },
  space: {
    base: '10px',
    sm: '30px',
    md: '50px',
  },
  button,
};