import { Box, BoxProps, VStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { DeviceMode } from 'types';
import { addHexOpacity } from 'utils';

import { createResponsiveStyle } from 'themes/foundations/breakpoints';
import { colors } from 'themes/foundations/colors';

import { Typography } from 'components/atoms';

import { useCoordinates, useDeviceMode, useTranslate } from 'hooks/common';

import { MenuConfig } from '.';

type Props = {
  subMenu: Omit<MenuConfig, 'icon' | 'subMenu'>[];
  parentPath: string;
  show: boolean;
  height: string;
  wrapperWidth: number;
  itemHeight: number;
  activePath: string;
  onNavigate: (path: string, parentPath?: string | undefined) => () => void;
};

export const SubMenu: FC<Props> = ({
  subMenu,
  parentPath,
  show,
  height,
  wrapperWidth,
  itemHeight,
  activePath,
  onNavigate,
}) => {
  const t = useTranslate();
  const [ref, rect] = useCoordinates<HTMLDivElement>();

  const bodyHeight = document.body.scrollHeight;

  const isOutSideScroller = rect && rect.bottom >= bodyHeight;
  const activeTranslateY = isOutSideScroller ? bodyHeight - rect.bottom : 0;

  const device = useDeviceMode();
  const subMenuTransition: BoxProps['transition'] = createResponsiveStyle({
    base: 'all 200ms cubic-bezier(0.645, 0.045, 0.355, 1)',
    md: 'opacity 200ms ease-in-out, transform 300ms ease-in-out, height 150ms ease-in-out',
    lg: 'opacity 300ms ease-in-out, transform 400ms ease-in-out, height 300ms ease-in-out',
  });
  const hover: BoxProps['_hover'] =
    device === DeviceMode.Desktop ? { opacity: 1, fontWeight: 'bold' } : undefined;

  const activeStyles: BoxProps | undefined = show
    ? {
      height,
      transform: createResponsiveStyle({
        base: `translateX(${wrapperWidth + 12}px) translateY(${activeTranslateY}px)`,
        '2xl': `translateX(${wrapperWidth + 32}px) translateY(${activeTranslateY}px)`,
      }),
      opacity: 1,
    }
    : undefined;
  const mobileActiveStyles: BoxProps | undefined = show
    ? {
      transform: `translateX(20px) translateY(15px)`,
      mb: '15px',
    }
    : undefined;

  const checkItemIsActive = (path: string) => {
    if (activePath.split('/').length >= 4) {
      return activePath.startsWith(path);
    }
    return activePath === path;
  };

  switch (device) {
    case DeviceMode.Mobile:
      return (
        <Box
          // transition={subMenuTransition}
          width="280px"
          height={show ? height : '0'}
          visibility={show ? 'visible' : 'hidden'}
          opacity={show ? 1 : 0}
          overflow="hidden"
          borderRadius="7px"
          mb="0px"
          transform={`translateX(35px) translateY(-${itemHeight}px)`}
          transition={'all .3s'}
          {...mobileActiveStyles}
        >
          <VStack
            as="ul"
            borderRadius="7px"
            p="10px"
            alignItems="stretch"
            sx={{
              '& > *:not(:last-child)': {
                borderBottom: '1px solid',
                borderColor: addHexOpacity(colors.secondary, 50),
              },
            }}
          >
            {subMenu.map((subItem, index) => {
              const isActiveSubItem = checkItemIsActive(subItem.path);
              const activeSubItemStyles: BoxProps | undefined = isActiveSubItem
                ? {
                  fontWeight: 'bold',
                  opacity: 1,
                  _hover: undefined,
                }
                : undefined;

              return (
                <Box key={index} px="15px">
                  <Link to={subItem.path}>
                    <Typography.Text
                      fontSize={createResponsiveStyle({ base: 'lg', lg: 'sm-lg', '2xl': 'lg' })}
                      _hover={{ opacity: 1, fontWeight: 'bold' }}
                      cursor="pointer"
                      transition="all 200ms  ease"
                      whiteSpace={{ base: 'nowrap', lg: 'inherit' }}
                      onClick={onNavigate(subItem.path, parentPath)}
                      opacity={createResponsiveStyle({ base: 1, lg: 0.5 })}
                      {...activeSubItemStyles}
                    >
                      {t(subItem.title)}
                    </Typography.Text>
                  </Link>
                </Box>
              );
            })}
          </VStack>
        </Box>
      );

    default:
      return (
        <Box
          transition={subMenuTransition}
          position="absolute"
          // zIndex="hide"
          top="-16px"
          transform="translateX(0) translateY(24px)"
          height="0"
          overflow="hidden"
          borderRadius="7px"
          opacity="0"
          {...activeStyles}
        >
          <VStack
            ref={ref}
            height="fit-content"
            borderRadius="7px"
            p="10px"
            bg="white"
            alignItems="stretch"
            sx={{
              '& > *:not(:last-child)': {
                borderBottom: '1px solid',
                borderColor: addHexOpacity(colors.secondary, 50),
              },
            }}
          >
            {subMenu.map((subItem, index) => {
              const isActiveSubItem = checkItemIsActive(subItem.path);
              const activeSubItemStyles: BoxProps | undefined = isActiveSubItem
                ? {
                  fontWeight: 'bold',
                  opacity: 1,
                  _hover: undefined,
                }
                : undefined;

              return (
                <Box key={index} px="15px">
                  <Link to={subItem.path}>
                    <Typography.Text
                      fontSize={createResponsiveStyle({ base: 'lg', lg: 'sm-lg', '2xl': 'lg' })}
                      _hover={hover}
                      cursor="pointer"
                      transition="all 200ms  ease"
                      onClick={onNavigate(subItem.path, parentPath)}
                      opacity={createResponsiveStyle({ base: 1, lg: 0.5 })}
                      whiteSpace="nowrap"
                      {...activeSubItemStyles}
                    >
                      {t(subItem.title)}
                    </Typography.Text>
                  </Link>
                </Box>
              );
            })}
          </VStack>
        </Box>
      );
  }
};
