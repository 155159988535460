import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { DataResponse, MessageAxiosResponse, ObjectAxiosResponse } from 'types';

import { IUser } from 'models/user';

import { userStorage } from 'configs/browser-storage';

import { mapErrorDescriptions } from './utils';

const baseUrl = 'auth';

export const authApi = {
  async login(
    email: string,
    password: string,
    remember_me?: 1 | 0,
  ): Promise<
    DataResponse<{ accessToken: string; tokenType: string; expiresIn: number; user: IUser }>
  > {
    try {
      const res: AxiosResponse<{
        access_token: string;
        token_type: string;
        expires_in: number;
        user: IUser;
      }> = await httpClient.post(`${baseUrl}/login`, { email, password, remember_me });
      const { data } = res;

      return {
        data: {
          user: data.user,
          accessToken: data.access_token,
          tokenType: data.token_type,
          expiresIn: data.expires_in,
        },
        status: true,
        message: '',
      };
    } catch (error: any) {
      const message = error.message ?? i18next.t<string>('message.somethingWentWrong');
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async refreshToken(): Promise<
    DataResponse<{ accessToken: string; tokenType: string; expiresIn: number; user: IUser }>
  > {
    try {
      const res: AxiosResponse<{
        access_token: string;
        token_type: string;
        expires_in: number;
        user: IUser;
      }> = await httpClient.post(`${baseUrl}/refresh`);
      const { data } = res;
      return {
        data: {
          user: data.user,
          accessToken: data.access_token,
          tokenType: data.token_type,
          expiresIn: data.expires_in,
        },
        status: true,
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error?.error?.message || i18next.t<string>('message.emailOrPasswordIncorrect'),
      });
    }
  },
  async logout() {
    try {
      await httpClient.post(`${baseUrl}/logout`);
      httpClient.deleteAuthorization();
    } catch (error: any) {}
  },
  async forgotPassword(email: string): Promise<DataResponse<null>> {
    try {
      const res: MessageAxiosResponse = await httpClient.post(`${baseUrl}/forgot-password`, {
        email,
      });
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.somethingWentWrong'), description } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions({ email: 'email' }, description),
      });
    }
  },
  async resetPassword(
    password: string,
    confirmPassword: string,
    key: string,
  ): Promise<DataResponse<null>> {
    try {
      const res: MessageAxiosResponse = await httpClient.post(`${baseUrl}/reset-password`, {
        password,
        confirm_password: confirmPassword,
        key,
      });
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.somethingWentWrong') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async checkResetToken(key: string): Promise<DataResponse<{ validToken: boolean }>> {
    try {
      const res: MessageAxiosResponse = await httpClient.post(
        `${baseUrl}/check-reset-password-token`,
        {
          key: key,
        },
      );
      const { data } = res;
      return {
        status: true,
        message: data.message,
        data: {
          validToken: true,
        },
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.somethingWentWrong') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
  async getCurrentPermission(): Promise<DataResponse<IUser>> {
    try {
      const res: ObjectAxiosResponse<IUser> = await httpClient.get(`portal/user/current-user-info`);
      const { data } = res;
      // authStorage.update({ user: data.data });
      userStorage.update(data.data);
      return {
        status: true,
        data: data.data,
        message: '',
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.somethingWentWrong') } = error;
      return Promise.reject({
        status: false,
        message,
      });
    }
  },
};

export type AuthApi = typeof authApi;
