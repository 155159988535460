import {
  Box,
  BoxProps,
  Center,
  TableProps as ChakraTableProps,
  Flex,
  GridProps,
  HStack,
  Icon,
  StackProps,
  Tooltip,
} from '@chakra-ui/react';
import { DEFAULT_PAGE_SIZE } from 'constant';
import get from 'lodash/get';
import noop from 'lodash/noop';
import orderBy from 'lodash/orderBy';
import React, { Fragment, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { DeviceMode, FieldPath, Option, Order } from 'types';
import useResizeObserver from 'use-resize-observer';

import { SelectBox, Sort, TextProps, Typography } from 'components/atoms';
import { Dropdown, DropdownProps } from 'components/atoms/Dropdown';
import { Loading, Paginate, Pagination, PaginationProps } from 'components/molecules';

import { useDeviceMode, useMergedState, useTranslate } from 'hooks/common';

import { ArrowDownIcon } from 'assets/icons';

import { CardView } from './CardView';
import { TableView } from './TableView';

export type Sorter = {
  key: string;
  order: Order;
  field?: string;
};

export type Filter<T extends object = any> = Partial<T>;

export type ColumnsType<RecordType extends object = any> = {
  title?: string;
  titleInMobile?: string;
  renderHeader?(): ReactNode;
  key: string;
  dataIndex?: FieldPath<RecordType>;
  defaultValue?: ReactNode;
  titleColStyleMobileProps?: BoxProps;
  gridItemProps?: BoxProps;
  cellStyleProps?: BoxProps | ((record: RecordType, index: number) => BoxProps);
  innerCellStyleProps?: TextProps;
  headerCellStyleProps?: BoxProps;
  innerHeaderCellStyleProps?: BoxProps;
  allowSort?: boolean;
  sortPath?: string;
  filter?: Omit<DropdownProps, 'defaultLabel' | 'onChange'>;
  fixed?: {
    direction: 'left' | 'right';
    columnWidth: number;
  };
  mobileTitleProps?: TextProps;
  shouldShowTitleInMobile?: boolean;
  orderInMobile?: number;
  pined?: boolean; // default show row in card view
  hidden?: boolean;
  hideColumnInMobile?: boolean | ((record: RecordType) => boolean);
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  render?(
    value: any,
    record: RecordType,
    index: number,
    tool: { isActive?: boolean; isExpanded?: boolean; toggleExpand?: () => void },
  ): ReactNode;
  renderMobile?(
    value: any,
    record: RecordType,
    index: number,
    tool: { isActive?: boolean; isExpanded?: boolean; toggleExpand?: () => void },
  ): ReactNode;
};
export type Action = 'pagination' | 'sort' | 'filter';

export interface TableProps<RecordType extends object = any> {
  data: RecordType[] | null;
  columns: ColumnsType<RecordType>[];
  loading?: boolean;
  hotSort?: boolean;
  pagination?: Partial<PaginationProps>;
  sorter?: Sorter | null;
  filter?: Filter | null;
  paginationWrapperStyleProps?: StackProps;
  hidePagination?: boolean;
  collapsible?: boolean;
  wrapperStyleProps?: BoxProps;
  styleProps?: ChakraTableProps;
  cardStyles?: any;
  styleDropDownProps?: BoxProps;
  activeRowKey?: string | number | null;
  activeCellStyleProps?: BoxProps;
  noDataPlaceholder?: ReactNode;
  rowStyleProps?:
    | ((record: RecordType, index: number) => Pick<BoxProps, 'className' | 'bg'> | undefined)
    | Pick<BoxProps, 'className' | 'bg'>;
  maxHeight?: BoxProps['maxHeight'];
  minHeight?: BoxProps['minHeight'];
  error?: any;
  initialSorter?: Sorter | null;
  expandable?: {
    expandedRowRender: (record: RecordType, recordIndex: number) => ReactNode;
    rowExpandable: ((record: RecordType, recordIndex: number) => boolean) | boolean;
    showExpandedRender?: boolean;
  };
  allowDrag?: boolean;
  allowDragInSmallDesk?: boolean;
  simplePagination?: boolean;
  hidePaginationOnSinglePage?: boolean;
  gridMobileProps?: GridProps;
  defaultMobileRowExpandKeys?: Array<string | number>;
  dynamicMaxHeight?: number;
  onChange?: (
    pagination: Paginate | null,
    sorter: Sorter | null,
    filter: Filter | null,
    action: Action,
  ) => void;
  rowKey?: (record: RecordType, index: number) => string | number;
  shouldRenderModal?: boolean;
  renderModal?: (containerRef: React.RefObject<HTMLElement | null>) => ReactNode;
  renderSelectAll?: () => ReactNode;
}

export const Table = <RecordType extends object = any>(props: TableProps<RecordType>) => {
  const {
    columns,
    data,
    hotSort,
    onChange,
    pagination,
    collapsible,
    wrapperStyleProps,
    styleDropDownProps,
    styleProps,
    cardStyles,
    rowKey = (record, index) => index,
    activeRowKey,
    activeCellStyleProps,
    paginationWrapperStyleProps,
    hidePagination = false,
    noDataPlaceholder,
    rowStyleProps,
    maxHeight,
    minHeight,
    error,
    initialSorter,
    expandable,
    allowDrag,
    renderSelectAll,
    renderModal = noop,
    simplePagination = false,
    hidePaginationOnSinglePage,
    allowDragInSmallDesk,
    gridMobileProps,
    defaultMobileRowExpandKeys,
    dynamicMaxHeight,
    shouldRenderModal = true,
  } = props;

  const device = useDeviceMode();
  const t = useTranslate();
  const [sorter, setSorter] = useState<Sorter | null>(initialSorter ?? null);
  const [sortCol, setSortCol] = useState<Option | null>(null);
  const [filter, filterControl] = useMergedState<Filter | null>(null);
  const [openAll, setOpenAll] = useState<boolean>(false);
  const [paginate, setPaginate] = useState<Paginate>({
    pageSize: pagination?.pageSize || DEFAULT_PAGE_SIZE,
    current: pagination?.current || 1,
  });

  const containerRef = useRef<HTMLDivElement | null>(null);

  const displayColumns = columns.filter((item) => !item.hidden);

  const { ref, width } = useResizeObserver();

  const displayMaxHeight =
    maxHeight ??
    `max(${dynamicMaxHeight ? `calc(${dynamicMaxHeight}px - 52px - 10px)` : '80vh'}, 500px)`;

  const TooltipWrapper = device === DeviceMode.Desktop ? Tooltip : Fragment;

  const displayData = useMemo(() => {
    if (!data?.length) return data;
    let result = data;
    if (sorter && hotSort) {
      result = orderBy(result, sorter.field, sorter.order) as RecordType[];
    }

    const { pageSize, current } = paginate;
    if (pagination && result.length > pageSize) {
      const start = (current - 1) * pageSize;
      result = result.slice(start, start + pageSize);
    }
    return result;
  }, [data, hotSort, paginate, pagination, sorter]);

  const displayTotal = pagination?.total || data?.length;

  const summary = useMemo(() => {
    if (!pagination) return null;
    const { current = 1, pageSize = 0 } = pagination;
    const from = 1 + (current - 1) * pageSize;
    const to = Math.min(from + pageSize - 1, displayTotal ?? 0);
    return { from, to, total: displayTotal };
  }, [displayTotal, pagination]);

  const activeCellStyles: BoxProps = {
    color: 'white',
    bg: 'tango.300',
    ...activeCellStyleProps,
  };

  const optionOrderSort = [
    { label: t('label.all'), value: '' },
    { label: t('label.asc'), value: 'asc' },
    { label: t('label.desc'), value: 'desc' },
  ];
  const [sortOrder, setSortOrder] = useState<Option>({ label: t('label.all'), value: '' });
  const optionColumns = useMemo(() => {
    return columns
      .filter((column) => column.allowSort && column.key !== 'action')
      .map((item) => {
        return { label: item.title ?? '', value: item.sortPath ?? item.dataIndex ?? '' };
      });
  }, [columns]);

  const displayPagination = !!displayTotal && !hidePagination;

  const checkActiveSort = (key: string) => key === sorter?.key;

  const getSortHint = (column: ColumnsType<RecordType>) => {
    const order = column.key === sorter?.key ? sorter.order : Order.Ascend;
    if (!sorter) return t('label.clickToSortAsc');
    if (order === Order.Ascend) return t('label.clickToSortDesc');
    return t('label.clickCancelSorting');
  };

  const calcNextSorter = (prevSorter: Sorter | null, column: ColumnsType<RecordType>) => {
    let newSorter: Sorter = {
      key: column.key,
      order: Order.Ascend,
      field: column.sortPath || (column.dataIndex as any),
    };
    if (!prevSorter || prevSorter.key !== column.key) return newSorter;

    if (prevSorter.order === Order.Descend) {
      return null;
    }
    newSorter.order = Order.Descend;

    return newSorter;
  };

  const triggerOnChange = (updates: {
    sorter?: Sorter | null;
    paginate?: Paginate | null;
    filter?: Filter | null;
    action: Action;
  }) => {
    onChange &&
      onChange(
        updates.paginate || paginate,
        updates.sorter || null,
        updates.filter || filter,
        updates.action,
      );
  };

  const handleClickSort = (column: ColumnsType<RecordType>) => () => {
    setSorter((prevState) => {
      const newState = calcNextSorter(prevState, column);
      triggerOnChange({ sorter: newState || null, action: 'sort' });
      return newState;
    });
  };

  const handleChangePage = (paginate: Paginate) => {
    setPaginate(paginate);
    triggerOnChange({ paginate, action: 'pagination' });
  };

  const renderHeader = (column: ColumnsType<RecordType>) => {
    const { filter: filterProps, renderHeader } = column;
    const hasSort = !!column.allowSort;
    const activeSort = checkActiveSort(column.key);
    const isAscend = activeSort && sorter?.order === Order.Ascend;
    if (filterProps) {
      const { items, renderItem, ...rest } = filterProps;
      return (
        <>
          <Box padding="8px 15px">
            <Typography.Text>
              {items.find((item) => item.value === get(filter, column.key))?.label ?? column.title}
            </Typography.Text>
          </Box>

          <Dropdown
            labelProps={{
              px: '15px',
              py: '8px',
            }}
            styleDropDownProps={styleDropDownProps}
            position="absolute"
            inset="0"
            right="2px"
            px="1px"
            defaultLabel={column.title ?? ''}
            items={items}
            renderItem={renderItem}
            value={filter ? get(filter, column.key) : null}
            onChange={(value) => {
              filterControl.update({
                [column.key]: value,
              });
              triggerOnChange({
                filter: { ...filter, [column.key]: value },
                action: 'filter',
              });
            }}
            {...rest}
          />
        </>
      );
    }
    if (hasSort)
      return (
        <Box position="relative" padding="8px 15px" {...column.innerHeaderCellStyleProps}>
          <TooltipWrapper label={getSortHint(column)} placement="top">
            <Box cursor="pointer" onClick={handleClickSort(column)}>
              <Typography.Text>{column.title}</Typography.Text>
              <Center position="absolute" right="5px" top="0" bottom="0" userSelect="none">
                <Sort isAscending={activeSort && isAscend} isDescending={activeSort && !isAscend} />
              </Center>
            </Box>
          </TooltipWrapper>
        </Box>
      );
    if (renderHeader)
      return (
        <Box position="relative" padding="8px 15px" {...column.innerHeaderCellStyleProps}>
          {renderHeader()}
        </Box>
      );
    return (
      <Box position="relative" padding="8px 15px" {...column.innerHeaderCellStyleProps}>
        <Typography.Text>{column.title}</Typography.Text>
      </Box>
    );
  };

  const handleSelectSortCol = (data: Option) => {
    if (!data) {
      triggerOnChange({ sorter: null, action: 'sort' });
      setSortOrder({ label: t('label.all'), value: '' });
    }
    setSortCol(data);
  };

  const handleSelectSortOrder = (data: Option) => {
    if (!sortCol) return;
    setSortOrder(data);
    const newSort = { key: sortCol.value, order: data.value, field: sortCol.value };
    triggerOnChange({ sorter: newSort || null, action: 'sort' });
  };

  useEffect(() => {
    if (props.sorter !== undefined) {
      setSorter(props.sorter);
    }
  }, [props.sorter]);

  useEffect(() => {
    if (props.filter !== undefined) {
      filterControl.set(props.filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filter]);

  return (
    <Box className="table" h="100%" position="relative" ref={containerRef} {...wrapperStyleProps}>
      <Loading loading={props.loading ?? false} minHeight={minHeight ?? '300px'}>
        {displayPagination && device === DeviceMode.Mobile && (
          <Box mb="5px">
            <Box ref={ref} />
            <HStack
              // px={{ base: '12px', md: 'initial' }}
              // pb="20px"
              mb="20px"
            >
              <Box
                // width="190px"
                zIndex="100"
                flex={1}
              >
                <SelectBox
                  id="column"
                  options={optionColumns ?? undefined}
                  placeholder={t('placeholder.pleaseSelectAColumn')}
                  borderRadius="10px"
                  // allowClear={false}
                  onChange={handleSelectSortCol}
                />
              </Box>

              <Box zIndex="100" width="95px">
                <SelectBox
                  id="column"
                  options={optionOrderSort}
                  borderRadius="10px"
                  allowClear={false}
                  initialValue={optionOrderSort[0]}
                  onChange={handleSelectSortOrder}
                  hasIndicator={false}
                  value={sortOrder}
                />
              </Box>
              <Box
                width="40px"
                height="40px"
                borderRadius="5px"
                backgroundColor="#FD9543"
                cursor="pointer"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => setOpenAll(!openAll)}
              >
                <Icon
                  as={ArrowDownIcon}
                  width="25px"
                  height="25px"
                  transform={openAll ? 'rotate(180deg)' : 'rotate(0)'}
                  transition="transform 0.2s ease-in"
                />
              </Box>
              {/*<Box*/}
              {/*  // flexBasis='40px'*/}
              {/*  display="inline-flex"*/}
              {/*  alignItems="center"*/}
              {/*  justifyContent="center"*/}
              {/*  width="40px"*/}
              {/*  height="40px"*/}
              {/*  borderRadius="5px"*/}
              {/*  backgroundColor="#FD9543"*/}
              {/*  cursor="pointer"*/}
              {/*  flexShrink={0}*/}
              {/*  onClick={() => setOpenAll(!openAll)}*/}
              {/*>*/}
            </HStack>
            <Flex {...paginationWrapperStyleProps} justifyContent="flex-end">
              <Box maxWidth={width} overflow="auto">
                <Pagination
                  size="xs"
                  {...props.pagination}
                  total={displayTotal}
                  onChange={handleChangePage}
                  triggerByBody={true}
                  showLessItems
                  hideOnSinglePage={hidePaginationOnSinglePage}
                  pageSizeOptions={['10', '25', '50', '100']}
                />
              </Box>
            </Flex>
            {summary && (
              <Flex justifyContent="flex-end">
                <Typography.Text>
                  {t('message.tableSummary', {
                    from: summary.from,
                    to: summary.to,
                    total: summary.total,
                  })}
                </Typography.Text>
              </Flex>
            )}
            {renderSelectAll && <Box my="10px">{renderSelectAll()}</Box>}
          </Box>
        )}
        {device === DeviceMode.Mobile ? (
          <CardView
            data={displayData}
            columns={displayColumns}
            activeRowKey={activeRowKey}
            rowStyleProps={rowStyleProps}
            activeCellStyles={activeCellStyles}
            noDataPlaceholder={noDataPlaceholder}
            error={error}
            rowKey={rowKey}
            collapsible={collapsible}
            cardStyles={cardStyles}
            expandable={expandable}
            gridMobileProps={gridMobileProps}
            defaultMobileRowExpandKeys={defaultMobileRowExpandKeys}
            allExpandable={openAll}
          />
        ) : (
          <TableView
            data={displayData}
            columns={displayColumns}
            maxHeight={displayMaxHeight}
            activeRowKey={activeRowKey}
            rowStyleProps={rowStyleProps}
            activeCellStyles={activeCellStyles}
            noDataPlaceholder={noDataPlaceholder}
            error={error}
            rowKey={rowKey}
            renderHeader={renderHeader}
            expandable={expandable}
            allowDrag={allowDrag}
            allowDragInSmallDesk={allowDragInSmallDesk}
            {...styleProps}
          />
        )}

        {displayPagination && device !== DeviceMode.Mobile && (
          <HStack
            {...paginationWrapperStyleProps}
            justifyContent={simplePagination ? 'center' : 'flex-end'}
          >
            {!simplePagination && summary && (
              <Typography.Text>
                {t('message.tableSummary', {
                  from: summary.from,
                  to: summary.to,
                  total: summary.total,
                })}
              </Typography.Text>
            )}
            <Pagination
              size={styleProps?.size}
              {...props.pagination}
              total={displayTotal}
              onChange={handleChangePage}
              pageSizeOptions={['10', '25', '50', '100']}
              simple={simplePagination}
              hideOnSinglePage={simplePagination || hidePaginationOnSinglePage}
            />
          </HStack>
        )}
      </Loading>
      {shouldRenderModal && renderModal(containerRef)}
    </Box>
  );
};