import { httpClient } from 'apis';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { authActions } from 'store/modules/auth';

import { useCurrentUser } from 'hooks/components/auth';

import { authStorage, rememberMeStorage, userStorage } from 'configs/browser-storage';
import i18n from 'configs/i18n';

import { Language } from 'constant/language';

export const AuthContainer: FC = (props) => {
  const dispatch = useDispatch();
  const user = useCurrentUser();

  const changeAccessTimes = (count: number) => {
    let accessTimes = (rememberMeStorage.value?.accessTimes ?? 0) + count;
    if (accessTimes < 0) accessTimes = 0;

    rememberMeStorage.update({ accessTimes });
  };

  const initAuth = async () => {
    const cachedAuth = authStorage.value;
    const cachedUser = userStorage.value;

    try {
      if (!cachedAuth || !cachedUser) throw new Error();

      const { remember, accessTimes = 0, closedAt } = rememberMeStorage.value ?? {};
      const isReload = !!closedAt && Date.now() - closedAt < 10 * 1000;

      if (!remember && accessTimes === 0 && !isReload) {
        throw new Error('End session');
      }

      changeAccessTimes(1);
      httpClient.setAuthorization(cachedAuth.accessToken, 'Bearer');
      // dispatch(authActions.authen(cachedAuth));
      dispatch(authActions.authen({ accessToken: cachedAuth.accessToken, user: cachedUser }));
      await dispatch(authActions.getCurrentUserPermission());
    } catch (error) {
      authStorage.clear();
      userStorage.clear();
      rememberMeStorage.clear();
      dispatch(authActions.authWithFailed());
    }
  };

  const handleAppUnload = () => {
    if (!rememberMeStorage.value?.remember) {
      rememberMeStorage.update({ closedAt: Date.now() });
      changeAccessTimes(-1);
    }
  };

  useEffect(() => {
    initAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      if (user && i18n.language !== user.language_code) {
        i18n.changeLanguage(user.language_code ?? Language.English);
      }
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleAppUnload);
    return () => window.removeEventListener('beforeunload', handleAppUnload);
  });

  return <>{props.children}</>;
};
