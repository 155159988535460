import { IconButton, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';
import React, { ForwardedRef, forwardRef, useState } from 'react';

import { CloseViewIcon, ViewIcon } from 'assets/icons';

export const PasswordInput = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [show, setShow] = useState(false);

    return (
      <InputGroup>
        <Input ref={ref} {...props} type={show ? 'text' : 'password'} />
        <InputRightElement>
          <IconButton
            aria-label="toggle password visibility"
            icon={show ? <CloseViewIcon /> : <ViewIcon />}
            colorScheme="tango"
            variant="link"
            onClick={() => {
              setShow(!show);
            }}
          />
        </InputRightElement>
      </InputGroup>
    );
  },
);
