import { JobStatus } from 'models/job';

import { useTranslate } from '../../common';

export const useJobStatusLabels = () => {
  const t = useTranslate();
  const JOB_STATUS_LABEL: Record<JobStatus, string> = {
    [JobStatus.Pending]: t('value.pending'),
    [JobStatus.Open]: t('value.open'),
    [JobStatus.Active]: t('value.active'),
    [JobStatus.Completed]: t('value.completed'),
    [JobStatus.Cancel]: t('value.cancelled'),
  };

  return JOB_STATUS_LABEL;
};
