import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import { DATE_TIME_BE } from 'constant';
import { DataResponse, ListAxiosResponse, Order, Payload, Query } from 'types';

import { ILocationCreditHistory } from 'models/location';
import { ILocationManager, ILocationProfile } from 'models/location-profile';

import { CreditHistoryByLocationFilterData } from 'components/molecules';

import { mapPaginationState, mapQueryToParams } from './utils';

// const entity = 'locationProfile';
// const basePath = `portal/${entity}` as const;

export const locationProfileApi = {
  async listLocationCreditHistory(
    payload: Payload<null, null, Query & Partial<CreditHistoryByLocationFilterData>>,
  ): Promise<DataResponse<ILocationCreditHistory[]>> {
    try {
      const { pagination, query } = payload;
      const {
        search,
        sort,
        recent,
        dateRange,
        minAvailableCredit,
        maxAvailableCredit,
        minAvailableConsumed,
        maxAvailableConsumed,
      } = query || {};
      const [startDate, endDate] = dateRange || [];

      const res: ListAxiosResponse<ILocationCreditHistory> = await httpClient.get(
        `portal/credit/location-user/credit-history-of-locations`,
        {
          params: mapQueryToParams(
            { sort, search },
            pagination,
            {
              recent: recent ? 1 : '',
              start_date: startDate?.second(0).format(DATE_TIME_BE),
              end_date: endDate?.second(59).format(DATE_TIME_BE),
              min_available_credit: minAvailableCredit,
              max_available_credit: maxAvailableCredit,
              min_consumed_credit: minAvailableConsumed,
              max_consumed_credit: maxAvailableConsumed,
            },
            {
              sortDefault: {
                key: 'date_credit',
                field: 'date_credit',
                order: Order.Descend,
              },
            },
          ),
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getProfileInfo(): Promise<DataResponse<ILocationManager>> {
    try {
      const res: AxiosResponse<{ data: ILocationProfile; user: any; message: string }> =
        await httpClient.get(`auth/admin-location-profile`);

      const { data } = res;
      return {
        status: true,
        message: data.message,
        data: data.user,
      };
    } catch (error: any) {
      return Promise.reject({ message: error.message, status: false });
    }
  },
};

export type LocationProfileApi = typeof locationProfileApi;
