import { ReactNode } from 'react';

import { PaginationState } from 'components/molecules';
import { Sorter } from 'components/organisms/Table';

export type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export type VoidFunc = () => void;

// TODO: Develop a type to remove nullish field
// ref: https://github.com/Microsoft/TypeScript/issues/26705
export type ShallowNoneNullableField<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

export enum Order {
  Ascend = 'asc',
  Descend = 'desc',
}

export type Option<V extends number | string = any> = {
  label: string;
  value: V;
};

export enum DeviceMode {
  Mobile = 1,
  Tablet,
  Desktop,
}

export enum ScreenMode {
  Mobile = 'base',
  Tablet = 'md',
  Desktop = 'lg',
  DesktopXl = 'xl',
  DesktopLxl = 'lxl',
  Desktop2Xl = '2xl',
}

export type Query<Group extends object = any, Column extends object = any> = {
  sort?: Sorter | null;
  filter?: string | null;
  search?: string | null;
  columnFilter?: Column | null;
  filterGroup?: Partial<Group> | null;
};

export type CachedFilter = {
  pagination: PaginationState;
  query: Query;
};

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export enum RecordStatus {
  Enable = 'enable',
  Disable = 'disable',
}

export enum Gender {
  Female = 0,
  Male = 1,
  Other = 2,
}

// export const GENDER_LABEL: Record<Gender, string> = {
//   [Gender.Female]: 'Female',
//   [Gender.Male]: 'Male',
//   [Gender.Other]: 'Other',
// };

export type ThunkActionStatus = 'pending' | 'fulfilled' | 'rejected';

export type ShallowNonNullish<T> = { [P in keyof T]-?: NonNullable<T[P]> };

export declare type Primitive = null | undefined | string | number | boolean | symbol | bigint;

type IsTuple<T extends ReadonlyArray<any>> = number extends T['length'] ? false : true;
type TupleKey<T extends ReadonlyArray<any>> = Exclude<keyof T, keyof any[]>;
type ArrayKey = number;
type PathImpl<K extends string | number, V> = V extends Primitive
  ? `${K}`
  : `${K}` | `${K}.${Path<V>}`;

export type Path<T> = T extends ReadonlyArray<infer V>
  ? IsTuple<T> extends true
    ? {
        [K in TupleKey<T>]-?: PathImpl<K & string, T[K]>;
      }[TupleKey<T>]
    : PathImpl<ArrayKey, V>
  : {
      [K in keyof T]-?: PathImpl<K & string, T[K]>;
    }[keyof T];
export type FieldPath<TFieldValues> = Path<TFieldValues>;

export type SummaryItem = {
  label: ReactNode;
  count: number;
};

export type BooleanStatus = 1 | 0;

export enum FilterType {
  Include = 'include',
  Exclude = 'exclude',
}

export type BooleanCollection = Record<number | string, boolean>;
