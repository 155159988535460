import {
  Box,
  BoxProps,
  Center,
  Collapse,
  Icon,
  Portal,
  VStack,
  useBoolean,
  usePopper,
} from '@chakra-ui/react';
import noop from 'lodash/noop';
import React, { Fragment, useEffect, useState } from 'react';
import { isFirefox } from 'react-device-detect';
import { Option } from 'types';
import useResizeObserver from 'use-resize-observer';
import { isNotSupportBackdropFilter } from 'utils';

import { colors } from 'themes/foundations/colors';

import { CloseIcon, DropdownIcon } from 'assets/icons';

import { addHexOpacity } from 'utils/opacity';

import { Typography } from '.';

export interface DropdownProps extends Omit<BoxProps, 'onChange'> {
  items: Option[];
  value?: Option['value'] | null;
  styleDropDownProps?: BoxProps;
  defaultLabel: string;
  itemProps?: BoxProps;
  animationDuration?: BoxProps['transitionDuration'];
  labelProps?: BoxProps;
  maxHeight?: BoxProps['maxHeight'];
  renderItem?: (value: Option) => React.ReactNode;
  onChange?: (value: Option['value'] | null) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  items,
  value,
  defaultLabel,
  bg = 'primary',
  styleDropDownProps,
  animationDuration = '300ms',
  itemProps,
  labelProps,
  maxHeight,
  renderItem,
  onChange = noop,
  ...props
}) => {
  const { ref: containerRef, width } = useResizeObserver();
  const [isOpen, { off, toggle }] = useBoolean(false);
  const [activeItem, setActiveItem] = useState<Option | null>();
  const { popperRef, referenceRef } = usePopper({
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  const handleClickItem = (item: Option) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveItem(item);
    off();
    onChange(item.value);
  };
  const handleClickToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggle();
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    setActiveItem(null);
    onChange(null);
  };

  useEffect(() => {
    if (value !== undefined) {
      const item = items.find((item) => item.value === value);
      setActiveItem(item ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    document.addEventListener('click', off);
    return () => {
      document.removeEventListener('click', off);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DropdownWrapper = isFirefox ? Portal : Fragment;

  return (
    <Box ref={containerRef} h="100%" position="relative" zIndex="dropdown" {...props}>
      <Center
        ref={referenceRef}
        h="100%"
        bg={bg}
        position="relative"
        zIndex="docked"
        cursor="pointer"
        onClick={handleClickToggle}
        opacity="0"
        {...labelProps}
      >
        {/* {activeItem ? activeItem.label : defaultLabel} */}
      </Center>

      <Center position="absolute" right="4px" top="0" bottom="0" zIndex="docked" userSelect="none">
        {activeItem ? (
          <Icon
            cursor="pointer"
            color="white"
            boxSize="11px"
            as={CloseIcon}
            onClick={handleClear}
          />
        ) : (
          <Icon
            boxSize="14px"
            cursor="pointer"
            onClick={handleClickToggle}
            color="white"
            as={DropdownIcon}
            transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
            transition="transform ease-in-out"
            transitionDuration={animationDuration}
          />
        )}
      </Center>

      <DropdownWrapper>
        <Box ref={popperRef} className="popper-content" zIndex={isFirefox ? 'dropdown' : undefined}>
          <Box position="relative" borderRadius="0 0px 8px 8px" {...styleDropDownProps}>
            <Box
              borderRadius=" 0 0px 8px 8px"
              className="blur-layer"
              position="absolute"
              inset="0"
              backdropFilter="blur(9px) brightness(100%)"
              backgroundColor={isNotSupportBackdropFilter ? 'white' : undefined}
              boxShadow={
                isNotSupportBackdropFilter
                  ? `0 3px 6px 0 ${addHexOpacity(colors.tango[500], 20)}`
                  : undefined
              }
            />
            <Collapse in={isOpen}>
              <VStack
                width={width}
                alignItems="stretch"
                px="20px"
                position="relative"
                pt="15px"
                sx={{
                  '& > *:not(:last-child)': {
                    borderBottom: '1px solid',
                    borderColor: addHexOpacity(colors.secondary, 50),
                  },
                }}
                spacing="0"
                overflow={maxHeight ? 'auto' : undefined}
                maxHeight={maxHeight}
                css={{
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    width: '6px',
                    background: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#e0e0e0',
                    borderRadius: '24px',
                  },
                  '&::-webkit-scrollbar-thumb:hover': {
                    background: '#bdbdbd',
                  },
                }}
              >
                {items.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      color="secondary"
                      py="15px"
                      cursor="pointer"
                      lineHeight="1"
                      onClick={handleClickItem(item)}
                      {...itemProps}
                    >
                      {renderItem ? (
                        renderItem(item)
                      ) : (
                        <Typography.Text >{item.label}</Typography.Text>
                      )}
                    </Box>
                  );
                })}
              </VStack>
            </Collapse>
          </Box>
        </Box>
      </DropdownWrapper>
    </Box>
  );
};
