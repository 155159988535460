import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'types';

import {
  citySelector,
  isFetchingCityOptions,
  locationActions,
} from 'store/modules/location';

export const useCityOptions = () => {
  const dispatch = useDispatch();
  const cities = useSelector(citySelector);
  const loading = useSelector(isFetchingCityOptions);

  useEffect(() => {
    if (!cities?.length) {
      dispatch(locationActions.getCityOptions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result: [Option[], boolean] = [cities ?? [], loading];
  return result;
};