import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import SwapRightOutlined from '@ant-design/icons/SwapRightOutlined';
import { ThemeProvider } from '@chakra-ui/react';
import classNames from 'classnames';
import { dateFullHourDisplay } from 'constant';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { RangePicker as RCRangePicker } from 'rc-picker';
import { GenerateConfig } from 'rc-picker/lib/generate';
import * as React from 'react';
import { overrides } from 'themes';

import { Components, RangePickerProps, getTimeProps } from '.';
import enUS from '../locale/en_US';
import { getRangePlaceholder } from '../util';

let inputs: NodeListOf<HTMLInputElement> | undefined;

export default function generateRangePicker<DateType>(
  generateConfig: GenerateConfig<DateType>,
): React.ComponentClass<RangePickerProps<DateType>> {
  class RangePicker extends React.Component<RangePickerProps<DateType>> {
    pickerRef = React.createRef<RCRangePicker<DateType>>();
    containerRef = React.createRef<HTMLDivElement>();

    state = {
      startDate: null,
      endDate: null,
    };

    focus = () => {
      if (this.pickerRef.current) {
        this.pickerRef.current.focus();
      }
    };

    blur = () => {
      if (this.pickerRef.current) {
        this.pickerRef.current.blur();
      }
    };

    handleChangeStartDate = (e: any) => {
      this.setState({
        startDate: e.target.value === '' ? null : dayjs(e.target.value, dateFullHourDisplay()),
      });
      this.props.onChange &&
        this.props.onChange([this.state.startDate as any, this.state.endDate as any], ['', '']);
    };

    handleChangeEndDate = (e: any) => {
      this.setState({
        endDate: e.target.value === '' ? null : dayjs(e.target.value, dateFullHourDisplay()),
      });
      this.props.onChange &&
        this.props.onChange([this.state.startDate as any, this.state.endDate as any], ['', '']);
    };

    componentDidMount() {
      inputs = this.containerRef.current?.querySelectorAll('.date-picker-input > input');
      if (inputs) {
        inputs[0].addEventListener('blur', this.handleChangeStartDate);
        inputs[1].addEventListener('blur', this.handleChangeEndDate);
      }
    }

    componentWillUnmount() {
      if (inputs) {
        inputs[0].removeEventListener('blur', this.handleChangeStartDate);
        inputs[1].removeEventListener('blur', this.handleChangeEndDate);
      }
    }

    render() {
      const {
        getPopupContainer: customGetPopupContainer,
        className,
        placeholder,
        size,
        isInvalid,
        ...restProps
      } = this.props;
      const prefixCls = 'date-picker';
      const { format, showTime, picker } = this.props as any;

      let additionalOverrideProps: any = {};

      additionalOverrideProps = {
        ...additionalOverrideProps,
        ...(showTime ? getTimeProps({ format, picker, ...showTime }) : {}),
        ...(picker === 'time' ? getTimeProps({ format, ...this.props, picker }) : {}),
      };
      return (
        <ThemeProvider theme={overrides}>
          <div ref={this.containerRef}>
            <RCRangePicker<DateType>
              placeholder={getRangePlaceholder(picker, enUS, placeholder)}
              prefixCls={prefixCls}
              separator={<SwapRightOutlined />}
              ref={this.pickerRef}
              suffixIcon={picker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />}
              clearIcon={<CloseCircleFilled />}
              allowClear
              {...restProps}
              {...additionalOverrideProps}
              locale={{
                ...enUS.lang,
                shortMonths: [
                  t('value.jan'),
                  t('value.feb'),
                  t('value.mar'),
                  t('value.apr'),
                  t('value.may'),
                  t('value.jun'),
                  t('value.jul'),
                  t('value.aug'),
                  t('value.sep'),
                  t('value.oct'),
                  t('value.nov'),
                  t('value.dec'),
                ],
                shortWeekDays: [
                  t('value.su'),
                  t('value.mo'),
                  t('value.tu'),
                  t('value.we'),
                  t('value.th'),
                  t('value.fr'),
                  t('value.sa'),
                ],
                ok: t('button.ok'),
              }}
              generateConfig={generateConfig}
              prevIcon={<span className={`${prefixCls}-prev-icon`} />}
              nextIcon={<span className={`${prefixCls}-next-icon`} />}
              superPrevIcon={<span className={`${prefixCls}-super-prev-icon`} />}
              superNextIcon={<span className={`${prefixCls}-super-next-icon`} />}
              components={Components}
              className={classNames(
                { 'size-sm': size === 'sm', 'size-md': size === 'md' },
                { invalid: isInvalid },
                className,
              )}
            />
          </div>
        </ThemeProvider>
      );
    }
  }

  return RangePicker;
}
