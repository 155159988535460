import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import i18next from 'i18next';
import { DataResponse, ListAxiosResponse, Order, Payload } from 'types';

import { IHqUser } from 'models/user';

import { HQUserFilterData } from 'components/molecules/HQUserFilter';
import { Sorter } from 'components/organisms/Table';

import { concatParams, mapErrorDescriptions, mapPaginationState, mapQueryToParams } from './utils';

export type EducationFormData = {
  name: string;
  description?: string;
  address: string;
};

const entity = 'hquser';
const basePath = `portal/${entity}` as const;

const userHqPayloadScheme = {
  company_id: 'company',
  first_name: 'firstName',
  last_name: 'lastName',
  job_title: 'jobTitle',
  contact_number: 'contactNumber',
  email: 'workEmail',
};

type ListQuery = {
  search?: string;
  sort?: { sort_field: string; sort_order: Order };
  filter?: string | number;
  locationId?: string | null;
  applyDate?: Dayjs | null;
  jobTitle?: string | null;
};

export const hqUserApi = {
  async addHqUser(
    payload: Payload<{
      company_id: string | number;
      first_name: string;
      last_name: string;
      job_title: string;
      contact_number: string;
      email: string;
    }>,
  ): Promise<DataResponse<null>> {
    try {
      const { body } = payload;
      const res: AxiosResponse<{ message: string; data: IHqUser }> = await httpClient.post(
        `${basePath}/store`,
        { ...body },
      );
      const { data } = res;
      return {
        message: data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.createdHQManagerUnsuccess') } = error;
      return Promise.reject({
        status: false,
        message,
        description: mapErrorDescriptions(userHqPayloadScheme, error.description),
      });
    }
  },
  async updateHqUser(
    payload: Payload<
      {
        first_name: string;
        last_name: string;
        job_title: string;
        contact_number: string;
        company_id: string;
        email: string;
      },
      { id: string | number }
    >,
  ): Promise<DataResponse<null>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{
        data: IHqUser;
        message: string;
      }> = await httpClient.put(`${basePath}/edit/${params.id}`, { ...payload.body });
      const { data } = res;
      return {
        status: true,
        message: data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.updatedHQManagerUnsuccess'), description } =
        error;
      return Promise.reject({
        status: false,
        message: message,
        description: mapErrorDescriptions(userHqPayloadScheme, description),
      });
    }
  },
  async disableHqUser(id: number): Promise<DataResponse<{ data: IHqUser; message: string }>> {
    try {
      const res: AxiosResponse<{
        data: IHqUser;
        message: string;
      }> = await httpClient.delete(`${basePath}/disable/${id}`);
      return {
        data: res.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.disableHQUserUnsuccess') } = error;

      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async enableHqUser(id: number): Promise<DataResponse<{ data: IHqUser; message: string }>> {
    try {
      const res: AxiosResponse<{
        data: IHqUser;
        message: string;
      }> = await httpClient.put(`${basePath}/enable/${id}`);
      return {
        data: res.data,
        message: res.data.message,
        status: true,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.enableHQUserUnsuccess') } = error;

      return Promise.reject({
        status: false,
        message: message,
      });
    }
  },
  async getHqUsers(
    payload: Payload<
      null,
      null,
      {
        sort?: Sorter | null;
        search?: string | null;
      } & Partial<HQUserFilterData>
    >,
  ): Promise<DataResponse<IHqUser[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, search, recent, hqUserStatus } = query || {};
      const filterTypes = concatParams(recent ? 'recent' : '', hqUserStatus ?? '');

      const res: ListAxiosResponse<IHqUser> = await httpClient.get(`${basePath}/index`, {
        params: mapQueryToParams(
          { sort, search },
          pagination,
          {
            filter_types: filterTypes,
          },
          {
            sortDefaultByCreatedAt: true,
          },
        ),
      });
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async summary(): Promise<
    DataResponse<{ totalActive: number; totalDisable: number; total: number }>
  > {
    try {
      const res: AxiosResponse<{
        data: {
          total: number;
          total_enable: number;
          total_disable: number;
        };
        message: string;
      }> = await httpClient.get(`${basePath}/summary`);

      const {
        data: {
          data: { total, total_enable: totalActive, total_disable: totalDisable },
        },
      } = res;
      return {
        status: true,
        data: {
          totalActive,
          totalDisable,
          total,
        },
        message: '',
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async GetHQUserDetail(id: string | number): Promise<DataResponse<IHqUser>> {
    try {
      const res: AxiosResponse<{ data: IHqUser; message: string }> = await httpClient.get(
        `${basePath}/show/${id}`,
      );

      return {
        status: true,
        data: res.data.data,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async changePassword(
    payload: Payload<
      {
        password: string;
        password_confirm: string;
      },
      { id: string | number }
    >,
  ): Promise<DataResponse<{ data: IHqUser; message: string }>> {
    try {
      const { params } = payload;
      const res: AxiosResponse<{ data: IHqUser; message: string }> = await httpClient.post(
        `${basePath}/change-password/${params.id}`,
        { ...payload.body },
      );
      return {
        status: true,
        data: res.data,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
};

export type HqUserApi = typeof hqUserApi & {
  ListQuery: ListQuery;
};
