import { Box, HStack, Spacer, Stack, VStack, useBreakpointValue } from '@chakra-ui/react';
import { PATH_NAME } from 'configs';
import { PermissionGuard } from 'guards';
import { withPermission } from 'hocs/withPermission';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Option } from 'types';
import useResizeObserver from 'use-resize-observer';

import { Role } from 'models/user';

import { PartnerPermission } from 'types/permission';

import { BarChar, Card, Legend, PrimaryButton, SelectBox, Typography } from 'components/atoms';

import { useRouter, useScreenMode, useTranslate } from 'hooks/common';
import { useCheckAllowedRole } from 'hooks/components/auth';
import { useHiringOverview } from 'hooks/components/dashboard/useHiringOverview';
import { useLocationOptions } from 'hooks/components/location';

import { LAYOUT_CONFIG } from 'configs/layout';

export const HiringOverviewWidget = () => {
  const router = useRouter();
  const screen = useScreenMode();
  const t = useTranslate();
  const chartFixedHeight = useBreakpointValue(
    {
      base: 235,
      md: 283,
      lg: 283,
      xl: 283,
      lxl: 283,
      '2xl': 283,
    },
    screen,
  );

  const [{ total_today_hired, total_hired_of_current_month, hiringData, timeData }, reFetch] =
    useHiringOverview();
  const translatedTimeData = timeData?.map((item) => {
    const translatedString = String(t(('value.' + item[0].toLowerCase()) as any));
    return [
      translatedString.length > 3
        ? translatedString.slice(0, 2) + translatedString.split(' ').at(-1)
        : translatedString,
      item[1],
    ];
  });
  const { ref, width: chartWidth, height: chartWrapperHeight } = useResizeObserver();
  const canFilterByLocation = useCheckAllowedRole([Role.AreaManager, Role.HqManager]);
  const [locationOptions, loadingOptions] = useLocationOptions(!canFilterByLocation);
  const chartHeight = Math.max(chartFixedHeight ?? 0, chartWrapperHeight ?? 0) || undefined;
  const [location, setLocation] = useState<Option | null>(null);

  const defaultOption: Option = {
    label: t('placeholder.allLocations'),
    value: t('value.all'),
  };
  const displayOptions: Option[] = [defaultOption, ...(locationOptions ?? [])];

  const handleGoListCandidate = () => {
    router.push(PATH_NAME.CANDIDATE);
  };

  const handleChangeLocation = (value: Option | null) => setLocation(value);

  useEffect(() => {
    if (location) reFetch(location?.value === 'all' ? undefined : location.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Card
      round={LAYOUT_CONFIG.dashboard.round}
      boxShadow={LAYOUT_CONFIG.dashboard.boxShadow}
      p={{ base: '20px', lg: '20px 15px 0px 15px', '2xl': '20px 15px 0px 35px' }}
      fitParent
    >
      <VStack align="stretch" h="100%">
        <Stack
          spacing={{ lg: '15px', '2xl': '45px' }}
          direction={{ base: 'column', lg: 'row' }}
          flexWrap={{ lg: 'wrap', '2xl': 'initial' }}
          alignItems={{ md: 'flex-start', lg: 'initial' }}
          justify={{ '2xl': 'space-between' }}
        >
          <Stack
            mb={{ base: '20px', lg: '0' }}
            width={{ base: 'auto', md: '100%', '2xl': 'auto' }}
            direction={{ base: 'column', lg: 'row', '2xl': 'column' }}
            justifyContent={{
              base: 'initial',
              md: 'space-between',
              lg: canFilterByLocation ? 'space-between' : 'center',
              '2xl': canFilterByLocation ? 'initial' : 'center',
            }}
            spacing={{ md: '10px', lg: '25px', '2xl': '0px' }}
          >
            <Typography.Title
              flex={1}
              alignSelf="flex-start"
              fontSize={{ base: 'lg', lg: 'xl' }}
              uppercase
            >
              {t('title.hiringOverview')}
            </Typography.Title>
            {canFilterByLocation && (
              <Box maxWidth={{ xl: '200px' }} flexBasis={{ lg: '165px', xl: 'unset' }}>
                <SelectBox
                  width="100%"
                  options={displayOptions ?? undefined}
                  onChange={handleChangeLocation}
                  loading={loadingOptions}
                  initialValue={defaultOption}
                  allowClear={false}
                />
              </Box>
            )}
          </Stack>
          <HStack
            spacing={{ base: '20px', lg: '30px', '2xl': '40px' }}
            justify={{ base: 'center', '2xl': 'initial' }}
            w={{ lg: '100%', '2xl': 'initial' }}
            pb="10px"
            alignSelf="center"
            alignItems="flex-start"
          >
            <VStack textAlign="center">
              <Typography.Title
                fontSize={{ base: 'lg', lg: 'sm-3xl', '2xl': '3xl' }}
                lineHeight="1"
              >
                {total_today_hired}
              </Typography.Title>
              <Typography.Text
              // whiteSpace="nowrap"
              >
                {t('label.todayHired')}
              </Typography.Text>
            </VStack>
            <VStack textAlign="center">
              <Typography.Title
                fontSize={{ base: 'lg', lg: 'sm-3xl', '2xl': '3xl' }}
                lineHeight="1"
              >
                {total_hired_of_current_month}
              </Typography.Title>
              <Typography.Text>
                {t('label.totalHired')}
                <br />
                {t('label.currentMonth')}
              </Typography.Text>
              {/*<Typography.Text*/}
              {/*  whiteSpace="nowrap"*/}
              {/*  textAlign="center"*/}
              {/*  position="relative"*/}
              {/*  minWidth="120px"*/}
              {/*>*/}
              {/*  {t('label.totalHired')}*/}
              {/*  <Typography.Text position="absolute" left="0" right="0" bottom="-100%">*/}
              {/*    {t('label.currentMonth')}*/}
              {/*  </Typography.Text>*/}
              {/*</Typography.Text>*/}
            </VStack>
          </HStack>
        </Stack>
        <Stack flex="1" direction={{ base: 'column', lg: 'row' }} alignItems="stretch">
          <Box flex="1" ref={ref}>
            <BarChar
              datasets={[
                {
                  name: t('label.numberOfHiredStaff'),
                  data: hiringData ?? [],
                },
              ]}
              labels={translatedTimeData ?? []}
              width={chartWidth}
              height={chartHeight}
              hightLightEndLabel
              tooltip={{
                y: {
                  formatter: (val) => {
                    return val.toLocaleString('en-US');
                  },
                },
              }}
            />
          </Box>
          <VStack
            flexDirection={{ base: 'column-reverse', lg: 'column' }}
            justify="center"
            h={{ base: 'initial', '2xl': '100%' }}
          >
            <PermissionGuard permissions={PartnerPermission.VIEW_CANDIDATE}>
              <Spacer />
              <PrimaryButton
                alignSelf={{ md: 'end', lg: 'initial' }}
                mt={{ base: '15px', lg: '0' }}
                onClick={handleGoListCandidate}
                w={{ base: '100%', md: '148px', lg: '100%' }}
              >
                {t('button.allCandidates')}
              </PrimaryButton>
            </PermissionGuard>

            <Spacer />
            <Box alignSelf={{ base: 'flex-start' }} mt="20px">
              <Legend
                color="primary"
                label={
                  <>
                    <Trans
                      t={t}
                      i18nKey="label.numberOfHiredStaff"
                      components={[
                        <Box key={0} as="br" display={{ base: 'none', lg: 'inline-block' }} />,
                      ]}
                    />
                  </>
                }
              />
            </Box>
            <Spacer />
          </VStack>
        </Stack>
      </VStack>
    </Card>
  );
};

export const HiringOverviewWidgetWithPermission = withPermission(
  PartnerPermission.VIEW_HIRING_OVERVIEW,
  HiringOverviewWidget,
);
