import dayjs from 'dayjs';
import floor from 'lodash/floor';
import round from 'lodash/round';

export const getEndOfToday = () => dayjs.tz(Date.now()).endOf('day');

export const getEndOfBeforeToday = () => dayjs.tz(Date.now()).subtract(1, 'day').endOf('day');

export const convertTimeToHourMinute = (time: number, unit: 's' | 'm'): [number, number] => {
  if (time <= 0) return [0, 0];

  const second = unit === 's' ? time : time * 60;
  const minute = second / 60;
  const hours = floor(minute / 60);
  const restMinute = round(minute % 60);
  return [hours, restMinute];
};

export const convertTimeToHour = (time: number, unit: 's' | 'm'): number => {
  if (time === 0) return 0;
  const second = unit === 's' ? time : time * 60;
  const minute = second / 60;
  const hours = round(minute / 60, 2);
  return hours;
};

export const displayHourMinute = (h: number, m: number) => {
  const hour = `0${h}`.slice(-2);
  const minute = `0${m}`.slice(-2);

  return `${hour}:${minute}`;
};
// date format month and year only
export const formatMonthYear = (date: string) => {
  return dayjs.tz(date).format('MMMM YYYY');
};
