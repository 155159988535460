import { RootState } from 'store/root-reducer';

export const internalRoleOptionSelector = ({ userRole }: RootState) => userRole.internalRoleOptions;
export const isFetchingInternalRoleOption = ({ userRole }: RootState) =>
  userRole.fetchInternalRoleOptionStatus === 'pending';

export const internalPermissionSelector = ({ userRole }: RootState) => userRole.internalPermissions;
export const isFetchingInternalPermissions = ({ userRole }: RootState) =>
  userRole.fetchInternalPermissionStatus === 'pending';

export const partnerPermissionSelector = ({ userRole }: RootState) => userRole.partnerPermissions;
export const isFetchingPartnerPermissions = ({ userRole }: RootState) =>
  userRole.fetchPartnerPermissionStatus === 'pending';
