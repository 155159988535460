import dayjs from 'dayjs';
import { FC, Fragment } from 'react';
import { formatMonthYear } from 'utils';

import { IApplicant } from 'models/applicant';

import { useTranslate } from 'hooks/common';

import { getWorkExperience } from 'constant/applicant';

import { Typography } from './Typography';

type Props = {
  experiences: Required<IApplicant>['work_experiences'];
};

export const WorkExperience: FC<Props> = ({ experiences }) => {
  const t = useTranslate();
  return (
    <>
      {experiences.map((work, idx) => (
        <Fragment key={idx}>
          <Typography.Text variant="italic">
            {work?.job_type?.title ?? work.job_type_other ?? t('label.unknown')} /{' '}
            {work.job_started_at != null
              ? formatMonthYear(work.job_started_at)
              : t('label.unknown')}{' '}
            - {work.job_ended_at != null ? formatMonthYear(work.job_ended_at) : t('label.unknown')}{' '}
            / {work.employer}
          </Typography.Text>
          <br />
        </Fragment>
      ))}
    </>
  );
};
