import { useCallback, useMemo, useState } from 'react';

export type ScrollPosition = 'left' | 'middle' | 'right';

export const useScrollMonitor = (defaultPosition?: ScrollPosition) => {
  const [position, setPosition] = useState<'left' | 'middle' | 'right'>(defaultPosition ?? 'left');
  const handleScroll = useCallback((e: React.UIEvent<HTMLElement>) => {
    const { scrollLeft, clientWidth, scrollWidth } = e.currentTarget;

    setPosition((prevState) => {
      if (scrollLeft === 0 || !scrollLeft) return 'left';
      if (scrollWidth - scrollLeft === clientWidth) return 'right';
      if (scrollLeft !== 0) return 'middle';
      return prevState;
    });
  }, []);

  return useMemo<[ScrollPosition, typeof handleScroll]>(
    () => [position, handleScroll],
    [handleScroll, position],
  );
};
