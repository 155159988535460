import { httpClient } from 'apis';
import { AxiosResponse } from 'axios';
import { DATE_TIME_BE } from 'constant';
import i18next from 'i18next';
import {
  ArrayAxiosResponse,
  ArrayResponse,
  DataResponse,
  ListAxiosResponse,
  MessageAxiosResponse,
  ObjectAxiosResponse,
  Option,
  Payload,
  PrimitiveResponse,
  Query,
  ShallowNonNullish,
} from 'types';

import { PaymentState } from 'store/modules/payment';

import { IConsumedCreditsMonthly, IPayment } from 'models/payment';
import { Role } from 'models/user';

import { PaymentFutureFilterData } from 'components/molecules/PaymentFutureFilter';
import { PaymentProcessedFilterData } from 'components/molecules/PaymentProcessedFilter';
import { PaymentSummaryFilterData } from 'components/molecules/PaymentSummaryFilter';
import { PaymentAdjustmentFormData } from 'components/organisms/PaymentAdjustmentForm';

import { getPathByRole, mapErrorDescriptions, mapPaginationState, mapQueryToParams } from './utils';

const entity = 'payment';
const basePath = `portal/${entity}` as const;

const paymentPayloadScheme: Record<string, keyof PaymentAdjustmentFormData> = {
  clock_in: 'clockIn',
  clock_out: 'clockOut',
  hourly_rate: 'wage',
  break_time: 'breakTime',
  bank_code: 'bank',
  account_holder_name: 'bankHolderName',
  account_number: 'bankAccountNumber',
  jod_allowance: 'jodAllowance',
  comment: 'comment',
};

export const paymentApi = {
  async getPaymentTotal(): Promise<PrimitiveResponse<number>> {
    try {
      const res: ObjectAxiosResponse<{
        total: number;
      }> = await httpClient.get(`${basePath}/jobSummary/summary`);
      const { data, message } = res.data;
      return {
        status: true,
        data: data.total,
        message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getProcessedPaymentTotal(): Promise<PrimitiveResponse<number>> {
    try {
      const res: ObjectAxiosResponse<{
        total: number;
      }> = await httpClient.get(`${basePath}/jobPayment/summary`);
      const { data, message } = res.data;
      return {
        status: true,
        data: data.total,
        message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getUnpaidPaymentTotal(): Promise<PrimitiveResponse<number>> {
    try {
      const res: ObjectAxiosResponse<{
        total: number;
      }> = await httpClient.get(`${basePath}/futurePayment/summary`);
      const { data, message } = res.data;
      return {
        status: true,
        data: data.total,
        message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async summary(): Promise<Omit<DataResponse<PaymentState['summary']>, 'message'>> {
    try {
      const [{ data: paymentTotal }, { data: processedTotal }, { data: unpaidTotal }] =
        await Promise.all([
          this.getPaymentTotal(),
          this.getProcessedPaymentTotal(),
          this.getUnpaidPaymentTotal(),
        ]);

      return {
        status: true,
        data: { paymentTotal, processedTotal, unpaidTotal },
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getPayments(
    payload: Payload<null, null, Query & Partial<PaymentSummaryFilterData>>,
  ): Promise<DataResponse<IPayment[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, search, recent, paymentStatus, slotUserStatus, dateRange } = query || {};
      const [startDate, endDate] = dateRange || [];

      const res: ListAxiosResponse<IPayment> = await httpClient.get(
        `${basePath}/list-payment-job`,
        {
          params: mapQueryToParams({ sort, search }, pagination, {
            recent: recent ? 1 : '',
            slot_user_status: slotUserStatus?.value,
            payment_status: paymentStatus?.value,
            start_date_time: startDate?.second(0).format(DATE_TIME_BE),
            end_date_time: endDate?.second(59).format(DATE_TIME_BE),
          }),
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getDetail(id: number | string): Promise<DataResponse<IPayment>> {
    try {
      const res: ObjectAxiosResponse<IPayment> = await httpClient.get(`${basePath}/show/${id}`);
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data,
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getProcessedPayments(
    payload: Payload<null, null, Query & Partial<PaymentProcessedFilterData>>,
  ): Promise<DataResponse<IPayment[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, search, processedDateRange, companyOptions, filterCompanyType } = query || {};
      const [start, end] = processedDateRange || [];
      const res: ListAxiosResponse<IPayment> = await httpClient.get(
        `${basePath}/list-processed-payment-job`,
        {
          params: mapQueryToParams({ sort, search }, pagination, {
            start_date_time: start?.second(0).format(DATE_TIME_BE),
            end_date_time: end?.second(59).format(DATE_TIME_BE),
            companies: companyOptions?.map((item) => item.value).join(),
            filter_company_type: companyOptions?.length ? filterCompanyType : null,
          }),
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async downloadPayment(query: Query & Partial<PaymentProcessedFilterData>): Promise<any> {
    const { search, processedDateRange, companyOptions, filterCompanyType } = query;
    const [start, end] = processedDateRange || [];
    try {
      const res: AxiosResponse<{ data: string; message: string }> = await httpClient.get(
        `${basePath}/download-list-processed-payment-job`,
        {
          params: mapQueryToParams({ search }, undefined, {
            start_date_time: start?.second(0).format(DATE_TIME_BE),
            end_date_time: end?.second(59).format(DATE_TIME_BE),
            companies: companyOptions?.map((item) => item.value).join(),
            filter_company_type: companyOptions?.length ? filterCompanyType : null,
          }),
          responseType: 'arraybuffer',
        },
      );
      return {
        status: true,
        data: res.data,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({ message: error.message, status: false });
    }
  },
  async confirmPayment(
    paymentId: number | string,
    adjustment: ShallowNonNullish<PaymentAdjustmentFormData>,
  ): Promise<DataResponse<null>> {
    const {
      clockIn,
      clockOut,
      wage,
      bank,
      bankAccountNumber,
      bankHolderName,
      jodAllowance,
      comment,
      breakTime,
    } = adjustment;
    try {
      const res: MessageAxiosResponse = await httpClient.post(
        `${basePath}/adjustment-payment-by-admin`,
        {
          payment_id: paymentId,
          clock_in: clockIn.set('seconds', 0).format(DATE_TIME_BE),
          clock_out: clockOut.set('seconds', 0).format(DATE_TIME_BE),
          hourly_rate: wage,
          break_time: breakTime.hour() * 60 + breakTime.minute(),
          bank_code: bank.value,
          account_holder_name: bankHolderName.toUpperCase(),
          account_number: bankAccountNumber,
          jod_allowance: jodAllowance,
          comment: comment || undefined,
        },
      );

      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.confirmUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(paymentPayloadScheme, description),
        message,
      });
    }
  },
  async getBankList(): Promise<ArrayResponse<Option>> {
    try {
      const res: ArrayAxiosResponse<{ code: string; name: string }> = await httpClient.get(
        `/bank-list`,
      );

      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.map((item) => ({ value: item.code, label: item.name })),
        message: message,
      };
    } catch (error: any) {
      return Promise.reject({
        status: false,
        message: error.message,
      });
    }
  },
  async getFuturePayments(
    payload: Payload<null, null, Query & Partial<PaymentFutureFilterData>>,
  ): Promise<DataResponse<IPayment[]>> {
    try {
      const { pagination, query } = payload;
      const { sort, recent, search, dateRange } = query || {};
      const [startDate, endDate] = dateRange || [];

      const res: ListAxiosResponse<IPayment> = await httpClient.get(
        `${basePath}/list-unprocessed-payment-job`,
        {
          params: mapQueryToParams({ sort, search }, pagination, {
            recent: recent ? 1 : '',
            start_date_time: startDate?.second(0).format(DATE_TIME_BE),
            end_date_time: endDate?.second(59).format(DATE_TIME_BE),
          }),
        },
      );
      const {
        data: { data, message },
      } = res;
      return {
        status: true,
        data: data.data,
        message: message,
        pagination: mapPaginationState(data),
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async processMultiplePayment(paymentIds: string[]): Promise<DataResponse<null>> {
    try {
      const res: MessageAxiosResponse = await httpClient.post(
        `${basePath}/process-multiple-payment-with-no-adjustment`,
        {
          payment_ids: paymentIds.join(),
        },
      );

      return {
        status: true,
        message: res.data.message,
      };
    } catch (error: any) {
      const { message = i18next.t<string>('message.confirmUnsuccess'), description } = error;
      return Promise.reject({
        status: false,
        description: mapErrorDescriptions(paymentPayloadScheme, description),
        message,
      });
    }
  },
  async getLocationConsumedCredits(): Promise<DataResponse<IConsumedCreditsMonthly>> {
    try {
      const authorizedPath = getPathByRole({
        [Role.HqManager]: 'consumed-credits-by-hq',
        [Role.AreaManager]: 'consumed-credits-by-area',
        [Role.LocationManager]: 'consumed-credits-by-location',
      });
      if (!authorizedPath) {
        throw new Error(i18next.t<string>('message.haveNoPermissionAccessFeature'));
      }
      const res: ObjectAxiosResponse<IConsumedCreditsMonthly> = await httpClient.get(
        `${basePath}/${authorizedPath}`,
      );
      const { data } = res;

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async getConsumedCreditsByLocationId(payload: {
    id: number | string;
  }): Promise<DataResponse<IConsumedCreditsMonthly>> {
    try {
      const { id } = payload;
      const res: ObjectAxiosResponse<IConsumedCreditsMonthly> = await httpClient.get(
        `${basePath}/consumed-credits-with-location-id`,
        {
          params: {
            location_id: id,
          },
        },
      );
      const { data } = res;

      return {
        status: true,
        data: data.data,
        message: data.message,
      };
    } catch (error: any) {
      return Promise.reject({ status: false, message: error.message });
    }
  },
  async downloadBulkBank(query: Query & Partial<PaymentProcessedFilterData>): Promise<any> {
    const { search, processedDateRange, companyOptions, filterCompanyType } = query;
    const [start, end] = processedDateRange || [];
    try {
      const res: AxiosResponse<{ data: string; message: string }> = await httpClient.get(
        `${basePath}/download-jod-bulk-fast`,
        {
          params: mapQueryToParams(
            { search },
            undefined,
            {
              start_date_time: start?.second(0).format(DATE_TIME_BE),
              end_date_time: end?.second(59).format(DATE_TIME_BE),
              companies: companyOptions?.map((item) => item.value).join(),
              filter_company_type: companyOptions?.length ? filterCompanyType : null,
            },
            {
              hidePagination: true,
            },
          ),
          responseType: 'arraybuffer',
        },
      );
      return {
        status: true,
        data: res.data,
        message: res.data.message,
      };
    } catch (error: any) {
      return Promise.reject({ message: error.message, status: false });
    }
  },
};

export type PaymentApi = typeof paymentApi;
