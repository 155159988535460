import { Avatar, Box, Button, HStack, Spacer, Stack, VStack } from '@chakra-ui/react';
import noop from 'lodash/noop';
import React from 'react';
import { DeviceMode } from 'types';

import { ILocation } from 'models/location';

import { PrimaryButton, Typography } from 'components/atoms';
import { ColumnsType, Table, TableProps } from 'components/organisms/Table';

import { useDeviceMode, useTranslate } from 'hooks/common';

type Props = {
  locations: ILocation[] | null;
  functionTitle?: string;
  functionAction?: (location: ILocation) => void;
  loading: boolean;
} & Omit<TableProps<ILocation>, 'data' | 'columns'>;

export const LocationCreditList: React.FC<Props> = ({
  loading,
  functionAction = noop,
  functionTitle,
  locations,
  ...props
}) => {
  const device = useDeviceMode();
  const t = useTranslate();

  const columns: ColumnsType<ILocation>[] = [
    {
      key: 'name',
      title: t('label.locationName'),
      dataIndex: 'name',
      allowSort: true,
      sortPath: 'location_name',
      width: '40%',
      render: (value, record, index, { isActive }) => {
        return (
          <HStack spacing="4">
            <Avatar
              bg={isActive ? 'white' : 'tango.300'}
              color={isActive ? 'secondary' : 'white'}
              name={value}
              src={record.url_logo}
            />
            <Typography.Title>{value}</Typography.Title>
          </HStack>
        );
      },
      renderMobile: (value) => {
        return <Typography.Text>{value}</Typography.Text>;
      },
      pined: true,
    },
    {
      key: 'consumed_credits',
      title: t('label.consumedCredits'),
      dataIndex: 'consumed_credits',
      width: '25%',
      cellStyleProps: {
        textAlign: 'center',
      },
      allowSort: true,
      render: (value) => <Typography.Title size="lg">{value.toLocaleString('en-US')}</Typography.Title>,
    },

    {
      key: 'available_credits',
      title: t('label.availableCredits'),
      dataIndex: 'available_credits',
      width: '25%',
      cellStyleProps: {
        textAlign: 'center',
      },
      allowSort: true,
      render: (value) => <Typography.Title size="lg">{value.toLocaleString('en-US')}</Typography.Title>,
    },
    {
      key: 'action',
      title: t('label.function'),
      shouldShowTitleInMobile: false,
      width: '10%',
      render(value, record) {
        return (
          <Button variant="primary" w="100%" onClick={() => functionAction(record)}>
            {functionTitle}
          </Button>
        );
      },
    },
  ];

  const columnsMobile: ColumnsType<ILocation>[] = [
    {
      key: 'name',
      title: t('label.locationName'),
      dataIndex: 'name',
      allowSort: true,
      shouldShowTitleInMobile: false,
      render: (value, record, index, { isActive }) => {
        return (
          <Stack spacing="4">
            <HStack pl="20px">
              <Avatar
                bg={isActive ? 'white' : 'tango.300'}
                color={isActive ? 'secondary' : 'white'}
                name={value}
                src={record.url_logo}
                size="sm"
              />
              <Typography.Title fontSize="md">{value}</Typography.Title>
            </HStack>
            <HStack>
              <VStack textAlign="center" spacing="0">
                <Typography.Title fontSize="md" lineHeight="1">
                  {record?.consumed_credits.toLocaleString('en-US')}
                </Typography.Title>
                <Typography.Text>{t('label.consumedCredits')}</Typography.Text>
              </VStack>
              <Spacer />
              <VStack textAlign="center" spacing="0">
                <Typography.Title fontSize="md" lineHeight="1">
                  {record?.available_credits.toLocaleString('en-US')}
                </Typography.Title>
                <Typography.Text>{t('label.availableCredits')}</Typography.Text>
              </VStack>
              <Spacer />
              <Box>
                <PrimaryButton onClick={() => functionAction(record)} h={8}>
                  {functionTitle}
                </PrimaryButton>
              </Box>
            </HStack>
          </Stack>
        );
      },
    },
  ];

  return (
    <Table
      loading={loading}
      columns={device !== DeviceMode.Mobile ? columns : columnsMobile}
      data={locations}
      rowKey={(record) => record.id}
      rowStyleProps={(record) => (record.status === 0 ? { className: `disable` } : undefined)}
      collapsible={false}
      cardStyles={{
        p: { base: '10px', lg: '15px 30px' },
        boxShadow: '0 3px 6px #f0761533',
      }}
      maxHeight="max(50vh, 500px)"
      {...props}
    />
  );
};
