import { ENV } from 'configs';
import { AuthRouteGuard, RolePermissionRouteGuard } from 'guards';
import { Fragment, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { DeviceMode } from 'types';
import { isIOS } from 'utils';

import { Loading } from 'components/molecules';
import { MainLayout } from 'components/templates/MainLayout';

import { useTranslate } from 'hooks/common';
import { useTrackingDeviceScreen } from 'hooks/common/useTrackingDeviceScreen';

import Error404 from 'pages/Error404';

import { Config } from 'containers/Config';
import { ErrorBoundary } from 'containers/ErrorBoundary';

import { RouteConfig, publicRoutes } from './routes';
import { useAuthorizedRoutes } from './useAuthorizedRoutes';

const renderRoute = (routes: RouteConfig[], t: any) => {
  return routes.map((route) => {
    const {
      layout: Layout = Fragment,
      component: Component,
      guard: Guard = Fragment,
      roles,
      permissions,
    } = route;
    let guardProps: any = {};
    if (Guard === RolePermissionRouteGuard) {
      guardProps = { requiredRoles: roles, requiredPermissions: permissions };
    }
    let metaDataTitle = route.metaData?.title;
    if (metaDataTitle) {
      if (t(metaDataTitle)) {
        metaDataTitle = t(metaDataTitle);
      }
    } else {
      metaDataTitle = ENV.INSTANCE.VIETNAM ? 'Jod' : 'Jobs-On-Demand';
    }
    return (
      <Route key={route.path} path={route.path} exact={route.exact}>
        <Guard {...guardProps}>
          <Helmet>
            <title>{metaDataTitle}</title>
          </Helmet>
          <Config
            pageTitle={t(route.title) ?? route.title}
            pageBreakLineTitle={route.breakLineTitle}
          />
          <Layout>
            <ErrorBoundary>{Component ? <Component /> : <Error404 />}</ErrorBoundary>
          </Layout>
        </Guard>
      </Route>
    );
  });
};

const AuthorizedRoutes = () => {
  const routes = useAuthorizedRoutes();
  const t = useTranslate();
  return (
    <Suspense fallback={<Loading h="100%" tip={t('title.loadPage')} />}>
      <Switch>{renderRoute(routes, t)}</Switch>
    </Suspense>
  );
};

const Routes = () => {
  const { device } = useTrackingDeviceScreen();
  const t = useTranslate();
  return (
    <>
      {device === DeviceMode.Mobile && isIOS() && (
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        </Helmet>
      )}
      <Suspense fallback={<Loading h="100%" tip={t('title.loadPage')} height="100vh" />}>
        <Switch>
          {renderRoute(publicRoutes, t)}
          <Route path="/">
            <AuthRouteGuard minH="100vh">
              <MainLayout>
                <AuthorizedRoutes />
              </MainLayout>
            </AuthRouteGuard>
          </Route>
        </Switch>
      </Suspense>
    </>
  );
};

export * from './routes';
export default Routes;