import {
  Avatar,
  Box,
  BoxProps,
  Center,
  Collapse,
  Flex,
  Grid,
  HStack,
  Icon,
  Tag,
  useBoolean,
} from '@chakra-ui/react';
import noop from 'lodash/noop';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import useResizeObserver from 'use-resize-observer';

import { currentUserSelector } from 'store/modules/auth';

import { colors } from 'themes/foundations/colors';

import { mapContactNumber, mapFullName } from 'apis/utils';

import { Card } from 'components/atoms';
import { Typography } from 'components/atoms';

import { useTranslate } from 'hooks/common';
import { useRouter } from 'hooks/common/useRouter';

import {
  EditCircleIcon,
  GlobeIcon,
  LetterIcon,
  PhoneIcon,
  PhotoIcon,
  TriangleIcon,
} from 'assets/icons';

import { LAYOUT_CONFIG } from 'configs/layout';
import { PATH_NAME } from 'configs/pathname';

import { LANGUAGES } from 'constant/language';

export type ProfileInfoProps = {
  onClickProfileIcon?: () => void;
} & BoxProps;

export const CollapsableProfileInfo: FC<ProfileInfoProps> = ({
  onClickProfileIcon = noop,
  ...props
}) => {
  const user = useSelector(currentUserSelector);
  const router = useRouter();
  const t = useTranslate();

  const { height: pinedHeight = 40, ref: pinedRef } = useResizeObserver();
  const [expanded, { toggle }] = useBoolean(false);
  const language = LANGUAGES.find((item) => item.code === user?.language_code) || LANGUAGES[0];
  const handleGoEdit = () => {
    onClickProfileIcon();
    router.push(PATH_NAME.ACCOUNT_SETTINGS);
  };

  const avatarHeight = expanded ? '80px' : '40px';

  return (
    <Box {...props}>
      <Card
        boxShadow={`${colors.tangoOpacity[50]} 0px 0px 3px 0px`}
        bg="tango.200"
        pl="15px"
        pr="25px"
        position="relative"
      >
        <HStack spacing="0">
          <Center w={expanded ? '108px' : '60px'} transition="width 0.2s ease-in">
            <Avatar
              transition="all 0.2s ease-in"
              boxSize={avatarHeight}
              bg="white"
              color="tango.600"
              src={user?.avatar_url ? user?.avatar_url : ''}
              icon={
                <Icon
                  as={PhotoIcon}
                  transition="all 0.2s ease-in"
                  width={expanded ? '32px' : '16px'}
                  height={expanded ? '24px' : '12px'}
                />
              }
            />
          </Center>
          <Box position="relative" flex={1}>
            <Collapse in={expanded} startingHeight={pinedHeight}>
              <Box maxW="150px" wordBreak="break-word" ref={pinedRef}>
                <Typography.Title size="lg" lineHeight="1" noOfLines={3} breakWord>
                  {user && mapFullName(user)}
                </Typography.Title>
              </Box>
              <Typography.Text
                size="md"
                lineHeight="1"
                opacity={user?.job_title ? 1 : 0}
                mt="10px"
                w="108px"
              >
                {user?.job_title ?? '...'}
              </Typography.Text>
            </Collapse>

            <Center
              position="absolute"
              right="0"
              bottom="0"
              pr={expanded ? '10px' : '20px'}
              height={expanded ? '24px' : '100%'}
              transition="height 0.2s ease-in, padding-right 0.2s ease-in"
            >
              <Icon boxSize="24px" cursor="pointer" as={EditCircleIcon} onClick={handleGoEdit} />
            </Center>
          </Box>
        </HStack>

        <Center h="100%" top="0" bottom="0" right="10px" position="absolute" ml="0">
          <Icon
            w="16px"
            h="auto"
            cursor="pointer"
            transform={expanded ? 'rotate(0)' : 'rotate(180deg)'}
            transition="transform 0.2s ease-in"
            as={TriangleIcon}
            onClick={toggle}
          />
        </Center>
      </Card>

      <Collapse in={expanded}>
        <Box mt="30px" pl={LAYOUT_CONFIG.sidebar.paddingLeft.base} pb="2px">
          <Grid
            templateColumns="fit-content(200px) 1fr"
            rowGap={{ base: '16px', '2xl': '18px' }}
            columnGap={{ base: '10px', '2xl': '16px' }}
          >
            <HStack>
              <PhoneIcon />
              <Typography.Text
                variant="bold"
                whiteSpace={{ '2xl': 'nowrap' }}
                maxWidth={{ base: '200px', lg: '60px', '2xl': '200px' }}
              >
                {t('label.phoneNumber')}
              </Typography.Text>
            </HStack>
            <Flex alignItems="center">
              <Typography.Text>{user && mapContactNumber(user)}</Typography.Text>
            </Flex>

            <HStack>
              <LetterIcon />
              <Typography.Text variant="bold">{t('label.email')}</Typography.Text>
            </HStack>

            <Typography.Text isTruncated showTooltip>
              {user?.email}
            </Typography.Text>

            <HStack>
              <GlobeIcon />
              <Typography.Text variant="bold">{t('label.language')}</Typography.Text>
            </HStack>
            <Box>{language && <Tag variant="outlineShadow">{language?.label}</Tag>}</Box>
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};
