import { WorkerStatus } from 'models/job';

import { useTranslate } from 'hooks/common';

export const useWorkerStatusLabels = () => {
  const t = useTranslate();
  const WORKER_STATUS_LABEL: Record<WorkerStatus, string> = {
    [WorkerStatus.Approved]: t('value.hired'),
    [WorkerStatus.Rejected]: t('value.rejected'),
    [WorkerStatus.SelfCanceled]: t('value.selfCancelled'),
    [WorkerStatus.ACK1]: t('value.ack1'),
    [WorkerStatus.ACK2]: t('value.ack2'),
    [WorkerStatus.ClockIn]: t('value.clockedIn'),
    [WorkerStatus.ClockOut]: t('value.clockedOut'),
    [WorkerStatus.AdminDisable]: t('value.adminDisable'),
    [WorkerStatus.NoShow]: t('value.noShow'),
    [WorkerStatus.NoHiring]: t('value.noHiring'),
    [WorkerStatus.NoApplicant]: t('value.noApplicants')
  };

  return WORKER_STATUS_LABEL;
};
