import {
  Avatar,
  Box,
  BoxProps,
  Flex,
  Grid,
  HStack,
  Icon,
  Tag,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import noop from 'lodash/noop';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { VoidFunc } from 'types';

import { currentUserSelector } from 'store/modules/auth';

import { colors } from 'themes/foundations/colors';

import { mapContactNumber, mapFullName } from 'apis/utils';

import { Card } from 'components/atoms';
import { Typography } from 'components/atoms';

import { useScreenMode, useTranslate } from 'hooks/common';
import { useRouter } from 'hooks/common/useRouter';

import { EditCircleIcon, GlobeIcon, LetterIcon, PhoneIcon, PhotoIcon } from 'assets/icons';

import { LAYOUT_CONFIG } from 'configs/layout';
import { PATH_NAME } from 'configs/pathname';

import { LANGUAGES } from 'constant/language';

export type ProfileInfoProps = {
  infoStyles?: BoxProps;
  onClickProfileIcon?: VoidFunc;
} & BoxProps;

export const ProfileInfo: FC<ProfileInfoProps> = ({ onClickProfileIcon = noop, ...props }) => {
  const user = useSelector(currentUserSelector);
  const router = useRouter();
  const screen = useScreenMode();
  const t = useTranslate();
  const tagSize = useBreakpointValue(
    {
      base: 'md',
      md: 'md',
      lg: 'md',
      lxl: 'md',
      '2xl': 'lg',
    },
    screen,
  );

  const language = LANGUAGES.find((item) => item.code === user?.language_code) || LANGUAGES[0];

  const handleGoEdit = () => {
    onClickProfileIcon();
    router.push(PATH_NAME.ACCOUNT_SETTINGS);
  };

  return (
    <Box {...props}>
      <Card
        boxShadow={`${colors.tangoOpacity[50]} 0px 0px 3px 0px`}
        bg="tango.200"
        pl={{ md: '58px', lg: '15px', xl: '20px', '2xl': '58px' }}
        pr="0"
      >
        <HStack spacing={{ md: '15px', lg: '10px', '2xl': '15px' }}>
          <Avatar
            boxSize={{
              md: '78px',
              lg: '50px',
              '2xl': '78px',
            }}
            bg="white"
            color="tango.600"
            src={user?.avatar_url ? user?.avatar_url : ''}
            icon={
              <Icon
                as={PhotoIcon}
                transition="all 0.2s ease-in"
                width={{
                  md: '32px',
                  lg: '24px',
                  '2xl': '32px',
                }}
                height={{
                  md: '24px',
                  lg: '16px',
                  '2xl': '24px',
                }}
              />
            }
          />

          <VStack
            position="relative"
            align="stretch"
            flex={1}
            spacing={{ md: '10px', lg: '4px', '2xl': '10px' }}
            maxW="210px"
          >
            <Typography.Title size="lg" lineHeight="1" pr="15px" breakWord noOfLines={3}>
              {user && mapFullName(user)}
            </Typography.Title>
            <HStack maxWidth="200px" overflow="hidden" justify="space-between">
              <Typography.Text
                size="md"
                lineHeight="1"
                opacity={user?.job_title ? 1 : 0}
                breakWord
                noOfLines={3}
              >
                {user?.job_title}
              </Typography.Text>
              <Box pr={{ md: '58px', lg: '15px' }} alignSelf="flex-end">
                <Icon
                  boxSize={{ lg: '20px', '2xl': '24px' }}
                  cursor="pointer"
                  as={EditCircleIcon}
                  onClick={handleGoEdit}
                />
              </Box>
            </HStack>
          </VStack>
        </HStack>
      </Card>

      <Box mt="24px" pl={LAYOUT_CONFIG.sidebar.paddingLeft} pb="2px">
        <Grid
          templateColumns="fit-content(200px) 1fr"
          rowGap={{ base: '16px', '2xl': '18px' }}
          columnGap={{ base: '10px', '2xl': '16px' }}
        >
          <HStack>
            <PhoneIcon />
            <Typography.Title
              whiteSpace={{ '2xl': 'nowrap' }}
              maxWidth={{ base: '200px', lg: '60px', '2xl': '200px' }}
              size="md"
              capitalize
            >
              {t('label.phoneNumber')}
            </Typography.Title>
          </HStack>
          <Flex alignItems="center">
            <Typography.Text size="md">{user && mapContactNumber(user)}</Typography.Text>
          </Flex>

          <HStack>
            <LetterIcon />
            <Typography.Title size="md">{t('label.email')}</Typography.Title>
          </HStack>

          <Typography.Title size="md" isTruncated showTooltip>
            {user?.email}
          </Typography.Title>

          <HStack>
            <GlobeIcon />
            <Typography.Text size="md" variant="bold">
              {t('label.language')}
            </Typography.Text>
          </HStack>
          <Box>
            {language && (
              <Tag size={tagSize} variant="outlineShadow">
                {language?.label}
              </Tag>
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};
